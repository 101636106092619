import SideNav from "./sideNav"
import Header from "./header"

export default function DashboardLayout(props) {
  return (
    <>
      <main className="bg-[#F5F5F5] text-black min-h-screen">
        {!window.location.pathname.includes("floorplan") && <SideNav />}

        <div className={`h-screen flex flex-col`}>
          {!window.location.pathname.includes("floorplan") && <Header />}

          <div
            className={`${!window.location.pathname.includes("floorplan") ? "p-3" : ""} flex-1 overflow-y-auto`}
          >
            {props.children}
          </div>
        </div>
      </main>
    </>
  )
}
