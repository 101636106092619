import moment from "moment-timezone"
import DetermineHostname from "../configs/DetermineHostname"

moment.tz.setDefault("Europe/London")
const fetchRestaurantInfo = async (tenantId, subtenantId) => {
  let url = `${DetermineHostname().hostV2}restaurant/info?tenantId=${tenantId}`
  if (subtenantId) {
    url += `&subtenantId=${subtenantId}`
  }
  const response = await fetch(url)
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const fetchRestaurantInfoByReservationID = async (bookingID) => {
  const response = await fetch(
    `${DetermineHostname().hostname}reservations/restaurant-info-by-reservation-id/${bookingID}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const fetchAvailableTimes = async (restaurantID, dateTime, guestsCount) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/available-times/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        restaurant_id: restaurantID,
        date: dateTime,
        guestsCount: guestsCount,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const confirmReservation = async ({
  restaurantID,
  dateTime,
  guestsCount,
  firstName,
  lastName,
  guestPhone,
  guestEmail,
  stripePaymentID,
  acceptPolicy,
  acceptRestaurantMails,
  acceptZumiMails,
  customerID,
}) => {
  const day = dateTime.date()
  const month = dateTime.month()
  const hour = dateTime.hour()
  const minute = dateTime.minute()

  const response = await fetch(
    DetermineHostname().hostname + "reservations/confirm-reservation/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        restaurantID,
        dateTime: dateTime.format(),
        guestsCount,
        firstName,
        lastName,
        guestPhone,
        guestEmail,
        stripePaymentID,
        acceptPolicy,
        acceptRestaurantMails,
        acceptZumiMails,
        hour,
        minute,
        month,
        day,
        customerID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const updateReservation = async ({ dateTime, reservationID }) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/update-reservation/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
        dateTime,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const getOccasions = async (restaurantID) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/get-occasions/" + restaurantID,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const savePreferences = async (
  NewBookingID,
  occasion,
  restrictions,
  requests,
) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/save-preferences/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        NewBookingID: NewBookingID.toString(),
        occasion,
        restrictions,
        requests,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const fetchReservationInfo = async (bookingID) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/reservation-info/" + bookingID,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const cancelReservation = async (bookingID) => {
  const response = await fetch(
    `${DetermineHostname().hostname}reservations/cancel-reservation/${bookingID}`,
    { method: "PATCH" },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const fetchReservationOwnerPayments = async (bookingID) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/owner-payments/" + bookingID,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const addNewReservationPayment = async ({
  bookingID,
  paymentID,
  isDefault,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/add-payments/" + bookingID,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paymentID,
        isDefault,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const setDefaultPayment = async ({ reservationID, customerID, paymentID }) => {
  const response = await fetch(
    `${DetermineHostname().hostname}reservations/set-default-payment/${reservationID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paymentID,
        customerID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const setHolderDefaultPayment = async ({ bookingID, paymentID }) => {
  const response = await fetch(
    `${DetermineHostname().hostname}reservations/set-holder-default-payment/${bookingID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paymentID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const confirmInvitation = async ({ bookingID, email, name, phone }) => {
  const response = await fetch(
    `${DetermineHostname().hostname}reservations/confirm-invitation/${bookingID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email,
        name,
        phone,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const saveGuestPreferences = async ({
  NewBookingID,
  email,
  requests,
  restrictions,
  occasion,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/save-guest-preferences/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        NewBookingID,
        email,
        requests,
        restrictions,
        occasion,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const getGuestsNumber = async (restaurantID) => {
  const response = await fetch(
    DetermineHostname().hostname + `reservations/guests-number/${restaurantID}`,
    {
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const getClientSecret = async ({ guestEmail, guestName, customerID }) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/client-secret/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        guestEmail,
        guestName,
        customerID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const editReservation = async (reservationData) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/edit-reservation/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(reservationData),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const phoneLookUp = async ({ guestPhone }) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/phone-lookup/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        guestPhone,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const deleteCard = async ({ paymentID, isDefault }) => {
  const response = await fetch(
    DetermineHostname().hostname + "reservations/remove-payment/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paymentID,
        isDefault,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

export {
  phoneLookUp,
  editReservation,
  updateReservation,
  fetchRestaurantInfoByReservationID,
  fetchRestaurantInfo,
  fetchAvailableTimes,
  confirmReservation,
  savePreferences,
  fetchReservationInfo,
  cancelReservation,
  fetchReservationOwnerPayments,
  addNewReservationPayment,
  setHolderDefaultPayment,
  confirmInvitation,
  saveGuestPreferences,
  getGuestsNumber,
  getClientSecret,
  getOccasions,
  setDefaultPayment,
  deleteCard,
}
