import { useContext, useRef } from "react"
import AdminDashboardContext from "../context/AdminDashboardContext"
import { Link, useParams } from "react-router-dom"
import { ChecksIcon } from "../../components/custom/IconsDashBoard"
import SettingsIcon from "@mui/icons-material/Settings"
import GroupIcon from "@mui/icons-material/Group"
import PieChartIcon from "@mui/icons-material/PieChart"
import BookOnlineIcon from "@mui/icons-material/BookOnline"
import HomeIcon from "@mui/icons-material/Home"
import CloseIcon from "@mui/icons-material/Close"
import WidgetsIcon from "@mui/icons-material/Widgets"
import QueryStatsIcon from "@mui/icons-material/QueryStats"
import RequestQuoteIcon from "@mui/icons-material/RequestQuote"
import { Logout } from "@mui/icons-material"
import styles from "./sideNav.module.css"
import { getSubtenantId } from "../../utils/helperFunctions"

export default function SideNav() {
  const { sidebar, setSidebar } = useContext(AdminDashboardContext)
  const sidebarRef = useRef()
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params["*"])
  const handleLogout = () => {
    setSidebar(false)
    localStorage.removeItem("token")
  }
  return (
    <>
      <aside
        ref={sidebarRef}
        className={`fixed h-full shadow-md top-0 left-0 z-50 transition-transform ease-in-out delay-150 w-60  ${sidebar ? " translate-x-0" : "-translate-x-full"}`}
        aria-label="Sidebar"
      >
        <div className="h-full  overflow-y-auto bg-white">
          <ul className="space-y-2 font-medium">
            <li>
              <button
                type="button"
                className={`${styles.sideBarButton} group ${styles.sideBarElement}`}
                onClick={() => setSidebar(false)}
              >
                <CloseIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Close sidebar</span>
              </button>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}`}
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
                onClick={() => setSidebar(false)}
              >
                <HomeIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Dashboard</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/reservations`}
                // to="reservations"
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
                onClick={() => setSidebar(false)}
              >
                <BookOnlineIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Reservations</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/paidreservation`}
                // to="paidreservation"
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
                onClick={() => setSidebar(false)}
              >
                <RequestQuoteIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Paid Reservations</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/checks`}
                // to="checks"
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
                onClick={() => setSidebar(false)}
              >
                <ChecksIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Checks</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/reports`}
                // to="reports"
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
                onClick={() => setSidebar(false)}
              >
                <PieChartIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Reports</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/users`}
                // to="users"
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
                onClick={() => setSidebar(false)}
              >
                <GroupIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Users</span>
              </Link>
            </li>

            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/setting`}
                // to="setting"
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
                onClick={() => setSidebar(false)}
              >
                <SettingsIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Settings</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/floorplan`}
                // to="floorplan"
                onClick={() => setSidebar(false)}
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
                target={"_blank"}
              >
                <WidgetsIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Floor plan</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/stats`}
                // to="stats"
                onClick={() => setSidebar(false)}
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
              >
                <QueryStatsIcon className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Stats</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/`}
                // to="/"
                onClick={handleLogout}
                className={`${styles.sideBarButton} ${styles.sideBarElement}`}
              >
                <Logout className={styles.sideBarIcon} dim={18} />
                <span className="ml-3">Log out</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </>
  )
}
