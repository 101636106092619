import React, { useEffect, useState } from "react"

const CustomDrawer = ({ anchor, open, onClose, children }) => {
  const [isVisible, setIsVisible] = useState(open)
  useEffect(() => {
    if (open) {
      setIsVisible(true)
    }
  }, [open])

  const handleClose = () => {
    setIsVisible(false)
    setTimeout(() => {
      onClose()
    }, 300)
  }

  return (
    <>
      {isVisible && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={handleClose}
        />
      )}
      <div
        className={`fixed ${anchor}-0 inset-x-0 transform ${
          open ? "translate-y-0" : "translate-y-full"
        } transition-transform duration-300 ease-in-out bg-white z-50 rounded-t-3xl max-h-[90%] overflow-y-auto`}
      >
        <div className="p-6 md:p-8">{children}</div>
      </div>
    </>
  )
}

export default CustomDrawer
