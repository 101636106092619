import { Route, Routes, useNavigate, useParams } from "react-router"
import DashboardLayout from "../layout"
import { AdminDashboardProvider } from "../context/AdminDashboardContext"
import { Home } from "./home"
import Setting from "./setting"
import { useEffect } from "react"
import { ReservationsPage } from "./reservations"
import ChecksPage from "./checks"
import { SelectedDateProvider } from "../context/selectedDateContext"
import { SelectedSortProvider } from "../context/SortContext"
import ReservationSingle from "./reservationpage/reservationSingle"
import CheckSingle from "./checkpage/checkpage"
import "./../styles/styles.css"
import { useQuery } from "@tanstack/react-query"
import { fetchRestaurantById } from "../../api/v2/settings"
import FloorPlan from "./floorPlan"
import StatsPage from "./stats"
import PaidReservations from "./paidBills"
import { getSubtenantId } from "../../utils/helperFunctions"

export default function Index() {
  const params = useParams()
  const tenantId = params?.tenantId
  let subtenantId = getSubtenantId(params["*"])
  const { data, isLoading, isError } = useQuery({
    queryKey: [tenantId],
    queryFn: () => fetchRestaurantById(tenantId, subtenantId),
    retry: false,
  })

  const navigate = useNavigate()
  const token = localStorage.getItem("token")
  useEffect(() => {
    if (!token) {
      navigate(`/login/${tenantId}${subtenantId ? `/${subtenantId}` : ""}`)
    }
  }, [token])

  if (isError)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          Resturant Not Found
        </h1>
      </div>
    )

  if (isLoading) return null
  return (
    <>
      <SelectedSortProvider>
        <SelectedDateProvider>
          <AdminDashboardProvider>
            <DashboardLayout>
              <Routes>
                <Route path="/*" element={<Home />} />
                <Route path="/paidreservation" element={<PaidReservations />} />
                <Route path="/stats" element={<StatsPage />} />
                <Route path="/floorplan" element={<FloorPlan />} />
                <Route path="/setting" element={<Setting />} />
                <Route path="/checks" element={<ChecksPage />} />
                <Route
                  path="/checks/:id"
                  element={<CheckSingle restaurantData={data} />}
                />
                <Route path="/reservations" element={<ReservationsPage />} />
                <Route
                  path="/reservations/:id"
                  element={<ReservationSingle restaurantData={data} />}
                />
                {/* new path added */}
                <Route path="/:subtenantId/*" element={<Home />} />
                <Route
                  path="/:subtenantId/paidreservation"
                  element={<PaidReservations />}
                />
                <Route path="/:subtenantId/stats" element={<StatsPage />} />
                <Route path="/:subtenantId/floorplan" element={<FloorPlan />} />
                <Route path="/:subtenantId/setting" element={<Setting />} />
                <Route path="/:subtenantId/checks" element={<ChecksPage />} />
                <Route
                  path="/:subtenantId/checks/:id"
                  element={<CheckSingle restaurantData={data} />}
                />
                <Route
                  path="/:subtenantId/reservations"
                  element={<ReservationsPage />}
                />
                <Route
                  path="/:subtenantId/reservations/:id"
                  element={<ReservationSingle restaurantData={data} />}
                />
              </Routes>
            </DashboardLayout>
          </AdminDashboardProvider>
        </SelectedDateProvider>
      </SelectedSortProvider>
    </>
  )
}
