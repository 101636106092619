import { React } from "react"
import Card from "@mui/material/Card"
import LargeTableComponet from "../../components/largeTableComponent"

function BillDetails({ data }) {
  const billHeaders = [
    { id: 1, name: "Name", key: "name", sortable: true },
    { id: 2, name: "Quantity", key: "quantity", sortable: true },
    { id: 3, name: "Price", key: "price", sortable: true },
  ]

  return (
    <>
      <Card sx={{ flexDirection: "row", p: 2, m: 2 }}>
        <span>
          <LargeTableComponet
            edit={true}
            hasSearch={false}
            hasSortFilter={false}
            hasDateFilter={false}
            headers={billHeaders}
            data={data}
            title="Bill details"
          />
        </span>
      </Card>
    </>
  )
}

export default BillDetails
