import React, { useState, useEffect } from "react"
import { useParams, useNavigate, useLocation, Link } from "react-router-dom"
import logo from "../../assets/zumi_logo_2.svg"

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
} from "@mui/material"
import { ExpandMoreOutlined } from "@mui/icons-material"

import CheckBox from "../../components/custom/CheckBox"
import HorizontalLine from "../../components/custom/HorizontalLine"
import { useMutation } from "@tanstack/react-query"
import {
  confirmReservation,
  getClientSecret,
  phoneLookUp,
} from "../../api/reservations"
import { useForm } from "react-hook-form"
import { useOutletContext } from "react-router-dom"

import { CreditCardIcon, SpinnerIcon } from "../../components/custom/Icons"
import { loadStripe } from "@stripe/stripe-js/pure"

import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js"
import countryCodes from "../../utils/country_codes.json" // here
import styled from "./Information.module.css"
import classNames from "classnames"
import moment from "moment"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
const InformationForm = () => {
  const [snackbarVisisble, setSnackbarVisisble] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const { restaurant } = useParams()
  const nav = useNavigate()
  const { state } = useLocation()
  const { selectedDateTime, guestCount } = state ?? {
    guestCount: null,
    selectedDateTime: null,
  }

  const { restaurantData } = useOutletContext()

  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [guestPhone, setguestPhone] = useState()
  const [countryCode, setcountryCode] = useState("+44")
  const [guestEmail, setguestEmail] = useState()
  const [acceptPolicy, setacceptPolicy] = useState(true)
  const [acceptRMails, setacceptRMails] = useState(true)
  const [acceptZumiMails, setacceptZumiMails] = useState(true)
  const [expanded, setExpanded] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setSnackbarVisisble(false)
  }

  const handleExpand = (event, isExpanded) => {
    setExpanded(isExpanded ? true : false)
  }

  const confirmReservationMutation = useMutation({
    mutationFn: confirmReservation,
    onError: () => {
      setIsDisabled(false)
      setSnackbarVisisble(true)
    },
  })

  const clientSecretMutation = useMutation({
    mutationFn: getClientSecret,
  })

  const submit = async () => {
    setIsDisabled(true)
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsDisabled(false)
      return null
    }
    const { error: submitError } = await elements.submit()
    if (submitError) {
      toast.error(submitError.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      setExpanded(true)
      setIsDisabled(false)
      return
    }

    const { client_secret, customerID } =
      await clientSecretMutation.mutateAsync({
        guestName: `${firstName} ${lastName}`,
        guestEmail,
      })

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret: client_secret,
      confirmParams: {
        return_url: `${window.location.origin}/booking/${restaurant}/confirm`,
      },
      redirect: "if_required",
    })
    if (error) {
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      setExpanded(true)
      setIsDisabled(false)
      return
    }

    confirmReservationMutation.mutate(
      {
        restaurantID: restaurant,
        dateTime: moment(selectedDateTime),
        guestsCount: guestCount,
        firstName,
        lastName,
        guestPhone: `${countryCode}${guestPhone}`,
        guestEmail,
        stripePaymentID: setupIntent.payment_method,
        customerID: customerID,
        acceptPolicy,
        acceptRestaurantMails: acceptRMails,
        acceptZumiMails,
      },
      {
        onSuccess: (data) => {
          goToNext(data.NewBookingID, data.reservationCode, data.last4)
        },
      },
    )
  }

  const goToNext = (newBookingID, reservationCode, last4) => {
    nav(`/booking/${restaurant}/confirm`, {
      state: {
        selectedDateTime,
        guestCount,
        last4,
        newBookingID,
        reservationCode,
      },
      replace: true,
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!state) {
      nav("/booking/" + restaurant)
    }
  }, [state, nav, restaurant])

  // useEffect(() => {
  //   if (stripe && isAppleDevice) {
  //     const pr = stripe.paymentRequest({
  //       currency: "gbp",
  //       country: "GB",
  //       requestPayerEmail: true,
  //       requestPayerName: true,
  //       total: {
  //         label: "Card Verification",
  //         amount: 50,
  //       },
  //     });

  //     // Check the availability of the Payment Request API.
  //     pr.canMakePayment().then((result) => {
  //       if (result) {
  //         setPaymentRequest(pr);
  //       }
  //     });

  //     pr.on("paymentmethod", async (ev) => {
  //       // Confirm the PaymentIntent without handling potential next actions (yet).

  //       const { client_secret } = await createPaymentIntent({
  //         amount: 0.5,
  //         description: `Invoice:${undefined},Reservation:${undefined},Restaurant:${
  //           restaurantData.restaurantName
  //         } ${restaurant},Date: ${moment().format()}`,
  //       });
  //       const { paymentIntent, error: confirmError } =
  //         await stripe.confirmCardPayment(
  //           client_secret,
  //           { payment_method: ev.paymentMethod.id },
  //           { handleActions: false }
  //         );

  //       if (confirmError) {
  //         ev.complete("fail");
  //       } else {
  //         ev.complete("success");
  //         if (paymentIntent.status === "requires_action") {
  //           const { error } = await stripe.confirmCardPayment(
  //             process.env.REACT_APP_STRIPE_PUBLISHED_KEY
  //           );
  //           if (error) {
  //             // The payment failed -- ask your customer for a new payment method.
  //           } else {
  //             // The payment has succeeded -- show a success message to your customer.
  //           }
  //         } else {
  //           // The payment has succeeded -- show a success message to your customer.
  //         }
  //       }
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stripe, elements]);

  if (!state) return null

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <form
        onSubmit={handleSubmit(() => {
          submit()
        })}
      >
        <div className="flex flex-col mx-[18px] pt-[28px] gap-y-7 items-center overflow-x-hidden">
          <div className="flex justify-between align-center w-full">
            <p className="text-black text-lg font-semibold leading-tight">
              {guestCount} guests
            </p>
            <p className="text-black text-lg font-semibold leading-tight">
              {moment(selectedDateTime).format("ddd, MMM D")}
            </p>
            <p className="text-black text-lg font-semibold leading-tight">
              {moment(selectedDateTime).format("HH:mm")}
            </p>
          </div>
          <HorizontalLine />
          <div className="w-full mb-[35px]">
            <h1 className=" text-black text-[22px] font-semibold leading-normal">
              Guest information
            </h1>
            <div className="mt-[16px] flex flex-col gap-[15px]">
              <input
                type="text"
                placeholder="First name"
                className="rounded-lg border border-slate-400 h-[50px] px-[16px] focus-visible:outline-[#a1c3de]"
                {...register("firstName", {
                  required: {
                    value: true,
                    message: "This Field is required",
                  },
                  minLength: {
                    value: 2,
                    message: "At least 2 letters required",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message:
                      "First name cannot include any special characters or numbers",
                  },
                })}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {errors.firstName && (
                <span className="my-1 text-red-500">
                  {errors.firstName.message}
                </span>
              )}
              <input
                type="text"
                placeholder="Last name"
                className="rounded-lg border border-slate-400 h-[50px] px-[16px] focus-visible:outline-[#a1c3de]"
                {...register("lastName", {
                  required: {
                    value: true,
                    message: "This Field is required",
                  },
                  minLength: {
                    value: 2,
                    message: "At least 2 letters required",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message:
                      "Last name cannot include any special characters or numbers",
                  },
                })}
                onChange={(e) => setLastName(e.target.value)}
              />
              {errors.lastName && (
                <span className="my-1 text-red-500">
                  {errors.lastName.message}
                </span>
              )}
              <div className="w-full flex gap-3">
                <FormControl className="!w-[150px] ">
                  <InputLabel
                    id="demo-simple-select-label"
                    className="!bg-white !px-2"
                  >
                    Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={countryCode}
                    onChange={(e) => setcountryCode(e.target.value)}
                    placeholder={"countryCode"}
                    className="!h-[50px]"
                  >
                    {countryCodes.map((i) => (
                      <MenuItem key={i.code} value={i.dial_code}>
                        {i.name} {i.dial_code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <input
                  type="text"
                  placeholder="Phone"
                  className="flex-grow rounded-lg border border-slate-400 h-[50px] px-[16px] focus-visible:outline-[#a1c3de]"
                  {...register("guestPhone", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^(\+?\d{1,3}[- ]?)?\d{10}$/,
                      message: "Please Enter a valid phone number",
                    },
                    validate: async () => {
                      setIsDisabled(true)
                      const phoneValidation = await phoneLookUp({
                        guestPhone: `${countryCode}${guestPhone}`,
                      })
                      setIsDisabled(false)
                      return (
                        phoneValidation.valid ||
                        "Please Enter a valid phone number"
                      )
                    },
                  })}
                  onChange={(e) => setguestPhone(e.target.value)}
                />
              </div>

              {errors.guestPhone && (
                <span className="my-1 text-red-500">
                  {errors.guestPhone.message}
                </span>
              )}
              <input
                type="email"
                placeholder="Email"
                className="rounded-lg border border-slate-400 h-[50px] px-[16px] focus-visible:outline-[#a1c3de]"
                required
                {...register("guestEmail", {
                  required: { value: true, message: "This field is required" },
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Please Enter a valid email",
                  },
                })}
                onChange={(e) => setguestEmail(e.target.value)}
              />
              {errors.guestEmail && (
                <span className="my-1 text-red-500">
                  {errors.guestEmail.message}
                </span>
              )}
            </div>
          </div>
          <HorizontalLine />
          {/* credit card form */}
          <div className="w-full mt-[16px]">
            <h1 className="text-black text-[22px] font-semibold leading-normal">
              Credit card required
            </h1>
            <div className="mb-[18px]">
              <span className="text-black text-xs font-normal leading-none ">
                {restaurantData?.name} requires a credit card to secure this
                booking. All credit card information will be processed securely.
                No-shows or cancellations less than 1 day in advance will be
                subject to a charge of £10 per person.
              </span>
              <br />
              <Link
                to={"/tos"}
                target="_blank"
                className="text-black text-xs font-bold underline leading-none"
              >
                View terms and conditions.
              </Link>
            </div>
            <div className={styled.infoContainer}>
              <section className={styled.infoSec1}>
                <img src={logo} alt="Company Logo" />
                <p className={styled.introduceText}>
                  Introducing an elegant new checkout experience
                </p>
              </section>
              <section className={styled.infoSec2}>
                <p>
                  {restaurantData.name} is partnering with Zumi to offer an
                  incredible new end of night checkout experience. At the end of
                  your meal, simply walk out and have your card charged
                  automatically 1 hour later. You can also check your bill, pay
                  in full and split the bill with others conveniently on your
                  mobile phone.
                </p>
                <button
                  form={null}
                  onClick={(e) => {
                    e.preventDefault()
                    window.open("https://client.zumi.app/faq-support", "_blank")
                  }}
                  className={classNames(
                    "grow shrink basis-0 h-12 px-8 py-3  rounded-3xl justify-center items-center gap-2 flex",
                    styled.comingSoonButton,
                  )}
                >
                  <div className="flex gap-4 items-center text-center font-semibold leading-tight">
                    Learn More
                  </div>
                </button>
              </section>
            </div>
            <HorizontalLine />
            <Accordion
              expanded={expanded}
              onChange={handleExpand}
              disableGutters
              sx={{
                boxShadow: "none",
                "&:before": {
                  backgroundColor: "transparent !important",
                },
              }}
              className={classNames("my-[20px]", styled.accordionContainer)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="flex gap-2 items-center w-full">
                  <span className="inline-block">
                    <CreditCardIcon />
                  </span>
                  <Typography
                    className={classNames(
                      "text-black text-[22px] font-[600] leading-[110%] fo",
                      styled.creditCardTitle,
                    )}
                  >
                    Payment
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                {/* <div className="flex flex-col gap-y-[10px] mt-[24px] w-full">
                  <span className="w-full relative">
                    <div className="mb-[4px] text-slate-900 text-base font-medium leading-[17.60px]">
                      Card number
                    </div>
                    <CardNumberElement
                      options={{
                        style: {
                          base: {
                            "::placeholder": {
                              color: "#A5ACB8",
                            },
                          },
                        },
                      }}
                    />
                    <img
                      src={BanksIcons}
                      alt="banks"
                      className="h-[12px] absolute top-2/3 transform -translate-y-1/2 right-3 pointer-events-none"
                    />
                  </span>
                  <span className="w-full grid grid-cols-2  gap-x-[10px] my-[10px]">
                    <div>
                      <div className="col-span-1 mb-[4px] text-slate-900 text-base font-medium leading-[17.60px]">
                        Expiry
                      </div>
                      <CardExpiryElement />
                    </div>
                    <div>
                      <div className="col-span-1  mb-[4px] text-slate-900 text-base font-medium leading-[17.60px]">
                        CVC
                      </div>
                      <CardCvcElement />
                    </div>
                  </span>
                </div> */}
                <PaymentElement />
              </AccordionDetails>
            </Accordion>

            <HorizontalLine />
            {/*isAppleDevice && (
              <>
                <Accordion
                  disableGutters
                  sx={{ boxShadow: "none" }}
                  className={classNames("my-[20px]", styled.accordionContainer)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div className="flex gap-2 items-center w-full">
                      <span className="inline-block">
                        <ApplePayIcon />
                      </span>
                      <Typography className="text-black text-[22px] font-[600] leading-[110%] fo">
                        Apple Pay
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    {paymentRequest && (
                      <div className={styled.payBtnContainer}>
                        <PaymentRequestButtonElement
                          options={{ paymentRequest }}
                        />
                      </div>
                    )}
                  </AccordionDetails>
                </Accordion>
                <HorizontalLine />
              </>
                    )*/}

            <div className={classNames(styled.policyChecksContainer)}>
              <div className="relative flex mb-[12px] items-center gap-2">
                <CheckBox
                  {...register("acceptPolicy", {
                    validate: () => {
                      return acceptPolicy === true
                    },
                  })}
                  checked={acceptPolicy}
                  onClick={() => {
                    setacceptPolicy(!acceptPolicy)
                  }}
                />
                <span
                  onClick={() => {
                    setacceptPolicy(!acceptPolicy)
                  }}
                  className=" text-black text-xs font-[400] leading-none cursor-pointer select-none"
                >
                  I accept the cancellation policy*
                </span>
                {/* <InfoOutlined className="absolute right-0 !w-4 !h-4" /> */}
              </div>
              {errors.acceptPolicy && (
                <span className="my-0.5 text-sm text-red-500 relative -top-2 left-2">
                  Checking this field is required
                </span>
              )}
              <div className="relative flex mb-[12px] items-center gap-2">
                <CheckBox
                  {...register("acceptRMails", {
                    validate: () => {
                      return acceptRMails === true
                    },
                  })}
                  checked={acceptRMails}
                  onClick={() => {
                    setacceptRMails(!acceptRMails)
                  }}
                />
                <span
                  onClick={() => {
                    setacceptRMails(!acceptRMails)
                  }}
                  className=" text-black text-xs font-[400] leading-none cursor-pointer select-none"
                >
                  Receive emails and texts from {restaurantData.name} about your
                  booking*
                </span>
                {/* <InfoOutlined className="absolute right-0 !w-4 !h-4" /> */}
              </div>
              {errors.acceptRMails && (
                <span className="my-0.5 text-sm text-red-500 relative -top-2 left-2">
                  Checking this field is required
                </span>
              )}
              <div className="relative flex mb-[12px] items-center gap-2">
                <CheckBox
                  {...register("acceptZumiMails", {
                    validate: () => {
                      return acceptZumiMails === true
                    },
                  })}
                  checked={acceptZumiMails}
                  onClick={() => setacceptZumiMails(!acceptZumiMails)}
                />
                <span
                  onClick={() => setacceptZumiMails(!acceptZumiMails)}
                  className=" text-black text-xs font-[400] leading-none cursor-pointer select-none"
                >
                  Receive emails and texts from {restaurantData.name} about your
                  bill and payment*
                </span>
                {/* <InfoOutlined className="absolute right-0 !w-4 !h-4" /> */}
              </div>
              {errors.acceptZumiMails && (
                <span className="my-0.5 text-sm text-red-500 relative -top-2 left-2">
                  Checking this field is required
                </span>
              )}
              <div>
                <span className="text-black text-xs font-normal leading-none">
                  By clicking “complete reservation” you agree to &nbsp;
                </span>
                <Link
                  to={"/tos"}
                  target="_blank"
                  className="text-black text-xs font-normal underline leading-none"
                >
                  Zumi Pay Terms of Service and Policy
                </Link>
              </div>
            </div>
            <div className="w-full mt-[20px] mb-[12px] justify-center items-center inline-flex">
              <button
                type="submit"
                onClick={handleSubmit(() => {
                  submit()
                })}
                disabled={isDisabled}
                className="grow shrink basis-0 h-12 px-8 py-3 bg-slate-900 rounded-3xl justify-center items-center gap-2 flex"
              >
                <div className="flex gap-4items-center text-center text-amber-50 text-lg font-semibold leading-tight gap-3">
                  {isDisabled && <SpinnerIcon />} Complete reservation
                </div>
              </button>
            </div>
          </div>
        </div>
        <Snackbar
          open={snackbarVisisble}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Error: {confirmReservationMutation?.error?.message}
          </Alert>
        </Snackbar>
      </form>
    </>
  )
}

const Information = () => {
  loadStripe.setLoadParameters({ advancedFraudSignals: false })
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

  return (
    <>
      {stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{ mode: "setup", currency: "gbp" }}
        >
          <InformationForm />
        </Elements>
      )}
    </>
  )
}

export default Information
