import React, { useEffect, useState } from "react"
import ModifyPayments from "../modifyPayment"
import CustomDrawer from "../../components/custom/CustomDrawer"

function ChangePaymentMethodInfo({ bookId, isOpen }) {
  const [open, setOpen] = useState(isOpen)
  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <div>
      <CustomDrawer anchor="bottom" open={open} onClose={handleClose}>
        <ModifyPayments bookId={bookId} />
      </CustomDrawer>
    </div>
  )
}
export default ChangePaymentMethodInfo
