import { SettingIcon, UsersIcon } from "../../components/custom/IconsDashBoard"
import { createContext, useContext, useEffect, useState } from "react"
import DetermineHostname from "../../configs/DetermineHostname"
import { useNavigate, useParams } from "react-router"
import SelectedSortContext from "../context/SortContext"
import LargeTableComponet from "../components/largeTableComponent"
import StatusComponent from "../components/StatusComponent"
import moment from "moment"
import { getSubtenantId } from "../../utils/helperFunctions"

const SelectedDateContext = createContext(null)

function Home() {
  // const logIn = () => {
  //   setisLoggedIn(true);
  // };
  // const logOut = () => {
  //   setisLoggedIn(false);
  // };
  const navigate = useNavigate()
  const [selectedDate, setSelecedDate] = useState()
  const [reservations, setReservations] = useState([])
  const [allReservations, setallReservations] = useState([])
  const [checks, setChecks] = useState([])
  const [allChecks, setallChecks] = useState([])
  const [checksLoading, setchecksLoading] = useState(true)
  const [reservationsLoading, setReservationsloading] = useState(true)
  const { selectedSort } = useContext(SelectedSortContext)
  const [counts, setCounts] = useState({})
  const [hideCanceled, setHideCanceled] = useState(false)
  const [hideLeft, setHideLeft] = useState(false)
  const [hideAnonymous, setHideAnonymous] = useState(false)
  const [hideBooked, setHideBooked] = useState(false)
  const [hideDeleted, setHideDeleted] = useState(false)

  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params["*"])

  const goSettings = () => {
    navigate("setting")
  }
  const fetchReservations = async (status, date, shift, billStatus, refund) => {
    setReservationsloading(true)
    let url = `${DetermineHostname().hostV2}reservationV2/all?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&date=${date ? encodeURIComponent(moment(date).utc().format("YYYY-MM-DD")) : moment().utc().format("YYYY-MM-DD")}`
    if (status || shift || billStatus || refund) {
      url = `${DetermineHostname().hostV2}reservationV2/all?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&shift=${shift ?? ""}&status=${status ?? ""}&billStatus=${billStatus ?? ""}&refund=${refund}&date=${moment(date).utc().format("YYYY-MM-DD")}`
    }
    const res = await fetch(url)
    const d = await res.json()
    d.data.sort((a, b) => -1 * moment(b.dateTime).diff(moment(a.dateTime)))
    if (hideCanceled) {
      d.data = d.data.filter(
        (reservation) =>
          reservation.status !== "Canceled" && reservation.status !== "No Show",
      )
    }
    if (hideLeft) {
      d.data = d.data.filter((reservation) => reservation.status !== "Left")
    }
    if (hideAnonymous) {
      console.log("hideAnonymous", hideAnonymous)
      d.data = d.data.filter(
        (reservation) => reservation.firstName && reservation.lastName,
      )
    }
    if (hideBooked) {
      d.data = d.data.filter(
        (reservation) =>
          reservation.status !== "Booked" && reservation.status !== "Confirmed",
      )
    }
    if (hideDeleted) {
      d.data = d.data.filter((reservation) => reservation.status !== "Deleted")
    }
    setReservations(d.data)
    setallReservations(d.data)
    setReservationsloading(false)
    setCounts({
      all: d.length,
      paid: d.paidCount,
      unpaid: d.unpaidCount,
    })
  }
  const fetchChecks = async (status, date) => {
    setchecksLoading(true)
    let url = `${DetermineHostname().hostV2}billsV2/all-bills?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&${date ? "date=" + date : ""}`
    if (status) {
      url = `${DetermineHostname().hostV2}billsV2/all-bills?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&status=${status}${date ? "&date=" + date : ""}`
    }
    const res = await fetch(url)
    const d = await res.json()
    setChecks(d.data)
    setallChecks(d.data)
    setchecksLoading(false)
  }

  useEffect(() => {
    let val = -1
    let item = selectedSort?.filter
    if (item && item[0] === "-") {
      item = item.substring(1)
      val = 1
    }
    let data =
      selectedSort?.table === "Reservations" ? allReservations : allChecks
    const sortedArray = [...data].sort((a, b) => {
      if (a[item] < b[item]) return val
      if (a[item] > b[item]) return -val
      return 0
    })
    selectedSort?.table === "Reservations"
      ? setReservations(sortedArray)
      : setChecks(sortedArray)
  }, [selectedSort])

  const reservationsHeaders = [
    { id: 11, name: "First Name", key: "firstName", sortable: true },
    { id: 18, name: "Last Name", key: "lastName", sortable: true },
    { id: 12, name: "Customer Email", key: "guestEmail", sortable: true },
    { id: 9, name: "Reservation Code", key: "reservationCode", sortable: true },
    { id: 15, name: "Table Number", key: "tables", sortable: true },
    { id: 24, name: "receiptNum", key: "receiptNum", sortable: true },
    { id: 13, name: "Reservation Date", key: "date", sortable: true },
    { id: 14, name: "Time", key: "time", sortable: true },
    {
      id: 7,
      name: "Status",
      key: "status",
      sortable: true,
      renderContent(row, _col) {
        return <StatusComponent status={row["status"]} />
      },
    },
    {
      id: 21,
      name: "Bill",
      key: "bill_status",
      sortable: true,
      renderContent(row, _col) {
        return <StatusComponent status={row["bill_status"]} />
      },
    },
    {
      id: 18,
      name: "Refunded",
      key: "refund_status",
      sortable: true,
      renderContent(row, _col) {
        return row.refund_status ?? "No"
      },
    },
    {
      id: 19,
      name: "Refunded Amount",
      key: "refundedAmount",
      sortable: true,
      renderContent(row, _col) {
        return row.refundedAmount ? (row.refundedAmount / 100).toFixed(2) : "0"
      },
    },
  ]
  const checksHeaders = [
    { id: 1, name: "First Name", key: "firstName", sortable: true },
    { id: 8, name: "Last Name", key: "lastName", sortable: true },
    { id: 2, name: "Customer Email", key: "guestEmail", sortable: true },
    { id: 3, name: "Check total", key: "price", sortable: true },
    { id: 4, name: "Creation Date", key: "date", sortable: true },
    { id: 5, name: "Table", key: "table", sortable: true },
    {
      id: 7,
      name: "Status",
      key: "status",
      sortable: true,
      renderContent(row, _col) {
        return <StatusComponent status={row["status"]} />
      },
    },
  ]
  return (
    <>
      <SelectedDateContext.Provider
        value={{
          selectedDate,
          setSelecedDate,
        }}
      >
        <section className="flex flex-row flex-wrap">
          <div className="flex-1 ">
            <div className="p-3 mx-2 rounded-lg bg-[#D3E0FF] flex h-full">
              <div className="basis-1/2 m-1">
                <h2>Welcome to your dashboard!</h2>
                <p>
                  This is the Zumi dashboard. View reservations or view floor
                  plan page to see active dining and payments.
                </p>
              </div>
              <div className="basis-1/2 m-1 flex justify-end">
                <img src="/images/rightColumnImg.png" alt="img" />
              </div>
            </div>
          </div>
          <div className="flex">
            <div
              onClick={goSettings}
              className="p-3 m-2 rounded-lg bg-[#FFF] h-[130px] w-[127px] flex flex-col justify-center items-center hover-row"
            >
              <p>Settings</p>
              <SettingIcon />
            </div>
            <div className="p-3 m-2 rounded-lg bg-[#FFF] h-[130px] w-[127px] flex flex-col justify-center items-center">
              <p>Users</p>
              <UsersIcon />
            </div>
          </div>
        </section>
        <section className=" flex flex-row flex-wrap rounded">
          <div className="overflow-auto flex-1">
            <LargeTableComponet
              fetchReservations={fetchReservations}
              openRow={true}
              hasDateFilter={true}
              hasHideList={true}
              headers={reservationsHeaders}
              data={reservations}
              title="Reservations"
              extraClassName="h-[390px]"
              selectToday={true}
              counts={counts}
              loading={reservationsLoading}
              from={"home"}
              setHideCanceled={setHideCanceled}
              hideCanceled={hideCanceled}
              setHideLeft={setHideLeft}
              hideLeft={hideLeft}
              setHideAnonymous={setHideAnonymous}
              hideAnonymous={hideAnonymous}
              setHideBooked={setHideBooked}
              hideBooked={hideBooked}
              setHideDeleted={setHideDeleted}
              hideDeleted={hideDeleted}
            />
          </div>
        </section>
        <section>
          <div className="m-2 rounded-lg bg-[#FFF] ">
            <LargeTableComponet
              openRow={true}
              hasDateFilter={false}
              headers={checksHeaders}
              fetchReservations={fetchChecks}
              data={checks}
              title="Checks"
              extraClassName="h-[390px]"
              selectToday={true}
              loading={checksLoading}
              from={"checks"}
            />
          </div>
        </section>
      </SelectedDateContext.Provider>
    </>
  )
}

export { Home, SelectedDateContext }
