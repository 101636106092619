import { useContext, useEffect, useState } from "react"
import LargeTableComponet from "../components/largeTableComponent"
import DetermineHostname from "../../configs/DetermineHostname"
import SelectedDateContext from "../context/selectedDateContext"
import StatusComponent from "../components/StatusComponent"
import moment from "moment"
import { useParams } from "react-router"
import { getSubtenantId } from "../../utils/helperFunctions"

function ReservationsPage() {
  const [reservations, setReservations] = useState([])
  const { setSelecedDate } = useContext(SelectedDateContext)
  const [reservationsLoading, setReservationsloading] = useState(true)
  const [hideCanceled, setHideCanceled] = useState(false)
  const [hideLeft, setHideLeft] = useState(false)
  const [hideAnonymous, setHideAnonymous] = useState(false)
  const [hideBooked, setHideBooked] = useState(false)
  const [hideDeleted, setHideDeleted] = useState(false)

  const [counts, setCounts] = useState({})
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params["*"])

  const fetchReservations = async (status, date, shift, billStatus, refund) => {
    setReservationsloading(true)
    let url = `${DetermineHostname().hostV2}reservationV2/all?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&date=${date ? moment(date).utc().format("YYYY-MM-DD") : moment().utc().format("YYYY-MM-DD")}`
    if (status || shift || billStatus || refund) {
      url = `${DetermineHostname().hostV2}reservationV2/all?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&shift=${shift ?? ""}&status=${status ?? ""}&billStatus=${billStatus ?? ""}&refund=${refund ?? ""}&date=${moment(date).utc().format("YYYY-MM-DD")}`
    }
    return await fetch(url)
      .then((res) => res.json())
      .then((d) => {
        d.data.sort((a, b) => -1 * moment(b.dateTime).diff(moment(a.dateTime)))
        if (hideCanceled) {
          d.data = d.data.filter(
            (reservation) =>
              reservation.status !== "Canceled" &&
              reservation.status !== "No Show",
          )
        }
        if (hideLeft) {
          d.data = d.data.filter((reservation) => reservation.status !== "Left")
        }
        if (hideAnonymous) {
          console.log("hideAnonymous", hideAnonymous)
          d.data = d.data.filter(
            (reservation) => reservation.firstName && reservation.lastName,
          )
        }
        if (hideBooked) {
          d.data = d.data.filter(
            (reservation) =>
              reservation.status !== "Booked" &&
              reservation.status !== "Confirmed",
          )
        }
        if (hideDeleted) {
          d.data = d.data.filter(
            (reservation) => reservation.status !== "Deleted",
          )
        }

        setReservations(d.data)
        setCounts({
          all: d.length,
          paid: d.paidCount,
          unpaid: d.unpaidCount,
        })
        setReservationsloading(false)
      })
  }

  useEffect(() => {
    setSelecedDate("all")
    // fetchReservations();
  }, [])
  const reservationsHeaders = [
    { id: 1, name: "First Name", key: "firstName", sortable: true },
    { id: 7, name: "Last Name", key: "lastName", sortable: true },
    { id: 2, name: "Customer Email", key: "guestEmail", sortable: true },
    { id: 15, name: "Table Number", key: "tables", sortable: true },
    { id: 24, name: "receiptNum", key: "receiptNum", sortable: true },
    { id: 3, name: "Reservation Date", key: "date", sortable: true },
    { id: 9, name: "Reservation Code", key: "reservationCode", sortable: true },
    { id: 4, name: "Time", key: "time", sortable: true },
    { id: 12, name: "total", key: "price", sortable: true },
    {
      id: 5,
      name: "Status",
      key: "status",
      sortable: true,
      renderContent(row, _col) {
        return <StatusComponent status={row["status"]} />
      },
    },
    {
      id: 7,
      name: "Bill",
      key: "bill_status",
      sortable: true,
      renderContent(row, _col) {
        return <StatusComponent status={row["bill_status"]} />
      },
    },
    {
      id: 12,
      name: "Refunded",
      key: "refund_status",
      sortable: true,
      renderContent(row, _col) {
        return row.refund_status ?? "No"
      },
    },
    {
      id: 12,
      name: "Refunded Amount",
      key: "refundedAmount",
      sortable: true,
      renderContent(row, _col) {
        return row.refundedAmount ? (row.refundedAmount / 100).toFixed(2) : "0"
      },
    },
    {
      id: 12,
      name: "POS Paid",
      key: "pos_paid",
      sortable: true,
      renderContent(row) {
        return row.pos_paid ? "Yes" : "No"
      },
    },
  ]

  return (
    <>
      <main className="p-6 m-2 rounded-lg bg-[#FFF] min-h-screen">
        <LargeTableComponet
          fetchReservations={fetchReservations}
          openRow={true}
          hasDateFilter={true}
          hasHideList={true}
          headers={reservationsHeaders}
          data={reservations ? reservations : []}
          title="Reservations"
          extraClassName="h-full"
          selectToday={true}
          counts={counts}
          loading={reservationsLoading}
          from={"home"}
          setHideCanceled={setHideCanceled}
          hideCanceled={hideCanceled}
          setHideLeft={setHideLeft}
          hideLeft={hideLeft}
          setHideAnonymous={setHideAnonymous}
          hideAnonymous={hideAnonymous}
          setHideBooked={setHideBooked}
          hideBooked={hideBooked}
          setHideDeleted={setHideDeleted}
          hideDeleted={hideDeleted}
        />
      </main>
    </>
  )
}

export { ReservationsPage }
