import React from "react"
import {
  Elements,
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

const CheckoutFormInfo = ({ onConfirm }) => {
  const stripe = useStripe()
  const elements = useElements()
  if (!stripe || !elements) return null
  const handleConfirm = async () => {
    try {
      await onConfirm(elements, stripe)
    } catch (error) {
      console.error("Error during payment confirmation:", error)
    }
  }
  return (
    <ExpressCheckoutElement
      onConfirm={handleConfirm}
      options={{
        buttonType: { googlePay: "plain", applePay: "plain" },
        paymentMethods: { googlePay: "auto", applePay: "always" },
        buttonHeight: 50,
        buttonTheme: { googlePay: "white", applePay: "white-outline" },
        layout: {
          maxRows: 1,
          maxColumns: 1,
        },
      }}
    />
  )
}

export default function StripeCheckoutForm({ onConfirm, amount }) {
  if (!process.env.REACT_APP_STRIPE_PUBLISHED_KEY) {
    return null
  }
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        currency: "gbp",
        amount,
        appearance: {
          variables: {
            borderRadius: "78px",
          },
        },
      }}
    >
      <CheckoutFormInfo onConfirm={onConfirm} />
    </Elements>
  )
}
