import React, { useEffect } from "react"

import { useParams, Outlet, useNavigate } from "react-router-dom"
import GoBack from "../../components/custom/Goback"
import ModifyCalender from "./Calender"
import ModifyInformation from "./Information"
import ModifyAvailability from "./Availability"
import { useQuery } from "@tanstack/react-query"
import { Skeleton } from "@mui/material"
import {
  fetchReservationInfo,
  fetchRestaurantInfo,
} from "../../api/reservations"
import moment from "moment"
import MuiFontTheme from "../../components/custom/MuiTheme"

const ModifyReservation = () => {
  const { restaurant, bookingID } = useParams()
  const nav = useNavigate()

  const {
    data: restaurantData,
    isLoading: isLoadingRestaurant,
    isError: isErrorRestaurant,
  } = useQuery({
    queryKey: [restaurant],
    queryFn: () => fetchRestaurantInfo(restaurant),
    retry: false,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
  })

  const {
    data: reservationData,
    isLoading: isLoadingReservation,
    isError: isErrorReservation,
    refetch: refetchReservation,
  } = useQuery({
    queryKey: [bookingID],
    queryFn: () => fetchReservationInfo(bookingID),
    retry: false,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
  })

  useEffect(() => {
    if (reservationData && reservationData.status === "cancelled") {
      nav(`/modify-reservation/${restaurant}/${bookingID}/cancelled`)
    }
  }, [reservationData])

  if (isErrorRestaurant)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          Resturant Not Found
        </h1>
      </div>
    )

  if (isErrorReservation)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          Reservation Not Found
        </h1>
      </div>
    )

  if (reservationData && moment(reservationData.dateTime).isBefore(moment())) {
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          You can't modify a past reservation
        </h1>
      </div>
    )
  }

  return (
    <MuiFontTheme font={restaurantData?.main_font ?? ""}>
      <div
        className={`relative h-full flex justify-center pt-0`}
        style={{ fontFamily: restaurantData?.main_font ?? "" }}
        id="booking"
      >
        <div className="w-full lg:max-w-[50%] relative">
          <div className="absolute top-[32px] left-[10px]">
            <GoBack />
          </div>
          <div className="w-screen mb-[30px] lg:ml-[-50%] flex justify-center h-[131px] text-center text-black text-[40px] font-extrabold leading-[60px] capitalize bg-[#f0e6cd]">
            {isLoadingRestaurant ? (
              <Skeleton
                animation="wave"
                className="w-[100px] rounded-xl !mt-4"
              />
            ) : (
              <img
                className="max-w-[170px] h-[66px] mt-[10px]"
                src={restaurantData.logo}
                alt="logo"
              />
            )}
          </div>
          {isLoadingRestaurant || isLoadingReservation ? (
            <Skeleton animation="wave" className="!mt-[50px]  !h-[60vh]" />
          ) : (
            <Outlet
              context={{ restaurantData, reservationData, refetchReservation }}
            />
          )}
        </div>
      </div>
    </MuiFontTheme>
  )
}

export default ModifyReservation

export { ModifyCalender, ModifyInformation, ModifyAvailability }
