import React from "react"
import { Link, NavLink } from "react-router-dom"
import { CloseCircleIcon, ArrowIcon } from "../../components/custom/Icons"
import { useNavigate } from "react-router-dom"
import HorizontalLine from "../../components/custom/HorizontalLine"

const MenuPage = () => {
  const nav = useNavigate()
  return (
    <div className="dark" id="dark_layout">
      <div className="flex justify-between flex-row-reverse w-full lg:border-b-2 border-[#9AAAFF] bg-[#17243A] h-[60px] lg:h-[120px] py-[20px] lg:py-[50px] px-[15px] lg:px-[65px]">
        <span onClick={() => nav(-1)} className="cursor-pointer">
          <CloseCircleIcon />
        </span>
        <Link to={"/"} className="invisible lg:visible">
          <img src="/images/zumi-logo.svg" alt="Zumi" />
        </Link>
      </div>
      <>
        <div className="w-full min-h-[calc(100vh-60px)] lg:min-h-[calc(100vh-120px)] lg:max-w-[50%] relative flex flex-col items-center gap-y-[18px] py-[18px] px-[24px] lg:px-[70px] lg:py-[45px] bg-[#0B172C] ">
          <div
            onClick={() => nav("/")}
            className="w-full flex justify-between cursor-pointer my-[18px]"
          >
            <div className=" text-amber-50 text-[22px] font-semibold leading-normal">
              About Us
            </div>
            <ArrowIcon />
          </div>
          <HorizontalLine />
          <div
            onClick={() => nav("/faq-support")}
            className="w-full flex justify-between cursor-pointer my-[18px]"
          >
            <div className=" text-amber-50 text-[22px] font-semibold leading-normal">
              FAQ & Support
            </div>
            <ArrowIcon />
          </div>
          <HorizontalLine />
          <NavLink
            to={"/tos"}
            className="w-full flex justify-between cursor-pointer my-[18px]"
          >
            <div className=" text-amber-50 text-[22px] font-semibold leading-normal">
              Legal
            </div>
            <ArrowIcon />
          </NavLink>
        </div>
      </>
    </div>
  )
}

export default MenuPage
