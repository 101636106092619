import React, { useEffect, useState } from "react"
import {
  useParams,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material"
import { CreditCard, ExpandMoreOutlined } from "@mui/icons-material"
import cn from "classnames"
import HorizontalLine from "../../components/custom/HorizontalLine"
import { GroupAddIcon, SpinnerIcon } from "../../components/custom/Icons"
import {
  cancelReservation,
  getOccasions,
  savePreferences,
  updateReservation,
} from "../../api/reservations"
import { useMutation, useQuery } from "@tanstack/react-query"
import moment from "moment"
import ConfirmModal from "./ConfirmModal"
const ModifyInformation = () => {
  const { restaurant } = useParams()
  const nav = useNavigate()
  const { state } = useLocation()
  const { restaurantData, reservationData, refetchReservation } =
    useOutletContext()

  const {
    dateTime,
    guestsCount,
    paymentLast4digits,
    guestEmail,
    NewBookingID,
    guestsEmails,
    preferences: reservationPreferences,
    reservationCode,
    restaurantPhone,
  } = reservationData

  const [preferences, setPreferences] = useState({
    occasion: null,
    restrictions: null,
    requests: null,
  })

  const [addNewGuest, setaddNewGuest] = useState(false)
  const [newGuestEmail, setnewGuestEmail] = useState([])
  const [cancelModalVisible, setCancelModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)

  const { data: occasions } = useQuery({
    queryKey: ["occasions"],
    queryFn: () => getOccasions(restaurant),
    refetchOnWindowFocus: false,
  })

  const handleAddMoreGuests = () => {
    setaddNewGuest(true)
    Array.from({ length: guestsCount - 1 }).map((_, id) =>
      setnewGuestEmail((prevNewGuests) => [
        ...prevNewGuests,
        { id, email: "" },
      ]),
    )
  }
  const handleEmailChange = (id, value) => {
    setnewGuestEmail(
      newGuestEmail.map((guestMail) =>
        guestMail.id === id ? { ...guestMail, email: value } : guestMail,
      ),
    )
  }

  const cancelReservationMutation = useMutation({
    mutationFn: cancelReservation,
    onSuccess: () => {
      nav(`/modify-reservation/${restaurant}/${NewBookingID}/cancelled`)
    },
  })

  const rebookReservationMutation = useMutation({
    mutationFn: updateReservation,
    onSuccess: async (data) => {
      refetchReservation()
      nav(`/modify-reservation/${restaurant}/${data.newReservationBookingID}`, {
        replace: true,
      })
    },
  })

  const modifyReservationMutation = useMutation({
    mutationFn: async (args) => await savePreferences(...args),
    onSuccess: async () => {
      await Rebook()
    },
  })

  const onEditsSave = async (event) => {
    event.preventDefault()
    modifyReservationMutation.mutate([
      NewBookingID,
      preferences.occasion,
      preferences.restrictions,
      preferences.requests,
    ])
  }

  const Rebook = async () => {
    if (
      moment(dateTime).diff(moment(), "hours") <= 24 ||
      !state.selectedDateTime ||
      !reservationData.id
    )
      return
    rebookReservationMutation.mutate({
      reservationID: reservationData.id,
      dateTime: state.selectedDateTime,
    })
  }
  const gotToCancel = async () => {
    cancelReservationMutation.mutate(NewBookingID)
  }

  const goToModify = (e) => {
    e.preventDefault()
    nav(`/modify-reservation/${restaurant}/${NewBookingID}/calender`, {
      state: {
        guestsCount,
        dateTime,
      },
    })
  }

  useEffect(() => {
    if (reservationPreferences) {
      setPreferences({ ...reservationPreferences })
    }
  }, [reservationPreferences])

  return (
    <div>
      <div className="flex flex-col mx-[18px]  gap-y-7 items-center ">
        <div className="w-full">
          <div className=" text-black text-[22px] font-semibold leading-normal">
            Your reservation
          </div>
        </div>
        <HorizontalLine />
        <div className="w-full flex flex-col gap-y-[15px] ">
          <div className="w-full flex flex-col gap-y-[8px]">
            <div className=" text-black text-lg font-semibold leading-tight capitalize">
              {restaurantData.name}
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {guestsCount} guests
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {state
                ? moment(state.selectedDateTime).format("ddd, MMM D")
                : moment(dateTime).format("ddd, MMM D")}
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {state
                ? moment(state.selectedDateTime).format("HH:mm")
                : moment(dateTime).format("HH:mm")}
            </div>
            {state && (
              <div className=" text-[#aaafc1] line-through text-sm font-semibold leading-tight">
                {moment(dateTime).format("ddd, MMM D, YYYY HH:mm")}
              </div>
            )}
            <div className=" text-black text-lg font-semibold leading-tight">
              Res# {reservationCode}
            </div>

            <div className=" text-black text-xs font-semibold leading-tight flex items-center gap-x-[9px]">
              <CreditCard /> Card ending in payment {paymentLast4digits}
            </div>
          </div>
          <div className="flex flex-col gap-y-[15px] mt-[14px]">
            {moment(dateTime).diff(moment(), "hours") <= 24 ? (
              <div className="bg-amber-100 border-amber-400 p-3 rounded-lg border">
                <p className="font-semibold">
                  This reservation cannot be cancelled or modified.
                </p>
                <p>Please call the restaurant at {restaurantPhone} directly</p>
              </div>
            ) : (
              <>
                <div className="w-[210px] h-[34px]  py-3 rounded-3xl border border-black justify-center items-center gap-2 inline-flex">
                  <button
                    onClick={() => setCancelModalVisible(true)}
                    className="text-center text-slate-900 text-base font-medium leading-[17.60px]"
                  >
                    Cancel Reservation
                  </button>
                </div>
                <div className="w-[210px] h-[34px] py-3 rounded-3xl border border-black justify-center items-center gap-2 inline-flex">
                  <button
                    className="text-center text-slate-900 text-base font-medium leading-[17.60px]"
                    onClick={() => setEditModalVisible(true)}
                  >
                    Modify Reservation
                  </button>
                </div>
              </>
            )}
            {/*<div
              className=" h-[33px] text-black text-xs font-semibold underline leading-[13.20px] cursor-pointer"
              onClick={() => setCancelModalVisible(true)}
            >
              Cancel Reservation
            </div>*/}
          </div>
        </div>
        <div>
          {" "}
          <HorizontalLine />
          <Accordion
            disableGutters
            sx={{
              boxShadow: "none",
              "&:before": {
                backgroundColor: "transparent !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="text-black text-[16px] font-[600] leading-[110%] font-[Figtree]">
                What’s the occasion?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <div className="flex flex-wrap gap-x-[11px] gap-y-[15px]">
                {occasions?.map((occasion, index) => (
                  <div
                    onClick={() => {
                      if (preferences.occasion === occasion) {
                        setPreferences({ ...preferences, occasion: null })
                      } else setPreferences({ ...preferences, occasion })
                    }}
                    key={index}
                    className={cn(
                      "h-9 px-[30px] py-[9px] bg-gray-200 text-slate-80 rounded-[33px] justify-center items-center gap-[21px] inline-flex cursor-pointer",
                      {
                        "!bg-[#0B172C] text-white":
                          preferences.occasion === occasion,
                      },
                    )}
                  >
                    <div className="text-center 0 text-base font-medium leading-[17.60px]">
                      {occasion}
                    </div>
                  </div>
                ))}
              </div>
            </AccordionDetails>
          </Accordion>
          <HorizontalLine />
          <Accordion
            disableGutters
            sx={{
              boxShadow: "none",
              "&:before": {
                backgroundColor: "transparent !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="text-black text-[16px] font-[600] leading-[110%] font-[Figtree]">
                Any allergies or restrictions?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <textarea
                onChange={(e) =>
                  setPreferences({
                    ...preferences,
                    restrictions: e.target.value,
                  })
                }
                defaultValue={preferences.restrictions}
                className="w-full h-[81px] rounded-lg border border-slate-400 resize-none p-1"
              ></textarea>
            </AccordionDetails>
          </Accordion>
          <HorizontalLine />
          <Accordion
            disableGutters
            sx={{
              boxShadow: "none",
              "&:before": {
                backgroundColor: "transparent !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="text-black text-[16px] font-[600] leading-[110%] font-[Figtree]">
                Any special requests?
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <textarea
                onChange={(e) =>
                  setPreferences({ ...preferences, requests: e.target.value })
                }
                defaultValue={preferences.requests}
                className="w-full h-[81px] rounded-lg border border-slate-400 resize-none p-1"
              ></textarea>
            </AccordionDetails>
          </Accordion>
          <HorizontalLine />
        </div>
        <div className="my-[21px] w-full">
          <div className=" h-[35px] text-black text-base font-semibold leading-[17.60px]">
            Who’s coming?
          </div>
          <div className="flex flex-col gap-y-[11px]">
            <div className="flex gap-x-[16px]">
              <div className="flex justify-center items-center col-span-1 bg-[#D9D9D9] rounded-full w-[30px] h-[30px]">
                {guestEmail[0]}
              </div>
              <div className="col-span-1">
                <div className=" h-5 text-black text-base font-medium leading-[17.60px]">
                  {guestEmail.split("@")[0]}
                </div>
                <div className=" h-5 text-black text-xs font-normal leading-none">
                  Organizer
                </div>
              </div>
            </div>
            {guestsEmails?.map((guest, index) => (
              <div key={index} className="flex gap-x-[16px]">
                <div className="flex justify-center items-center col-span-1 bg-[#D9D9D9] rounded-full w-[30px] h-[30px]">
                  {guest.email[0]}
                </div>
                <div className="col-span-1">
                  <div className=" h-5 text-black text-base font-medium leading-[17.60px]">
                    {guest.email.split("@")[0]}
                  </div>
                  <div className=" h-5 text-black text-xs font-normal leading-none">
                    {guest.status}
                  </div>
                </div>
              </div>
            ))}
            {!addNewGuest && state?.selectedDateTime && (
              <div
                className="flex gap-x-[16px] items-center cursor-pointer"
                onClick={handleAddMoreGuests}
              >
                <GroupAddIcon />
                <div className="text-center text-slate-900 text-base font-medium leading-[17.60px]">
                  Edit Guests
                </div>
              </div>
            )}
            {addNewGuest &&
              newGuestEmail.map((guest, i) => (
                <input
                  key={i}
                  value={guest.value}
                  onChange={(e) => handleEmailChange(guest.id, e.target.value)}
                  type="text"
                  placeholder="Guest email"
                  className="outline-none py-1 border-b border-slate-400"
                />
              ))}
          </div>
        </div>
        <button
          onClick={onEditsSave}
          disabled={
            !(
              preferences.occasion ||
              preferences.restrictions ||
              preferences.requests ||
              newGuestEmail ||
              state
            ) ||
            modifyReservationMutation.isLoading ||
            rebookReservationMutation.isLoading
          }
          className="text-center text-amber-50 disabled:bg-slate-400 bg-slate-900 text-[22px] font-semibold leading-normal w-[374px] h-12 px-8 py-3  rounded-3xl justify-center items-center gap-2 inline-flex "
        >
          <div className="flex gap-2 items-center">
            {(modifyReservationMutation.isLoading ||
              rebookReservationMutation.isLoading) && <SpinnerIcon />}{" "}
            <span>Save</span>
          </div>
        </button>
      </div>
      <ConfirmModal
        visible={cancelModalVisible}
        onClose={() => setCancelModalVisible(false)}
        onClick={gotToCancel}
        isLoading={cancelReservationMutation.isLoading}
        header={"Cancel Reservation"}
        discreption={"Are you sure you want to cancel?"}
        confirmText={"Cancel Reservation"}
      />
      <ConfirmModal
        visible={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        onClick={goToModify}
        isLoading={modifyReservationMutation.isLoading}
        header={"Modify Reservation"}
        discreption={"Are you sure you want to rebook?"}
        confirmText={"Modify Reservation"}
      />
    </div>
  )
}

export default ModifyInformation
