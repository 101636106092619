const stateColors = {
  active: "#29CC97",
  pending: "#FEC400",
  "ON WAITLIST": "#000000",
  cancelled: "#F12B2C",
  canceled: "#F12B2C",
  paid: "#29CC97",
  unpaid: "#FEC400",
}
export default stateColors
