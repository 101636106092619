import React from "react"

function SearchBar({ handleSearch }) {
  return (
    <div className="relative hidden sm:block ">
      <label className="sr-only" htmlFor="search">
        {" "}
        Search{" "}
      </label>

      <input
        className=" h-10 w-full bg-[#ECECEC] rounded-2xl border-none bg-white ps-10 ps-4 text-sm shadow-sm sm:w-56 mx-5"
        type="search"
        style={{
          color: "#4B506D",
          fontSize: "14px",
          fontFamily: "Mulish",
          fontWeight: 600,
          lineHeight: "20px",
          letterSpacing: "0.20px",
          wordWrap: "break-word",
        }}
        placeholder="Search."
        onChange={handleSearch}
      />

      <button
        type="button"
        className="absolute start-1 top-5 -translate-y-1/2 rounded-md bg-transparent p-3 text-gray-600 transition hover:text-gray-700"
      >
        <span className="sr-only">Search</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4 mx-1"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
    </div>
  )
}

export default SearchBar
