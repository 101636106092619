import React, { useEffect, useRef, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { toast } from "react-toastify"
import moment from "moment-timezone"
import { collection, onSnapshot } from "firebase/firestore"
import { db } from "../../firebaseConfig"
import Slider from "@mui/material/Slider"
import Box from "@mui/material/Box"
import { getBillByTableAPI, getTablesData } from "../../api/v2/settings"

const tables = [
  "F7",
  "F6",
  "F5",
  "F4",
  "F3",
  "F2",
  "F1",
  "F8",
  "F9",
  "F10",
  "F11",
  "F12",
  "F13",
  "T11.5",
  "T10",
  "T9",
  "T8",
  "T7",
  "T6",
  "T5",
  "T4",
  "T3",
  "T2",
  "T1",
  "BS4",
  "BS5",
  "BS6",
  "BS7",
  "BS8",
  "BS9",
  "BS10",
  "B3",
  "B2",
  "B1",
  "C1",
  "C2",
  "C3",
  "C4",
  "C5",
  "C8",
  "C7",
  "C6",
  "G1",
  "G2",
  "G3",
  "G4",
  "G5",
  "G12",
  "G11",
  "G10",
  "G9",
  "Test1",
  "Test2",
  "G8",
  "G7",
  "G6",
]

function Table({
  tableName,
  tableData,
  rounded = false,
  className = "",
  onClick,
}) {
  const bgColors = {
    paid: "bg-[#85CD73]",
    "partial paid": "bg-[#F1AE4B]",
    unpaid: "bg-[#DA5555]",
  }
  const textColors = {
    paid: "text-[#85CD73]",
    "partial paid": "text-[#F1AE4B]",
    unpaid: "text-[#DA5555]",
  }
  const borderColors = {
    paid: "border-[#85CD73]",
    "partial paid": "border-[#F1AE4B]",
    unpaid: "border-[#DA5555]",
  }

  return (
    <div
      className={`h-[60px]  flex justify-between items-center gap-3 cursor-pointer select-none ${className}`}
    >
      <div
        onClick={onClick}
        className={`w-[60px] h-full flex flex-col overflow-hidden ${rounded ? "rounded-full" : "rounded-lg"} border-2 ${borderColors[tableData?.reservation?.bill_status]} bg-white`}
      >
        <div className="flex justify-center items-center flex-grow">
          <h1
            className={`${textColors[tableData?.reservation?.bill_status]} font-bold text-xl`}
          >
            {tableName}
          </h1>
        </div>
        {tableData?.reservation?.bill_status && (
          <div
            className={`flex justify-center items-center ${bgColors[tableData?.reservation?.bill_status]}`}
          >
            <h1 className="text-white font-bold text-base truncate">
              {tableData?.reservation?.bill_status}
            </h1>
          </div>
        )}
      </div>
      {Object.keys(tableData?.bill || {}).length > 0 && (
        <div className={`flex flex-col justify-between items-start h-full`}>
          <div className={`flex justify-between gap-2`}>
            <h1 className={`text-white font-bold text-xs`}>T</h1>
            <h1 className={`text-white font-bold text-xs`}>
              {Number(tableData?.bill?.price ?? 0)?.toFixed(2)}
            </h1>
          </div>
          <div className={`flex justify-between gap-2`}>
            <h1
              className={`${textColors[tableData?.reservation?.bill_status]} font-bold text-xs`}
            >
              P
            </h1>
            <h1 className={`${textColors["paid"]} font-bold text-xs`}>
              {Number(tableData?.reservation?.pay_total ?? 0).toFixed(2)}
            </h1>
          </div>
          <div className={`flex justify-between gap-2`}>
            <h1 className={`${textColors["unpaid"]} font-bold text-xs`}>O</h1>
            <h1 className={`${textColors["unpaid"]} font-bold text-xs`}>
              {tableData?.reservation.amount_unpaid.toFixed(2)}
            </h1>
          </div>
        </div>
      )}
    </div>
  )
}

const FloorPlan = () => {
  const [showTable, setShowTable] = useState(false)
  const [selectedTable, setSelectedTable] = useState(null)
  const tableRef = useRef(null)
  const [tableData, setTableData] = useState({})
  const [zoom, setZoom] = useState(100)
  const bgColors = {
    paid: "bg-[#85CD73]",
    "partial paid": "bg-[#F1AE4B]",
    unpaid: "bg-[#DA5555]",
  }
  const textColors = {
    paid: "text-[#85CD73]",
    "partial paid": "text-[#F1AE4B]",
    unpaid: "text-[#DA5555]",
  }

  const getBillByTableMutation = useMutation({
    mutationFn: getBillByTableAPI,
    onSuccess: (data) => {
      setSelectedTable(data.table)
      setTableData(data)
      setShowTable(true)
    },
    onError: (error) => {
      setSelectedTable(null)
      toast.error(error?.message || "An error occurred")
    },
    retry: 0,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  const {
    data: tablesData,
    isError,
    isLoading,
    refetch: tablesRetetch,
  } = useQuery({
    queryKey: ["tables"],
    queryFn: () => getTablesData(tables || []),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  })
  const handleTable = (table) => {
    getBillByTableMutation.mutate(table)
  }

  //close the table if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setShowTable(false)
        setSelectedTable(null)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (!tablesRetetch) return
    let ignoreFirstTime = true

    const updateData = async () => {
      if (ignoreFirstTime) {
        ignoreFirstTime = false
        return
      }
      await tablesRetetch()
      if (selectedTable) {
        getBillByTableMutation.mutate(selectedTable)
      }
    }

    const reservationSnapShot = onSnapshot(
      collection(db, "reservations"),
      updateData,
    )

    return () => {
      reservationSnapShot()
    }
  }, [tablesRetetch, selectedTable, getBillByTableMutation])

  useEffect(() => {
    if (!tablesRetetch) return
    let ignoreFirstTime = true

    const updateData = async () => {
      if (ignoreFirstTime) {
        ignoreFirstTime = false
        return
      }
      await tablesRetetch()
      if (selectedTable) {
        getBillByTableMutation.mutate(selectedTable)
      }
    }

    const invoicesSnapShot = onSnapshot(collection(db, "invoices"), updateData)

    return () => {
      invoicesSnapShot()
    }
  }, [tablesRetetch, selectedTable, getBillByTableMutation])
  if (isLoading) return null
  if (isError) return <div>Something went wrong</div>

  return (
    <div className="w-full h-full">
      <div
        className={`relative bg-custom-blue flex flex-row h-full w-fit min-w-full p-[100px] gap-14 overflow-hidden font-[Figtree] origin-top-left transform transition-all duration-300`}
        style={{ transform: `scale(${zoom}%)` }}
      >
        <div className="flex flex-col justify-between pt-16">
          <Table
            tableName={"F7"}
            onClick={() => handleTable("F7")}
            tableData={tablesData?.F7}
          />
          <Table
            tableName={"F6"}
            onClick={() => handleTable("F6")}
            tableData={tablesData?.F6}
          />
          <Table
            tableName={"F5"}
            onClick={() => handleTable("F5")}
            tableData={tablesData?.F5}
          />
          <Table
            tableName={"F4"}
            onClick={() => handleTable("F4")}
            tableData={tablesData?.F4}
          />
          <Table
            tableName={"F3"}
            onClick={() => handleTable("F3")}
            tableData={tablesData?.F3}
          />
          <Table
            tableName={"F2"}
            onClick={() => handleTable("F2")}
            tableData={tablesData?.F2}
          />
          <Table
            tableName={"F1"}
            onClick={() => handleTable("F1")}
            tableData={tablesData?.F1}
          />
        </div>

        <div className="flex flex-col justify-between">
          <Table
            tableName={"F8"}
            onClick={() => handleTable("F8")}
            tableData={tablesData?.F8}
          />
        </div>

        <div className="flex flex-col justify-between pt-16">
          <Table
            tableName={"F9"}
            onClick={() => handleTable("F9")}
            tableData={tablesData?.F9}
          />
          <Table
            tableName={"F10"}
            onClick={() => handleTable("F10")}
            tableData={tablesData?.F10}
          />
          <Table
            tableName={"F11"}
            onClick={() => handleTable("F11")}
            tableData={tablesData?.F11}
          />
          <Table
            tableName={"F12"}
            onClick={() => handleTable("F12")}
            tableData={tablesData?.F12}
          />
          <Table
            tableName={"F13"}
            onClick={() => handleTable("F13")}
            tableData={tablesData?.F13}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Table
            tableName={"T11.5"}
            onClick={() => handleTable("T11.5")}
            tableData={tablesData["T11.5"]}
          />
          <Table
            tableName={"T10"}
            onClick={() => handleTable("T10")}
            tableData={tablesData?.T10}
          />
          <Table
            tableName={"T9"}
            onClick={() => handleTable("T9")}
            tableData={tablesData?.T9}
          />
          <Table
            tableName={"T8"}
            onClick={() => handleTable("T8")}
            tableData={tablesData?.T8}
          />
          <Table
            tableName={"T7"}
            onClick={() => handleTable("T7")}
            tableData={tablesData?.T7}
          />
          <Table
            tableName={"T6"}
            onClick={() => handleTable("T6")}
            tableData={tablesData?.T6}
          />
          <Table
            tableName={"T5"}
            onClick={() => handleTable("T5")}
            tableData={tablesData?.T5}
          />
          <Table
            tableName={"T4"}
            onClick={() => handleTable("T4")}
            tableData={tablesData?.T4}
          />
          <Table
            tableName={"T3"}
            onClick={() => handleTable("T3")}
            tableData={tablesData?.T3}
          />
          <Table
            tableName={"T2"}
            onClick={() => handleTable("T2")}
            tableData={tablesData?.T2}
          />
        </div>
        <div className="flex flex-col justify-end">
          <Table
            tableName={"T1"}
            onClick={() => handleTable("T1")}
            tableData={tablesData?.T1}
          />
        </div>

        <div className="flex flex-col justify-end gap-3">
          <Table
            tableName={"BS4"}
            onClick={() => handleTable("BS4")}
            tableData={tablesData?.BS4}
            rounded={true}
          />
          <Table
            tableName={"BS5"}
            onClick={() => handleTable("BS5")}
            tableData={tablesData?.BS5}
            rounded={true}
          />
          <Table
            tableName={"BS6"}
            onClick={() => handleTable("BS6")}
            tableData={tablesData?.BS6}
            rounded={true}
          />
          <Table
            tableName={"BS7"}
            onClick={() => handleTable("BS7")}
            tableData={tablesData?.BS7}
            rounded={true}
          />
          <Table
            tableName={"BS8"}
            onClick={() => handleTable("BS8")}
            tableData={tablesData?.BS8}
            rounded={true}
          />
          <Table
            tableName={"BS9"}
            onClick={() => handleTable("BS9")}
            tableData={tablesData?.BS9}
            rounded={true}
          />
          <Table
            tableName={"BS10"}
            onClick={() => handleTable("BS10")}
            tableData={tablesData?.BS10}
            rounded={true}
          />
        </div>
        <div className="flex flex-col justify-start gap-4 pt-28">
          <Table
            tableName={"B3"}
            onClick={() => handleTable("B3")}
            tableData={tablesData?.B3}
            rounded={true}
          />
        </div>
        <div className="flex flex-col justify-around pt-20">
          <Table
            tableName={"B2"}
            onClick={() => handleTable("B2")}
            tableData={tablesData?.B2}
          />
          <Table
            tableName={"B1"}
            onClick={() => handleTable("B1")}
            tableData={tablesData?.B1}
          />
        </div>
        <div className="flex flex-col justify-between gap-14">
          <div className="flex flex-col gap-10">
            <div className="flex flex-row justify-start gap-28 flex-grow">
              <Table
                tableName={"C1"}
                onClick={() => handleTable("C1")}
                tableData={tablesData?.C1}
              />
              <Table
                tableName={"C2"}
                onClick={() => handleTable("C2")}
                tableData={tablesData?.C2}
              />
              <Table
                tableName={"C3"}
                onClick={() => handleTable("C3")}
                tableData={tablesData?.C3}
              />
              <Table
                tableName={"C4"}
                onClick={() => handleTable("C4")}
                tableData={tablesData?.C4}
              />
              <Table
                tableName={"C5"}
                onClick={() => handleTable("C5")}
                tableData={tablesData?.C5}
              />
            </div>
            <div className="flex flex-row justify-start flex-grow">
              <Table
                tableName={"C8"}
                onClick={() => handleTable("C8")}
                tableData={tablesData?.C8}
                className={"ml-20"}
              />
              <Table
                tableName={"C7"}
                onClick={() => handleTable("C7")}
                tableData={tablesData?.C7}
                className={"ml-28"}
              />

              <Table
                tableName={"C6"}
                onClick={() => handleTable("C6")}
                tableData={tablesData?.C6}
                className={"ml-auto mr-20"}
                rounded={true}
              />
            </div>
            <div className="flex flex-row justify-start gap-28 flex-grow">
              <Table
                tableName={"G1"}
                onClick={() => handleTable("G1")}
                tableData={tablesData?.G1}
                rounded={true}
              />
              <Table
                tableName={"G2"}
                onClick={() => handleTable("G2")}
                tableData={tablesData?.G2}
              />
              <Table
                tableName={"G3"}
                onClick={() => handleTable("G3")}
                tableData={tablesData?.G3}
              />
              <Table
                tableName={"G4"}
                onClick={() => handleTable("G4")}
                tableData={tablesData?.G4}
              />
              <Table
                tableName={"G5"}
                onClick={() => handleTable("G5")}
                tableData={tablesData?.G5}
              />
            </div>
          </div>
          <div className="flex flex-row gap-16 flex-grow">
            <div className="flex flex-col justify-end gap-6">
              <Table
                tableName={"G12"}
                onClick={() => handleTable("G12")}
                tableData={tablesData?.G12}
              />
              <Table
                tableName={"G11"}
                onClick={() => handleTable("G11")}
                tableData={tablesData?.G11}
              />
              <Table
                tableName={"G10"}
                onClick={() => handleTable("G10")}
                tableData={tablesData?.G10}
              />
              <Table
                tableName={"G9"}
                onClick={() => handleTable("G9")}
                tableData={tablesData?.G9}
              />
              <Table
                tableName={"Test1"}
                onClick={() => handleTable("Test1")}
                tableData={tablesData?.Test1}
              />
            </div>
            <div className="flex flex-col justify-end gap-6">
              <Table
                tableName={"Test2"}
                onClick={() => handleTable("Test2")}
                tableData={tablesData?.Test2}
              />
            </div>
            <div className="flex flex-col justify-start gap-6">
              <Table
                tableName={"G8"}
                onClick={() => handleTable("G8")}
                tableData={tablesData?.G8}
                className={"flex-grow mb-24"}
              />
            </div>
            <div className="flex flex-col justify-start gap-6">
              <Table
                tableName={"G7"}
                onClick={() => handleTable("G7")}
                tableData={tablesData?.G7}
                className={"mt-60"}
              />
            </div>
            <div className="flex flex-col justify-start gap-6 flex-grow">
              <Table
                tableName={"G6"}
                onClick={() => handleTable("G6")}
                tableData={tablesData?.G6}
                className={"ml-auto mr-36"}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`fixed top-0 bottom-0 ${showTable ? "right-0" : "-right-full"} h-full w-[619px] bg-[#29304E] p-14 shadow-2xl shadow-black transition-all z-10`}
        ref={tableRef}
      >
        <div className="flex flex-col gap-2">
          <div className="flex gap-3">
            <p
              className={`${textColors[tableData?.reservation?.bill_status]}  font-bold text-3xl`}
            >
              Table {tableData.table}
            </p>
            <p
              className={`text-white  text-xl font-bold flex justify-center items-center ${bgColors[tableData?.reservation?.bill_status]} rounded-full px-3`}
            >
              {tableData.reservation?.bill_status}
            </p>
          </div>
          <p className="text-white  text-xl font-bold">
            ID {tableData.bill?.receiptNum}
          </p>
        </div>
        <hr className="my-10" />
        <div className="flex flex-col gap-3">
          <div className="flex justify-between items-center">
            <p className="text-white  text-xl font-medium">Reservation ID</p>
            <p className="text-white  text-xl font-bold">
              {tableData.reservation?.reservationCode}
            </p>
          </div>
          {tableData.reservation?.status !== "Left" && (
            <div className="flex justify-between items-center">
              <p className="text-white  text-xl font-medium">Guest Name</p>
              <p className="text-white  text-xl font-bold">
                {tableData.reservation?.firstName}{" "}
                {tableData.reservation?.lastName}
              </p>
            </div>
          )}
          <div className="flex justify-between items-center">
            <p className="text-white  text-xl font-medium">Total bill</p>
            <p className="text-white  text-xl font-bold">
              £{Number(tableData.bill?.price ?? 0).toFixed(2)}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-[#85CD73]  text-xl font-medium">Amount paid</p>
            <p className="text-[#85CD73]  text-xl font-bold">
              £{Number(tableData.reservation?.pay_total).toFixed(2)}
            </p>
          </div>
        </div>
        <hr className="my-10" />
        <div className="flex justify-between items-center">
          <p className="text-[#F1AE4B]  text-xl font-medium">
            Amount outstanding
          </p>
          <p className="text-[#F1AE4B]  text-xl font-bold">
            {tableData.reservation?.amount_unpaid !== 0.01
              ? `£${Number(tableData.reservation?.amount_unpaid).toFixed(2)}`
              : "not available"}
          </p>
        </div>
        <hr className="my-10" />
        <div className="flex flex-col gap-5">
          <p className="text-white  text-xl font-bold">
            Payments for this reservation
          </p>
          {tableData.paymentRecord?.length === 0 && (
            <div className="flex justify-between items-center">
              <p className="text-white  text-xl font-bold">No Payment Yet</p>
              <p className="text-white  text-xl font-bold">£00.00</p>
            </div>
          )}
          {tableData.paymentRecord
            ?.sort((a, b) =>
              moment(a.createdAt).isAfter(moment(b.createdAt)) ? 1 : -1,
            )
            .map((record, i) => (
              <div key={i} className="flex justify-between items-center">
                <p className="text-white  text-xl font-bold">
                  Payment {i + 1} ({moment(record.createdAt).format("HH:mm")} -{" "}
                  {record.paymentType} - {record.payment_last4 ?? "no number"})
                </p>
                <p className="text-white  text-xl font-bold">
                  £{record.paid.toFixed(2)}
                </p>
              </div>
            ))}
        </div>
        <hr className="my-10" />
        <div className="flex flex-col gap-5">
          <div className="flex justify-between items-center">
            <p
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `${process.env.REACT_APP_HOST_URL}/bill/${tableData.reservation?.id}/?id=${tableData.reservation?.guestID}`,
                )
              }}
              className="text-white  text-xl font-bold cursor-copy select-none hover:text-green-400 active:text-green-300 truncate"
            >
              Copy Bill Link
            </p>
          </div>
          <div className="flex justify-between items-center">
            <p
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `${process.env.REACT_APP_HOST_URL}/admin/${tableData.reservation?.restaurantID}/reservations/${tableData.reservation?.id}`,
                )
              }}
              className="text-white  text-xl font-bold cursor-copy select-none hover:text-green-400 active:text-green-300 truncate"
            >
              Copy Reservation Link
            </p>
          </div>
        </div>
        <div
          className="bg-[#6E80E2] flex justify-center items-center px-10 py-2 rounded-t-3xl absolute left-[-87px] top-[50%] -rotate-90 cursor-pointer"
          onClick={() => setShowTable(false)}
        >
          <p className="text-white font-bold text-xl select-none">Close</p>
        </div>
      </div>
      <Box sx={{ width: 300 }} className="fixed bottom-16 right-10">
        <Slider
          defaultValue={100}
          onChange={(e, value) => setZoom(value)}
          max={200}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
      </Box>
    </div>
  )
}

export default FloorPlan
