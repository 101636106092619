import { React, useEffect, useState } from "react"
import Card from "@mui/material/Card"
import ActionIcon from "../../components/ActionIcon"
import StatusComponent from "../../components/StatusComponent"
import { Box } from "@mui/system"
import LargeTableComponet from "../../components/largeTableComponent"
import styles from "./reserVationPage.module.css"
import { Button } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import PaidIcon from "@mui/icons-material/Paid"
import ReceiptIcon from "@mui/icons-material/Receipt"
import { useMutation } from "@tanstack/react-query"
import {
  createReservationBill,
  getBillInfoByResID,
  manualCharge,
  sendBeforeChargeSMS,
} from "../../../api/v2/bills"
import moment from "moment"
import { toast } from "react-toastify"

function PaymentDetails({ data, refetchReservation }) {
  const [dataSet, setDataSet] = useState(data)
  const [guestsHeaders, setGuestHeaders] = useState([])

  const sendSMS = async () => {
    try {
      await sendBeforeChargeSMS(data[0].reservationID)
      toast.success("SMS Sent Succeful", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    } catch (error) {
      toast.error("SMS Didn't Reach", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }

  const manualChargeMutation = useMutation({
    mutationFn: manualCharge,
    onSuccess: async () => {
      const newData = await getBillInfoByResID(data[0].reservationID)

      setDataSet([newData])
      toast.success("payment success", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
    onError: (err) => {
      toast.error(err.message || "payment field", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })

  const manualChargeHandle = async () => {
    manualChargeMutation.mutate(data[0].reservationID)
  }

  const createBillMutation = useMutation({
    mutationFn: createReservationBill,
    onSuccess: () => {
      refetchReservation()
    },
    onError: () => {
      toast.error("create bill failed", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })
  const createBill = async () => {
    createBillMutation.mutate(data[0].reservationID)
  }

  useEffect(() => {
    if (data?.[0].splitters) {
      const reservationAndBillInfo = data[0]
      const splitters = reservationAndBillInfo.splitters.map((splitter) => ({
        ...splitter,
        amount: (
          splitter.portion *
          (reservationAndBillInfo.price / reservationAndBillInfo.guestsCount)
        ).toFixed(2),
      }))
      setDataSet(splitters)
      setGuestHeaders([
        { id: 1, name: "Email", key: "email", sortable: true },
        {
          id: 1,
          name: "stripeCustomerID",
          key: "stripeCustomerID",
          sortable: true,
        },
        { id: 1, name: "Charged Amount", key: "amount", sortable: true },
      ])
    } else if (!data?.[0].splitters) {
      setDataSet(data)
      setGuestHeaders([
        { id: 1, name: "First Name", key: "firstName", sortable: true },
        { id: 2, name: "Last Name", key: "lastName", sortable: true },
        {
          id: 3,
          name: "Stripe Customer ID",
          key: "stripeCustomerID",
          sortable: true,
        },
        { id: 4, name: "Amount due", key: "price", sortable: true },
        { id: 7, name: "Unpaid Amount", key: "amount_unpaid", sortable: true },
        {
          id: 5,
          name: "Status",
          key: "status",
          sortable: true,
          renderContent(row) {
            return <StatusComponent status={row.bill_status} />
          },
        },
        {
          id: 6,
          name: "Action",
          key: "action",
          sortable: true,
          renderContent() {
            return <ActionIcon />
          },
        },
      ])
    }
  }, [data])

  return (
    <>
      <Card sx={{ flexDirection: "row", p: 2, m: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            p: 2,
            width: "100%",
            alignItems: "center",
          }}
        >
          <span>
            <p className={styles.totalTitle}> Total </p>
            <p className={styles.Total}>
              {" "}
              £{data[0] && data[0].price ? data[0].price : 0}{" "}
            </p>
          </span>
          <span>
            <p className={styles.totalTitle}> Due </p>
            <p className={styles.Due}>
              {" "}
              {moment(data[0]?.dateTime).format("YYYY-MM-DD")}
            </p>
          </span>
          <Button
            disabled={
              (data?.[0]?.guestSmsLock && data?.[0]?.guestsCount > 6) ||
              !data?.[0]?.bill_sms_status ||
              !(
                (data?.[0]?.booked_by_api &&
                  data?.[0]?.booked_by === "Alberti API") ||
                (data?.[0]?.booked_by_wedgit &&
                  data?.[0]?.booked_by === "Booking Widget") ||
                (data?.[0]?.booked_by_walkin &&
                  data?.[0]?.booked_by === "Walk In") ||
                (data?.[0]?.booked_by_other &&
                  !["Alberti API", "Booking Widget", "Walk In"].includes(
                    data?.[0]?.booked_by,
                  ))
              )
            }
            onClick={async () => {
              await sendSMS()
            }}
            variant="contained"
            endIcon={<SendIcon />}
          >
            Send SMS
          </Button>

          <Button
            onClick={() => {
              manualChargeHandle()
            }}
            variant="contained"
            endIcon={<PaidIcon />}
          >
            Manual Charge
          </Button>

          <Button
            onClick={() => {
              createBill()
            }}
            variant="contained"
            endIcon={<ReceiptIcon />}
            disabled={createBillMutation.isLoading}
          >
            Update Bill
          </Button>
        </Box>

        <span>
          <LargeTableComponet
            hasSearch={false}
            hasSortFilter={false}
            hasDateFilter={false}
            headers={guestsHeaders}
            data={dataSet}
            title="Payment details  "
            //  extraClassName='h-[390px]'
          />
        </span>
      </Card>
    </>
  )
}

export default PaymentDetails
