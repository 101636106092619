import React, {
  useParams,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom"
import cn from "classnames"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography,
} from "@mui/material"
import { ExpandMoreOutlined } from "@mui/icons-material"
import HorizontalLine from "../../components/custom/HorizontalLine"
import { useEffect } from "react"
import { fetchAvailableTimes } from "../../api/reservations"
import { useQuery } from "@tanstack/react-query"
import styled from "./Availability.module.css"
import moment from "moment"

const SelectedDateAvailableTimes = ({
  selectedDateTime,
  availabileTimes,
  waitingList,
  next,
  slotDateTime,
}) => {
  return (
    <div className="flex flex-col gap-y-[34px] my-[24px] w-full">
      <div className="w-full text-black text-[22px] font-semibold leading-normal">
        {moment(slotDateTime).format("ddd, MMM D")}
        {availabileTimes.length > 0 ? (
          <div className="grid grid-cols-2 gap-y-[14px] gap-x-[28px] my-[16px]">
            {availabileTimes.map((t, index) => {
              const isSelectedTime = moment(selectedDateTime).isSame(moment(t))

              return (
                <div
                  onClick={() => next(t)}
                  key={index}
                  className={cn(
                    "h-[60px] bg-gray-200 rounded-lg flex justify-center items-center cursor-pointer",
                    {
                      "!bg-slate-900": isSelectedTime,
                    },
                    styled.choice,
                  )}
                >
                  <div
                    className={cn(
                      "w-[124px] h-9 text-center text-slate-900 text-[22px] font-semibold leading-normal",
                      { "!text-white": isSelectedTime },
                    )}
                  >
                    {moment(t).format("HH:mm")}
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <>
            <p className="text-lg mt-[10px]">
              No Reservations available for today
            </p>
            {waitingList && waitingList.length > 0 && (
              <Link
                to={waitingList}
                className="text-lg mt-[10px] text-left underline block"
              >
                Join our waiting list
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const Availability = () => {
  const { restaurant } = useParams()
  const nav = useNavigate()
  const { state } = useLocation()

  useEffect(() => {
    if (!state) {
      nav("/booking/" + restaurant)
    }
  }, [state, nav, restaurant])

  const { selectedDateTime, guestCount } = state ?? {
    guestCount: null,
    selectedDateTime: moment(),
  }

  const firstDateTime = moment(selectedDateTime).format()
  const secondDateTime = moment(selectedDateTime).add(1, "days").format()
  const thirdDateTime = moment(selectedDateTime).add(2, "days").format()

  const firstDateQuery = useQuery(
    ["availabileTimes", restaurant, firstDateTime, guestCount],
    () => fetchAvailableTimes(restaurant, firstDateTime, guestCount),
  )

  const secondDateQuery = useQuery(
    ["availabileTimes", restaurant, secondDateTime, guestCount],
    () => fetchAvailableTimes(restaurant, secondDateTime, guestCount),
    { enabled: !!firstDateQuery?.data },
  )

  const thirdDateQuery = useQuery(
    ["availabileTimes", restaurant, thirdDateTime, guestCount],
    () => fetchAvailableTimes(restaurant, thirdDateTime, guestCount),
    { enabled: !!secondDateQuery?.data },
  )

  const goToInformation = (time) => {
    nav(`/booking/${restaurant}/information`, {
      state: {
        guestCount,
        selectedDateTime: time,
      },
    })
  }

  if (!state) return null

  return (
    <div className="flex flex-col mx-[30px]  items-center">
      {firstDateQuery.data && (
        <SelectedDateAvailableTimes
          selectedDateTime={selectedDateTime}
          slotDateTime={firstDateTime}
          availabileTimes={firstDateQuery.data.available_times}
          waitingList={firstDateQuery.data.waitingList}
          next={goToInformation}
        />
      )}
      {firstDateQuery.isLoading && (
        <Skeleton width="100%" height={"400px"} animation="wave" />
      )}
      <HorizontalLine />
      <div className="w-full ">
        <Accordion
          disableGutters
          sx={{
            boxShadow: "none",
            "&:before": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={styled.dates}>
              View additional dates
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {secondDateQuery.data && (
              <SelectedDateAvailableTimes
                selectedDateTime={selectedDateTime}
                slotDateTime={secondDateTime}
                availabileTimes={secondDateQuery.data.available_times}
                waitingList={secondDateQuery.data.waitingList}
                next={goToInformation}
              />
            )}
            {secondDateQuery.isLoading && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height={"250px"}
                animation="wave"
              />
            )}
            {thirdDateQuery.data && (
              <SelectedDateAvailableTimes
                selectedDateTime={selectedDateTime}
                slotDateTime={thirdDateTime}
                availabileTimes={thirdDateQuery.data.available_times}
                waitingList={thirdDateQuery.data.waitingList}
                next={goToInformation}
              />
            )}
            {thirdDateQuery.isLoading && (
              <Skeleton
                sx={{ marginTop: 4 }}
                variant="rectangular"
                width="100%"
                height={"250px"}
                animation="wave"
              />
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <HorizontalLine />
    </div>
  )
}

export default Availability
