import React from "react"

const Contact = () => {
  return (
    <div className="w-auto m-auto h-auto  z-20    ">
      <div className="mb-[30px] mt-[92px] md:mt-[165px]  max-w-[804px] text-[34px] md:text-[65px] text-white font-bold m-auto">
        Get in touch
      </div>

      <div className="flex justify-center mb-[12px] md:mb-[42px]  max-w-[238px] md:max-w-[454px] text-[14px] md:text-[24px] font-normal	text-center m-auto">
        If you require support or would like to reach out to us direct for any
        reason, please click contact us below.
      </div>

      <div
        className="flex justify-center items-center	bg-[#F7FDE3] rounded-full mb-[75px]  
              max-w-[190px] md:max-w-[323.278px] text-[14px] md:text-[24px]
              h-[41.5px] md:h-[70px] z-20
              font-semibold	text-black text-center m-auto"
      >
        <a href="mailto:info@zumi.app">
          <button className="m-auto">Contact us</button>
        </a>
      </div>
    </div>
  )
}

export default Contact
