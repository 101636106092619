import React from "react"

function CustomFontComponent({ fontFamily, children, className }) {
  return (
    <div
      className={className ?? ""}
      style={{ fontFamily: fontFamily ?? "font-sans" }}
    >
      {children}
    </div>
  )
}

export default CustomFontComponent
