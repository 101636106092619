import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

const SortContext = createContext()

export const SelectedSortProvider = ({ children }) => {
  const [selectedSort, setSelectedSort] = useState()
  return (
    <SortContext.Provider
      value={{
        selectedSort,
        setSelectedSort,
      }}
    >
      {children}
    </SortContext.Provider>
  )
}

SelectedSortProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SortContext
