import React, { useState, useEffect } from "react"
import {
  useParams,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Alert,
  Snackbar,
} from "@mui/material"
import { CreditCard, ExpandMoreOutlined } from "@mui/icons-material"
import cn from "classnames"
import HorizontalLine from "../../components/custom/HorizontalLine"
import { getOccasions, savePreferences } from "../../api/reservations"
import { SpinnerIcon } from "../../components/custom/Icons"
import moment from "moment"
import styles from "./Confirm.module.css"
import { useQuery } from "@tanstack/react-query"

const Confirm = () => {
  const { restaurant } = useParams()
  const nav = useNavigate()
  const { state } = useLocation()
  const [inviteLoading, setinviteLoading] = useState(false)
  const [preferences, setPreferences] = useState({
    occasion: null,
    restrictions: null,
    special: null,
  })
  const [preferencesLoading, setpreferencesLoading] = useState(false)
  const { restaurantData } = useOutletContext()

  const [preferencesSaved, setPreferencesSaved] = useState(false)

  const [snackbarVisisble, setSnackbarVisisble] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState()
  const [snackbarSeverity, setSnackbarSeverity] = useState()

  const [errorMsg, seterrorMsg] = useState(null)

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setSnackbarVisisble(false)
  }

  let { selectedDateTime, guestCount, last4, newBookingID, reservationCode } =
    state ?? {
      selectedDateTime: null,
      guestCount: null,
      last4: null,
      newBookingID: null,
    }
  //
  selectedDateTime = moment(selectedDateTime)
  //

  const resLink = `${window.location.origin}/reservations/${restaurant}/${newBookingID}`
  const resNumber = `${reservationCode}`

  const { data: occasions } = useQuery({
    queryKey: ["occasions"],
    queryFn: () => getOccasions(restaurant),
  })

  const onNotesSave = (event) => {
    event.preventDefault()
    setpreferencesLoading(true)
    savePreferences(
      newBookingID,
      preferences.occasion,
      preferences.restrictions,
      preferences.special,
    )
      .then(() => {
        setPreferencesSaved(true)
        setpreferencesLoading(false)
      })
      .catch((err) => {
        setinviteLoading(false)
        seterrorMsg(err.message)
        setSnackbarVisisble(true)
        setPreferencesSaved(false)
        setpreferencesLoading(false)
      })
  }

  const onCopy = (event) => {
    event.preventDefault()
    window.navigator.clipboard.writeText(resLink).then(() => {})
    setSnackbarMessage("Copied to clipboard!")
    setSnackbarVisisble(true)
    setSnackbarSeverity("success")
  }

  useEffect(() => {
    if (!state) {
      nav("/booking/" + restaurant)
    }
  }, [state, nav, restaurant])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  if (!state) return null

  return (
    <div>
      <div className="flex flex-col mx-[18px]  gap-y-7 items-center ">
        <HorizontalLine />
        <div className="flex justify-between align-center w-full">
          <p className="text-black text-lg font-semibold leading-tight">
            {guestCount} guests
          </p>
          <p className="text-black text-lg font-semibold leading-tight">
            {moment(selectedDateTime).format("ddd, MMM D")}
          </p>
          <p className="text-black text-lg font-semibold leading-tight">
            {moment(selectedDateTime).format("HH:mm")}
          </p>
        </div>
        <HorizontalLine />
        <div className="w-full flex flex-col gap-y-[15px] ">
          <h1 className=" h-[37px] text-black text-[22px] font-semibold leading-normal">
            Reservation Confirmed!
          </h1>
          <div className=" text-black text-xs font-normal leading-[130%] text-[12px] ">
            Thanks for making a reservation with us! We’re really looking
            forward to your visit. <br />
            <br /> Please make sure you arrive within 15 minutes of your
            reservation time or we may not be able to seat you.
            <br />
            <br />
            Your table will be reserved for 1 hour and 45 minutes. <br />
            <br />
            {restaurantData.name} charges an optional 12.5% gratuity charge per
            table.
          </div>
          <div className="w-full flex flex-col gap-y-[8px]">
            <div className=" text-black text-lg font-semibold leading-tight capitalize">
              {restaurantData.name}
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {guestCount} guests
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {moment(selectedDateTime).format("ddd, MMM D, YYYY")}
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {moment(selectedDateTime).format("HH:mm")}
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              Res# {resNumber}
            </div>
            <div className=" text-black text-xs font-semibold leading-tight flex items-center gap-x-[9px]">
              <CreditCard /> Card ending in {last4}
            </div>
          </div>
          <div className="flex w-full justify-center">
            <HorizontalLine />
          </div>
          <h1 className="mx-[23px] h-[37px] text-black text-[22px] font-semibold leading-normal">
            Invite guests
          </h1>
          <div className="mx-[23px] text-black text-xs font-normal leading-[130%] text-[12px] ">
            We'd love it if you could invite your dinner guests to join our Zumi
            seamless checkout experience so you can easily split the bill
            without waiting around at the restaurant.
          </div>
          <form onSubmit={onCopy} className="flex flex-col gap-y-[20px] ">
            <div className="w-full justify-center items-center inline-flex my-[20px]">
              <div className="grow shrink basis-0 h-12 bg-slate-900 rounded-3xl justify-center items-center gap-2 flex">
                <button className="text-center text-amber-50 text-lg font-semibold leading-tight w-full h-full">
                  <div className="flex items-center gap-2 justify-center">
                    {inviteLoading && <SpinnerIcon />}{" "}
                    <span>Copy link to reservation </span>
                  </div>
                </button>
              </div>
            </div>
          </form>
          <h1 className="mx-[23px] h-[37px] text-black text-[22px] font-semibold leading-normal">
            Reservation notes
          </h1>
          <form onSubmit={onNotesSave}>
            <div className="flex w-full justify-center">
              <HorizontalLine />
            </div>
            <Accordion
              disableGutters
              sx={{
                boxShadow: "none",
                "&:before": {
                  backgroundColor: "transparent !important",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  className={
                    ("text-black text-[16px] font-[600] leading-[110%] font-[Figtree]",
                    styles.reservationNotesElement)
                  }
                >
                  What’s the occasion?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className="flex flex-wrap gap-x-[11px] gap-y-[15px]">
                  {occasions?.map((occasion, index) => (
                    <div
                      onClick={() => {
                        if (preferences.occasion === occasion) {
                          setPreferences({ ...preferences, occasion: null })
                        } else setPreferences({ ...preferences, occasion })
                      }}
                      key={index}
                      className={cn(
                        "cursor-pointer h-9 px-[30px] py-[9px] bg-gray-200 text-slate-80 rounded-[33px] justify-center items-center gap-[21px] inline-flex",
                        {
                          "!bg-[#0B172C] text-white":
                            preferences.occasion === occasion,
                        },
                      )}
                    >
                      <div className="text-center 0 text-base font-medium leading-[17.60px]">
                        {occasion}
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
            <div className="flex w-full justify-center">
              <HorizontalLine />
            </div>
            <Accordion
              disableGutters
              sx={{
                boxShadow: "none",
                "&:before": {
                  backgroundColor: "transparent !important",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  className={
                    ("text-black text-[16px] font-[600] leading-[110%] font-[Figtree]",
                    styles.reservationNotesElement)
                  }
                >
                  Any allergies or restrictions?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <textarea
                  onChange={(e) =>
                    setPreferences({
                      ...preferences,
                      restrictions: e.target.value,
                    })
                  }
                  className="w-full h-[81px] rounded-lg border border-slate-400 resize-none p-1"
                ></textarea>
              </AccordionDetails>
            </Accordion>
            <div className="flex w-full justify-center">
              <HorizontalLine />
            </div>
            <Accordion
              disableGutters
              sx={{
                boxShadow: "none",
                "&:before": {
                  backgroundColor: "transparent !important",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  className={
                    ("text-black text-[16px] font-[600] leading-[110%] font-[Figtree]",
                    styles.reservationNotesElement)
                  }
                >
                  Any special requests?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <textarea
                  onChange={(e) =>
                    setPreferences({ ...preferences, special: e.target.value })
                  }
                  className="w-full h-[81px] rounded-lg border border-slate-400 resize-none p-1"
                ></textarea>
              </AccordionDetails>
            </Accordion>
            <div className="flex w-full justify-center">
              <HorizontalLine />
            </div>
            <div
              className={cn(
                "w-full h-12 px-8 py-3 bg-slate-400 text-amber-50 rounded-3xl justify-center items-center gap-2 inline-flex my-[22px]",
                { "!bg-lime-300 !text-black": preferencesSaved },
              )}
            >
              <button
                disabled={
                  !(
                    preferences.occasion ||
                    preferences.restrictions ||
                    preferences.special
                  ) ||
                  preferencesLoading ||
                  preferencesSaved
                }
                onClick={onNotesSave}
                className={cn(
                  "text-center  text-lg font-semibold leading-tight",
                )}
              >
                <div className="flex items-center gap-2">
                  {preferencesLoading && <SpinnerIcon />}
                  <span>
                    {" "}
                    {preferencesSaved
                      ? "Reservation notes updated!"
                      : "Save reservation notes"}
                  </span>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
      <Snackbar
        open={snackbarVisisble}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Confirm
