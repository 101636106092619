import { React } from "react"
import Card from "@mui/material/Card"
import LargeTableComponet from "../../components/largeTableComponent"
import moment from "moment-timezone"

function PaymentsFailed({ data }) {
  const Headers = [
    { id: 1, name: "is owner", key: "isOwner", sortable: true },
    {
      id: 2,
      name: "stripe customer id",
      key: "stripeCustomerID",
      sortable: true,
    },
    {
      id: 3,
      name: "payment type",
      key: "paymentType",
      sortable: true,
    },
    {
      id: 4,
      name: "card last 4",
      key: "payment_last4",
      sortable: true,
    },
    {
      id: 5,
      name: "amount in pence",
      key: "stripeAmountInPence",
      sortable: true,
    },
    {
      id: 6,
      name: "reason",
      key: "reason",
      sortable: true,
    },
    {
      id: 7,
      name: "date",
      key: "createdAt",
      sortable: true,
      renderContent: (data) => {
        return moment(data).format("DD/MM/YYYY hh:mm:ss A")
      },
    },
  ]

  return (
    <>
      <Card sx={{ flexDirection: "row", p: 2, m: 2 }}>
        <span>
          <LargeTableComponet
            edit={true}
            hasSearch={false}
            hasSortFilter={false}
            hasDateFilter={false}
            headers={Headers}
            data={data}
            title="Payments Failed"
          />
        </span>
      </Card>
    </>
  )
}

export default PaymentsFailed
