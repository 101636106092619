import React, { useEffect } from "react"
import "./billStyle.css"
import { CustomLoader } from "../../components/custom/Icons"

const SucceededPaymentInfo = () => {
  useEffect(() => {
    window.top.postMessage("3DS-authentication-complete", "*")
  }, [])
  return (
    <div className="success-payment-div">
      <CustomLoader />
      <p className="loader-fetching-text">Fetching your bill...</p>
    </div>
  )
}

export default SucceededPaymentInfo
