import { Box, CircularProgress } from "@mui/material"
import React from "react"
import styles from "./BigCircularProgress.module.css"

function BigCircularProgress() {
  return (
    <Box className={styles.container}>
      <CircularProgress className={styles.circularProgress} />
    </Box>
  )
}

export default BigCircularProgress
