import { Button } from "@mui/material"
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import moment from "moment-timezone"
import React, { useState } from "react"

function EditableDateData({ onSubmit, date, title }) {
  const [toggleField, setToggleField] = useState(false)
  const [dateValue, setDateValue] = useState(date)
  const handleDateEdit = () => {
    if (toggleField) {
      onSubmit(dateValue)
      setToggleField(false)
    } else {
      setToggleField(true)
    }
  }
  return (
    <div className="flex gap-2 items-center">
      <span className="font-semibold">{title}</span>
      {toggleField ? (
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
        >
          <DateTimePicker
            value={dateValue}
            onChange={(newValue) => setDateValue(newValue)}
            disablePast
            ampm={false}
          />
        </LocalizationProvider>
      ) : (
        <span className="font-semibold">
          {moment(date).format("YYYY-MM-DD | HH:mm")}
        </span>
      )}
      <Button className="h-[20px]" onClick={handleDateEdit}>
        {toggleField ? "Submit" : "Edit"}
      </Button>
      {toggleField && (
        <Button
          className="h-[20px]"
          onClick={() => setToggleField(false)}
          color="error"
        >
          Cancel
        </Button>
      )}
    </div>
  )
}

export default EditableDateData
