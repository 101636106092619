import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material"
import { useEffect, useState } from "react"
import {
  fetchRestaurantById,
  rsyncReservations,
  updateSettings,
} from "../../api/v2/settings"
import { useParams } from "react-router"
import BigCircularProgress from "../components/BigCircularProgress"
import { useMutation } from "@tanstack/react-query"
import moment from "moment"
import { getSubtenantId } from "../../utils/helperFunctions"
import { toast } from "react-toastify"

function range(start, end, val = 1) {
  return Array(end - start + 1)
    .fill(undefined, undefined, undefined)
    .map((_, idx) => (start + idx) * val)
}

function ToggleSetting({ disabled, checked, onChange, title, description }) {
  return (
    <section className="m-4 grid grid-cols-1 md:grid-cols-4 gap-3">
      <h2>{title}</h2>
      <div className="col-span-3">
        <Switch disabled={disabled} checked={checked} onChange={onChange} />
        <p className="text-gray-400">{description}</p>
      </div>
    </section>
  )
}

export default function Setting() {
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params["*"])
  const [fromDate, setFromDate] = useState(moment().format())
  const [toDate, setToDate] = useState(moment().add(12, "months").format())
  const [settingsValues, setSettingsValues] = useState({})
  const [settingsBookedByValues, setSettingsBookedByValues] = useState({})
  const [maximumPayment, setmaximumPayment] = useState(0)
  const [loading, setLoading] = useState(true)
  const [success, setsuccess] = useState(false)
  const rsyncReservationsMutation = useMutation({
    mutationFn: rsyncReservations,
    onSuccess: () => {
      toast.success("Updated Successfully")
      setsuccess(true)
    },
    onError: (e) => {
      toast.error("Update Failed")
      setsuccess(false)
    },
  })

  const handleSwitch = async (val) => {
    const res = await updateSettings(
      tenantId,
      {
        active: val,
      },
      subtenantId,
    )
    if (res) {
      toast.success("Updated Successfully")
      setSettingsValues({
        ...settingsValues,
        active: val,
      })
      if (!val) {
        window.open(settingsValues?.backup_url, "_blank")
      }
    } else if (!res) {
      toast.error("Update Failed")
    }
  }
  const handleSettings = async () => {
    const setting = {}
    if (settingsValues.max_payment) {
      setting.max_payment = settingsValues.max_payment
    }
    if (settingsValues.max_splits) {
      setting.max_splits = settingsValues.max_splits
    }
    if (Object.keys(setting).length === 0) {
      toast.error("Please select an option")
      return
    }
    try {
      await updateSettings(tenantId, setting, subtenantId)
      setsuccess(true)
      toast.success("Updated Successfully")
    } catch (error) {
      setsuccess(false)
      toast.error("Update Failed")
    }
  }
  const handleSettingUpdate = async (settingKey, val) => {
    try {
      if (typeof val === "undefined") return null
      setSettingsValues({
        ...settingsValues,
        [settingKey]: val,
      })
      await updateSettings(tenantId, { [settingKey]: val }, subtenantId)
      setsuccess(true)
      toast.success("Updated Successfully")
    } catch (error) {
      toast.error("Update Failed")
      setSettingsValues({
        ...settingsValues,
        [settingKey]: !val,
      })
    }
  }
  const handleSettingBookedByUpdate = async (settingKey, val) => {
    try {
      if (typeof val === "undefined") return null
      setSettingsBookedByValues({
        ...settingsValues,
        [settingKey]: val,
      })
      await updateSettings(tenantId, { [settingKey]: val }, subtenantId)
      setsuccess(true)
      toast.success("Updated Successfully")
    } catch (error) {
      toast.error("Update Failed")
      setSettingsBookedByValues({
        ...settingsValues,
        [settingKey]: !val,
      })
    }
  }

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      const response = await fetchRestaurantById(tenantId, subtenantId)
      setSettingsValues(response?.config?.integrations?.sevenrooms)
      setSettingsBookedByValues(
        response?.config?.integrations?.sevenrooms?.booked_by,
      )
      setmaximumPayment(response?.config?.integrations?.sevenrooms?.max_payment)
      setLoading(false)
    }
    fetchData()
  }, [tenantId])

  return (
    <>
      {loading ? (
        <BigCircularProgress />
      ) : (
        <main className="p-3 m-2 rounded-lg bg-[#FFF] min-h-screen">
          <section className="m-4">
            <h2>Controls</h2>
          </section>
          <hr />
          <section className="m-4 grid grid-cols-1 md:grid-cols-4 gap-3">
            <h2>Activate/Deactivate</h2>
            <div className="col-span-3">
              <Switch
                checked={settingsValues?.active}
                onChange={async (e) => {
                  await handleSwitch(e.target.checked)
                }}
              />
              <p className="text-gray-400">
                Deactivating account will make you lose a lot of good features.
              </p>
            </div>
          </section>
          <hr />
          {typeof settingsValues?.emergency_shutoff === "boolean" && (
            <>
              <ToggleSetting
                title={"Emergency shutoff"}
                description={
                  "Activating emergency shutoff will stop payment flow"
                }
                checked={settingsValues?.emergency_shutoff}
                onChange={async (e) => {
                  await handleSettingUpdate(
                    "emergency_shutoff",
                    e.target.checked,
                  )
                }}
              />
              <hr />
            </>
          )}
          {typeof settingsValues?.bill_sms_status === "boolean" && (
            <>
              <ToggleSetting
                title={"Send Bill SMS"}
                description={
                  "Deactivating bill sms will stop sending bill url to guests"
                }
                disabled={
                  !settingsBookedByValues?.api &&
                  !settingsBookedByValues?.widget &&
                  !settingsBookedByValues?.walkin &&
                  !settingsBookedByValues?.other &&
                  !settingsValues?.guest_sms_lock &&
                  !settingsValues?.charge
                }
                checked={
                  !(
                    !settingsBookedByValues?.widget &&
                    !settingsValues?.guest_sms_lock &&
                    !settingsBookedByValues?.api &&
                    !settingsBookedByValues?.walkin &&
                    !settingsBookedByValues?.other &&
                    !settingsValues?.charge
                  ) && settingsValues?.bill_sms_status
                }
                onChange={async (e) => {
                  await handleSettingUpdate("bill_sms_status", e.target.checked)
                }}
              />
              <hr />
            </>
          )}
          {typeof settingsBookedByValues?.api === "boolean" && (
            <>
              <ToggleSetting
                title={"Send Bill SMS For Albert Api"}
                description={
                  "Deactivating bill sms will stop sending bill url to reservations booked by albert api"
                }
                disabled={!settingsValues?.bill_sms_status}
                checked={
                  settingsValues?.bill_sms_status && settingsBookedByValues?.api
                }
                onChange={async (e) => {
                  await handleSettingBookedByUpdate("api", e.target.checked)
                }}
              />
              <hr />
            </>
          )}
          {typeof settingsBookedByValues?.widget === "boolean" && (
            <>
              <ToggleSetting
                title={"Send Bill SMS For Booking Widget"}
                description={
                  "Deactivating bill sms will stop sending bill url to reservations booked by booking widget"
                }
                disabled={!settingsValues?.bill_sms_status}
                checked={
                  settingsValues?.bill_sms_status &&
                  settingsBookedByValues?.widget
                }
                onChange={async (e) => {
                  await handleSettingBookedByUpdate("wedgit", e.target.checked)
                }}
              />
              <hr />
            </>
          )}
          {typeof settingsBookedByValues?.walkin === "boolean" && (
            <>
              <ToggleSetting
                title={"Send Bill SMS For Walk In"}
                description={
                  "Deactivating bill sms will stop sending bill url to reservations booked by walk in"
                }
                disabled={!settingsValues?.bill_sms_status}
                checked={
                  settingsValues?.bill_sms_status &&
                  settingsBookedByValues?.walkin
                }
                onChange={async (e) => {
                  await handleSettingBookedByUpdate("walkin", e.target.checked)
                }}
              />
              <hr />
            </>
          )}
          {typeof settingsBookedByValues?.other === "boolean" && (
            <>
              <ToggleSetting
                title={"Send Bill SMS For Other Booking Ways"}
                description={
                  "Deactivating bill sms will stop sending bill url to reservations booked by another way"
                }
                disabled={!settingsValues?.bill_sms_status}
                checked={
                  settingsValues?.bill_sms_status &&
                  settingsBookedByValues?.other
                }
                onChange={async (e) => {
                  await handleSettingBookedByUpdate("other", e.target.checked)
                }}
              />
              <hr />
            </>
          )}
          {typeof settingsValues?.guest_sms_lock === "boolean" && (
            <>
              <ToggleSetting
                title={"Send Bill SMS For Guests less than or equal 6"}
                description={
                  "Deactivating bill sms will stop running max guest number logic"
                }
                disabled={!settingsValues?.bill_sms_status}
                checked={
                  settingsValues?.bill_sms_status &&
                  settingsValues?.guest_sms_lock
                }
                onChange={async (e) => {
                  await handleSettingUpdate("guest_sms_lock", e.target.checked)
                }}
              />
              <hr />
            </>
          )}
          {typeof settingsValues?.charge === "boolean" && (
            <>
              <ToggleSetting
                title={"Send Charge To Seven Rooms"}
                description={
                  "Deactivating will stop sending charges to seven rooms"
                }
                disabled={!settingsValues?.bill_sms_status}
                checked={
                  settingsValues?.bill_sms_status && settingsValues?.charge
                }
                onChange={async (e) => {
                  await handleSettingUpdate("charge", e.target.checked)
                }}
              />
              <hr />
            </>
          )}
          {typeof settingsValues?.enable_email === "boolean" && (
            <>
              <ToggleSetting
                title={"Send Bill Email"}
                description={"Deactivating will stop sending bill email"}
                checked={settingsValues?.enable_email}
                onChange={async (e) => {
                  await handleSettingUpdate("enable_email", e.target.checked)
                }}
              />
              <hr />
            </>
          )}
          <section className="m-4 grid grid-cols-1 md:grid-cols-4 gap-3">
            <h2>Rsync Reservations</h2>
            <div className="col-span-3 flex flex-wrap gap-2 flex-col">
              <div className={"col-span-3 flex flex-wrap gap-2"}>
                <Button
                  variant="contained"
                  disabled={rsyncReservationsMutation.isLoading}
                  onClick={() => {
                    rsyncReservationsMutation.mutate({
                      sevenrooms_venue_id: settingsValues?.venue_id,
                      from_date: fromDate,
                      to_date: toDate,
                      tenantId,
                      subtenantId: subtenantId ?? null,
                    })
                  }}
                >
                  Sync Reservations
                </Button>
                <TextField
                  id="from-date"
                  label="From Date"
                  type="date"
                  value={moment(fromDate).format("YYYY-MM-DD")}
                  onChange={(e) => setFromDate(moment(e.target.value).format())}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id="to-date"
                  label="To Date"
                  type="date"
                  value={moment(toDate).format("YYYY-MM-DD")}
                  onChange={(e) => setToDate(moment(e.target.value).format())}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <p className="text-gray-400">
                Note: The maximum number of reservations that can be synced is
                400.
              </p>
            </div>
          </section>
          <hr />
          <section className="m-4 grid grid-cols-1 md:grid-cols-4 gap-3">
            <h2>Payments</h2>
            <div className="col-span-3 flex flex-wrap">
              <div className="basis-full md:basis-1/2 flex  flex-col my-4">
                <div className="mx-3 flex  flex-col">
                  <label>Maximum payment amount</label>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Select an option</InputLabel>
                    <Select
                      value={maximumPayment}
                      onChange={(e) => {
                        setmaximumPayment(e.target.value)
                        setSettingsValues({
                          ...settingsValues,
                          max_payment: e.target.value,
                        })
                      }}
                      label="Max Payment"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {range(1, 10, 100).map((el) => {
                        return (
                          <MenuItem key={el} value={el}>
                            <em>{el}</em>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <Button onClick={handleSettings} variant="contained">
                    Update
                  </Button>
                </div>
              </div>
              <div className="basis-full md:basis-1/2  my-4">
                <div className="mx-3 flex flex-col">
                  <label>Maximum amount of splits</label>
                  <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel>Select an option</InputLabel>
                    <Select
                      value={settingsValues.max_splits}
                      onChange={(e) =>
                        setSettingsValues({
                          ...settingsValues,
                          max_splits: e.target.value,
                        })
                      }
                      label="Maximum splits"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {range(1, 10, 1).map((el) => {
                        return (
                          <MenuItem key={el} value={el}>
                            <em>{el}</em>
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                  <Button onClick={handleSettings} variant="contained">
                    Update
                  </Button>
                </div>
              </div>
            </div>
          </section>
          {/* <hr />
          <section className="m-4 grid grid-cols-1 md:grid-cols-4 gap-3">
            <h2>Reservations</h2>
            <div className="col-span-3 flex flex-wrap">
              <div className="basis-full my-4">
                <div className="basis-full md:basis-1/2 flex  my-4">
                  <div className="mx-3 flex flex-col basis-full md:basis-1/2">
                    <label>Connect easyTable account</label>
                    <br />
                    <FormControl onSubmit={handleSubmitEtb}>
                      <label className="my-3">Email </label>
                      <TextField
                        value={settingsValues?.etb_email}
                        onChange={(e) => {
                          setSettingsValues({
                            ...settingsValues,
                            etb_email: e.target.value,
                          });
                        }}
                        required
                        InputProps={{
                          placeholder: "Enter Email for EasyTable account",
                        }}
                      />
                      <label className="my-3">Password </label>
                      <TextField
                        value={settingsValues?.etb_password}
                        onChange={(e) => {
                          setSettingsValues({
                            ...settingsValues,
                            etb_password: e.target.value,
                          });
                        }}
                        required
                        InputProps={{
                          placeholder: "Enter password for EasyTable account",
                        }}
                      />
                      <label className="my-3">Etb id </label>
                      <TextField
                        value={settingsValues?.etb_id}
                        onChange={(e) => {
                          setSettingsValues({
                            ...settingsValues,
                            etb_id: e.target.value,
                          });
                        }}
                        required
                        InputProps={{
                          placeholder: "Enter id for EasyTable account", // This is the placeholder text
                        }}
                      />
                      <label className="my-3">Etb Restaurant id </label>
                      <TextField
                        value={settingsValues?.etb_restaurant_id}
                        onChange={(e) => {
                          setSettingsValues({
                            ...settingsValues,
                            etb_restaurant_id: e.target.value,
                          });
                        }}
                        required
                        InputProps={{
                          placeholder: "Enter id for Resatuarant EasyTable", // This is the placeholder text
                        }}
                      />
                      <Button
                        onClick={handleSubmitEtb}
                        sx={{ m: 2 }}
                        variant="contained"
                      >
                        add Easy Table account
                      </Button>
                    </FormControl>
                    <br />
                    <label className="my-3">Reservation backup form</label>
                    <TextField
                      value={settingsValues?.backup_url}
                      onChange={(e) =>
                        setSettingsValues({
                          ...settingsValues,
                          backup_url: e.target.value,
                        })
                      }
                      required
                    />
                    <div className="my-3">
                      <Button onClick={handleBackupUrl} variant="contained">
                        Update
                      </Button>
                      <p className="text-gray-400">
                        This is a backup URL in case of any thing got wrong.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />
          <section className="m-4 grid grid-cols-1 md:grid-cols-4 gap-3">
            <h2>POS Integration</h2>
            <div className="col-span-3">
              <label>Connect Blinq account</label>
              <br />
              <br />
              <FormControl onSubmit={handleSubmitBlinq}>
                <label className="my-3">Username </label>
                <TextField
                  onChange={(e) => {
                    setSettingsValues({
                      ...settingsValues,
                      blinqme_username: e.target.value,
                    });
                  }}
                  value={settingsValues?.blinqme_username}
                  required
                />
                <label className="my-3">Email </label>
                <TextField
                  onChange={(e) => {
                    setSettingsValues({
                      ...settingsValues,
                      blinqme_email: e.target.value,
                    });
                  }}
                  required
                  value={settingsValues?.blinqme_email}
                />
                <label className="my-3">Password </label>
                <TextField
                  onChange={(e) => {
                    setSettingsValues({
                      ...settingsValues,
                      blinqme_password: e.target.value,
                    });
                  }}
                  required
                  value={settingsValues?.blinqme_password}
                />
                <label className="my-3">Blinq API Pass </label>
                <TextField
                  onChange={(e) => {
                    setSettingsValues({
                      ...settingsValues,
                      blinkme_api_pass: e.target.value,
                    });
                  }}
                  required
                  value={settingsValues?.blinkme_api_pass}
                />
                <Button
                  type="submit"
                  sx={{ m: 2 }}
                  variant="contained"
                  onClick={handleSubmitBlinq}
                >
                  add blinq pos account
                </Button>
              </FormControl>
              <Snackbar
                open={open}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
              >
                <Alert
                  severity={success ? "success" : "error"}
                  sx={{ width: "100%" }}
                >
                  {success ? "Updated Successfully" : "Update Failed"}
                </Alert>
              </Snackbar>
            </div>
          </section> */}
        </main>
      )}
    </>
  )
}
