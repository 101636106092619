import React from "react"
import HorizontalLine from "../../components/custom/HorizontalLine"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material"
import { ExpandMoreOutlined } from "@mui/icons-material"
import Contact from "../../components/contact/Contact"

const FAQSUPPORT = () => {
  return (
    <div className="dark" id="dark_layout">
      <>
        <div className="w-full min-h-[calc(100vh-60px)] lg:min-h-[calc(100vh-120px)] lg:max-w-[50%] relative flex flex-col items-center gap-y-[67px] py-[18px] px-[24px] lg:px-[70px] lg:py-[45px] bg-[#0B172C] ">
          <div className="w-full gap-y-[18px] flex flex-col items-center">
            <div className="w-full h-6 text-amber-50 text-[32px] font-semibold leading-normal mb-[28px]">
              FAQ
            </div>
            <Accordion
              disableGutters
              className="!shadow-none !bg-transparent !w-full"
              defaultExpanded={false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined className="!text-amber-50" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="text-amber-50 font-[400] text-base  leading-[130%]">
                  What is Zumi?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className=" text-gray-400 text-s font-[400]  leading-[130%]">
                  Zumi is a seamless checkout that partners with your favourite
                  restaurants so you can forget about the payment and focus on
                  the meal without having to wait at the end of the meal. When
                  you make a reservation and enter your card details, Zumi holds
                  the details on file (until your reservation is over) to give
                  you the ability to just walk out when you're ready to leave
                  the restaurant which should save precious time for both the
                  restaurant and the customer.
                </div>
              </AccordionDetails>
            </Accordion>
            <HorizontalLine />
            <Accordion
              disableGutters
              className="!shadow-none !bg-transparent !w-full"
              defaultExpanded={false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined className="!text-amber-50" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="text-amber-50 font-[400] text-base  leading-[130%]">
                  When will I be charged?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className=" text-gray-400 text-s font-[400]  leading-[130%]">
                  If you decide to just walk out after your meal then your card
                  on file will be charged within 1 hour of leaving the
                  restaurant. Alternatively, you may decide to pay on your
                  device or split the bill within the 1 hour time frame.
                </div>
              </AccordionDetails>
            </Accordion>
            <HorizontalLine />
            <Accordion
              disableGutters
              className="!shadow-none !bg-transparent !w-full"
              defaultExpanded={false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined className="!text-amber-50" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="text-amber-50 font-[400] text-base  leading-[130%]">
                  How do I change my payment method?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className=" text-gray-400 text-s font-[400]  leading-[130%]">
                  You can click on the 'modify payment method' link in your
                  email confirmation at any point before the end of your meal to
                  change the payment method. Alternatively, you can also wait to
                  view the bill and change the payment method there.
                </div>
              </AccordionDetails>
            </Accordion>
            <HorizontalLine />
            <Accordion
              disableGutters
              className="!shadow-none !bg-transparent !w-full"
              defaultExpanded={false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined className="!text-amber-50" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="text-amber-50 font-[400] text-base  leading-[130%]">
                  What if I have an issue?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className=" text-gray-400 text-s font-[400]  leading-[130%]">
                  If you spot an issue with the bill whilst at the restaurant,
                  please speak to the Ida team. If you have left the restaurant
                  and there was an issue with the bill or payment, please get in
                  touch with us below and we will resolve the issue for you.
                </div>
              </AccordionDetails>
            </Accordion>
            <HorizontalLine />
            <Accordion
              disableGutters
              className="!shadow-none !bg-transparent !w-full"
              defaultExpanded={false}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreOutlined className="!text-amber-50" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className="text-amber-50 font-[400] text-base  leading-[130%]">
                  How do we split the bill?
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <div className=" text-gray-400 text-s font-[400]  leading-[130%]">
                  Zumi enables you to split the bill with your guests. As the
                  reservation holder or a registered guest (a guest that fills
                  out card details ahead of the meal), you will receive an SMS
                  with a link to the bill. When you're finished with the meal,
                  you can click on the link to review your bill and then choose
                  whether to pay in full or to split the bill. Once you press
                  split, you will simply state the amount of people at your
                  table and how many people you will be paying for. You will
                  then be able to copy the link (to the bill) and share with
                  your other guests for them to pay.
                  <br />
                  <br />
                  For a guest that hasn't registered ahead of the meal, you
                  would just share the link with them and they can pay their
                  share.
                </div>
              </AccordionDetails>
            </Accordion>
            <HorizontalLine />
            <Contact />
          </div>
        </div>
      </>
    </div>
  )
}

export default FAQSUPPORT
