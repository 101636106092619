import { CircularProgress } from "@mui/material"
import React from "react"

const CompletePaymentLoadingNew = () => {
  return (
    <button className="w-full my-5 bg-transperant text-color-dark p-3 rounded-3xl font-semibold border border-color-dark">
      <CircularProgress
        sx={{ color: "#2A2B49", zoom: "30%" }}
        className="me-4"
      />{" "}
      Completing payment
    </button>
  )
}

export default CompletePaymentLoadingNew
