import React, { Fragment, useEffect, useRef, useState } from "react"
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  CheckGreenFillIcon,
  CheckGreenIcon,
  CheckWhiteIcon,
  CustomLoader,
  SpinnerIcon,
} from "../../components/custom/Icons"
import currencies from "../../utils/currencies.json"
import {
  addChargeToSvr,
  addPaymentFailure,
  addStripePaymentLog,
  createPaymentIntent,
  getPaymentMethod,
} from "../../api/v2/bills"
import { CardIcon } from "../modify payment"
import { fetchReservationOwnerPayments } from "../../api/v2/reservations"
import ConfirmPayment from "../../components/custom/ConfirmPayment"
import moment from "moment"
import { toast } from "react-toastify"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri"
import { flushSync } from "react-dom"
import { FaFacebookF, FaInstagram, FaXTwitter } from "react-icons/fa6"
import SwipeButtonDark from "../../components/custom/SwipeButtonDark"
import SplitBillInfo from "./SplitBillInfo"
import PaymentFormInfo from "./PaymentFormInfo"
import ChangePaymentMethodInfo from "./ChangePaymentMethodInfo"
import CalendarIconNew from "../../components/SVG/CalendarIconNew"
import PersonIconNew from "../../components/SVG/PersonIconNew"
import CuttedHorizontalLineNew from "../../components/custom/CuttedHorizontalLineNew"
import CompletePaymentLoadingNew from "../../components/custom/CompletePaymentLoadingNew"
import NotifyMarkNew from "../../components/SVG/NotifyMarkNew"
import {
  mailBill,
  payBillInFull,
  payInFull3DS,
  payInFullApplePayment,
  updateSvrReservation,
} from "../../api/v2/bills"
import "./billStyle.css"
import { IsValidEmail } from "../../components/custom/IsValidEmail"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import ProblemWithBill from "./ProblemWithBill"
import StripeCheckoutForm from "../../components/CheckoutFormInfo"
import { calculateAmountInPence } from "../../utils/helperFunctions"

loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

const PayingBillComponentInfo = () => {
  const [ownerPaid, setOwnerPaid] = useState(false)
  const [sendEmail, setSendEmail] = useState("")
  const [showConfirmComponent, setShowConfirmComponent] = useState(false)
  const [owner, setOwner] = useState(null)
  const { reservationID } = useParams()
  const [searchParams] = useSearchParams()
  const guestID = searchParams.get("id")
  const [disableBillMail, setDisableBillMail] = useState(false)
  const [billEmailNotValid, setBillEmailNotValid] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState({})
  const [isLoadingModal, setIsLoadingModal] = useState(true)
  const [showAll, setShowAll] = React.useState(false)
  const [splitBill, setSplitBill] = useState(false)
  const [someoneElse, setSomeoneElse] = useState(false)
  const [expandedSectionTab, setExpandedSectionTab] = useState([0, 1, 2])

  const handleAccordionChangeTab = (uuids) => {
    setExpandedSectionTab(uuids)
  }
  const [expandedFooter, setExpandedFooter] = useState([])

  const handleAccordionFooter = (expandedItems) => {
    setExpandedFooter(expandedItems)
  }

  const [payFull, setPayFull] = useState(true)
  const [anotherPayment, setAnotherPayment] = useState(false)
  const [isWalletConfirming, setIsWalletConfirming] = useState(false)
  const [isChange, setIsChange] = useState(false)
  const settleRef = useRef()
  const nav = useNavigate()
  const stripe = useStripe()
  const {
    invoiceData,
    statusPaid,
    setStatusPaid,
    invoiceID,
    setShowPaymentBtn,
    showPaymentBtn,
    portionPaid,
    restaurantInfo,
  } = useOutletContext()

  const shownItems = showAll ? invoiceData.items : invoiceData.items.slice(0, 3)
  const unShownLength = invoiceData.items.length - 3
  const currencySymbol = Object.values(currencies).find(
    (c) => c.code.toLowerCase() === invoiceData.currency.toLowerCase(),
  )?.symbol

  const {
    data: ownerData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [invoiceData.NewBookingID, "Payments"],
    queryFn: () => fetchReservationOwnerPayments(invoiceData.NewBookingID),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!invoiceData.NewBookingID,
  })
  const payInFullMutation = useMutation({
    mutationFn: payBillInFull,
    onSuccess: async (data) => {
      if (data?.next_action?.redirect_to_url) {
        setPaymentIntent(data)
        setIsLoadingModal(true)
        setShowModal(true)
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
        return
      }
      updateReservationMutation.mutate({
        reservation_id: reservationID,
        data: { status: "CUSTOM_STATUS_24" },
      })
      addChargeToSvrMutation.mutate({
        reservation_id: reservationID,
        paymentIntentID: data.paymentIntentID,
        amountInPound: invoiceData.price,
        subtotal: invoiceData.subtotal,
      })
      setStatusPaid(true)
      setShowPaymentBtn(false)
    },
    onError: (err) => {
      toast.error(err.message ?? "pay in full failed")
    },
  })
  const payInFullApplePaymentMutation = useMutation({
    mutationFn: payInFullApplePayment,
    onSuccess: (data) => {
      updateReservationMutation.mutate({
        reservation_id: reservationID,
        data: { status: "CUSTOM_STATUS_24" },
      })
      addChargeToSvrMutation.mutate({
        reservation_id: reservationID,
        paymentIntentID: data.paymentIntentID,
        amountInPound: invoiceData.price,
        subtotal: invoiceData.subtotal,
      })
      setStatusPaid(true)
      setShowPaymentBtn(false)
      setIsWalletConfirming(false)
    },
    onError: (err) => {
      toast.error(err.message ?? "pay in full with wallet failed")
      setIsWalletConfirming(false)
    },
  })
  const payInFull3DSMutation = useMutation({
    mutationFn: payInFull3DS,
    onSuccess: (data) => {
      updateReservationMutation.mutate({
        reservation_id: reservationID,
        data: { status: "CUSTOM_STATUS_24" },
      })
      addChargeToSvrMutation.mutate({
        reservation_id: reservationID,
        paymentIntentID: data.paymentIntentID,
        amountInPound: invoiceData.price,
        subtotal: invoiceData.subtotal,
      })
      setShowModal(false)
      document.body.style.overflow = "auto"
      setStatusPaid(true)
      setShowPaymentBtn(false)
      setIsLoadingModal(false)
    },
    onError: (err) => {
      setShowModal(false)
      document.body.style.overflow = "auto"
      toast.error(err.message ?? "payment in 3ds failed")
      setIsLoadingModal(false)
    },
  })

  const mailBillMutation = useMutation({
    mutationFn: mailBill,
    onError: (err) => {
      toast.error(err.message ?? "sending email failed")
    },
  })
  const updateReservationMutation = useMutation({
    mutationFn: updateSvrReservation,
    onSuccess: () => {},
    onError: (err) => {
      toast.error(err.message ?? "updating reservation failed")
    },
  })

  const addPaymentFailedMutation = useMutation(addPaymentFailure)

  const addChargeToSvrMutation = useMutation({
    mutationFn: addChargeToSvr,
    onSuccess: () => {},
    onError: (err) => {
      toast.error(err.message ?? "adding charge failed")
    },
  })

  const onBillMail = () => {
    const validEmail = IsValidEmail(sendEmail)
    if (!validEmail) return setBillEmailNotValid(true)
    setBillEmailNotValid(false)
    mailBillMutation.mutate(
      {
        billID: invoiceID,
        email: sendEmail,
        paid:
          (invoiceData.price / invoiceData.guestsCount) *
          (portionPaid || ownerPaid?.portion),
      },
      {
        onSuccess: () => {
          setSendEmail("")
        },
      },
    )
    setDisableBillMail(true)
  }

  const validateOnChange = (e) => {
    const validEmail = IsValidEmail(e)
    if (!validEmail) return setBillEmailNotValid(true)
    else return setBillEmailNotValid(false)
  }

  const onCopy = () => {
    window.navigator.clipboard
      .writeText(`${window.location.origin}/bill-info/${reservationID}`)
      .then(() => {
        toast.success("Link has been copied")
      })
  }

  useEffect(() => {
    const isOwner = invoiceData.guestID === guestID
    const ownerData = invoiceData?.splitters?.find((i) => i.isOwner)
    if (invoiceData.pay_method === "split") {
      setSplitBill(true)
    }
    if (ownerData) setShowPaymentBtn(false)
    if (!isOwner) {
      setOwner(false)
    } else {
      if (ownerData) setOwnerPaid(ownerData)
      if (isOwner) setOwner(true)
    }
    if (guestID && !isOwner) {
      return nav("/")
    }
  }, [invoiceData])

  useEffect(() => {
    const handleMessage = async (event) => {
      if (
        event.data === "3DS-authentication-complete" &&
        paymentIntent?.client_secret
      ) {
        const { paymentIntent: intent } = await stripe.retrievePaymentIntent(
          paymentIntent.client_secret,
        )
        if (intent.status === "succeeded") {
          payInFull3DSMutation.mutate({
            invoiceID,
            paymentIntentID: intent.id,
          })
        } else {
          toast.error(
            intent.last_payment_error.message ?? "authenticating failed",
          )
          setShowModal(false)
          setIsLoadingModal(false)
          document.body.style.overflow = "auto"
        }
      }
    }
    window.addEventListener("message", handleMessage, false)
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [stripe, paymentIntent])

  useEffect(() => {
    if (isWalletConfirming) {
      window.scrollTo(0, 0)
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isWalletConfirming])

  const onConfirm = async (elementsObj, stripeObj) => {
    try {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      setIsWalletConfirming(true)
      const { client_secret } = await createPaymentIntent({
        amountInPence: calculateAmountInPence(invoiceData),
        description: `Invoice:${invoiceID},Reservation:${
          invoiceData.NewBookingID
        },Restaurant:${invoiceData.restaurantName} ${invoiceData.restaurantID},Date: ${moment().format()}`,
        meta: {
          invoiceNum: invoiceData.receiptNum,
          table: invoiceData.table,
        },
      })
      let { error, paymentIntent } = await stripeObj.confirmPayment({
        elements: elementsObj,
        clientSecret: client_secret,
        confirmParams: {
          expand: ["payment_method"],
          return_url: `${window.location.origin}/bill-info/${reservationID}/`,
        },
        redirect: "if_required",
      })
      let modifiedPaymentIntent = paymentIntent ? { ...paymentIntent } : {}
      let paymentBodySucceeded = {}
      let paymentModifiedBodySucceeded = {}
      let paymentBodyNotSucceeded = {}
      let paymentBodyError = {}
      if (error) {
        toast.error(error.message)
        paymentBodyError = {
          reservationID,
          payment_intent: error.payment_intent,
          payment_method: error.payment_method,
          isOwner: owner,
          reason: error.message,
        }
        addPaymentFailedMutation.mutate(paymentBodyError)
        setIsWalletConfirming(false)
      } else if (paymentIntent.status !== "succeeded") {
        toast.error("payment not succeeded because of some reason")
        paymentBodyNotSucceeded = {
          reservationID,
          payment_intent: paymentIntent,
          payment_method: paymentIntent.payment_method,
          isOwner: owner,
          reason: "payment not succeeded because of some reason",
        }
        addPaymentFailedMutation.mutate(paymentBodyNotSucceeded)
        setIsWalletConfirming(false)
      } else if (paymentIntent.status === "succeeded") {
        paymentBodySucceeded = {
          billID: invoiceID,
          email: owner
            ? invoiceData?.guestEmail ?? "no-email@owner.com"
            : "guest@guest.com",
          name: owner
            ? `${invoiceData.firstName} ${invoiceData.lastName}`
            : "guest",
          paymentMethod: {
            id: paymentIntent.payment_method?.id ?? null,
            card: { last4: paymentIntent.payment_method?.card?.last4 ?? null },
          },
          paymentType:
            paymentIntent.payment_method.card?.wallet ?? null
              ? paymentIntent.payment_method?.card?.wallet?.type ?? null
              : paymentIntent.payment_method?.type ?? null,
          isOwner: !!guestID ?? null,
          paymentIntent: paymentIntent ?? null,
        }
        if (!modifiedPaymentIntent.payment_method.id) {
          modifiedPaymentIntent.payment_method = await getPaymentMethod(
            modifiedPaymentIntent.payment_method,
          )
        }
        if (!modifiedPaymentIntent.payment_method.card) {
          modifiedPaymentIntent.payment_method.card = {
            last4: null,
            wallet: { type: modifiedPaymentIntent.payment_method.type ?? null },
          }
        }
        paymentModifiedBodySucceeded = {
          billID: invoiceID,
          email: owner
            ? invoiceData.guestEmail ?? "no-email@owner.com"
            : "guest@guest.com",
          name: owner
            ? `${invoiceData.firstName} ${invoiceData.lastName}`
            : "guest",
          paymentMethod: {
            id: modifiedPaymentIntent.payment_method.id,
            card: { last4: modifiedPaymentIntent.payment_method.card.last4 },
          },
          paymentType: modifiedPaymentIntent.payment_method.card?.wallet
            ? modifiedPaymentIntent.payment_method.card.wallet.type
            : modifiedPaymentIntent.payment_method.type,
          isOwner: !!guestID,
          paymentIntent: modifiedPaymentIntent,
        }
        payInFullApplePaymentMutation.mutate(paymentModifiedBodySucceeded)
      }
      await addStripePaymentLog({
        reservationID,
        stripeLog: {
          stripePaymentIntent: paymentIntent ?? {},
          stripeModifiedPaymentIntent: modifiedPaymentIntent ?? {},
          stripePaymentError: error ?? {},
        },
        zumiLog: {
          zumiPaymentLog: paymentBodySucceeded,
          zumiModifiedPaymentLog: paymentModifiedBodySucceeded,
          zumiPaymentError: paymentBodyError,
        },
      })
    } catch (err) {
      toast.error(err.message)
      await addStripePaymentLog({
        reservationID,
        errorLog: { message: err.message, stack: err.stack },
      })
      setIsWalletConfirming(false)
    }
  }
  if (isLoading) return null

  const yourBillTabContent = (
    <>
      <div className="bill-tab-view">
        <div className="bill-tab-outer-view">
          {invoiceData.bill_status === "paid" && (
            <p className="bill-paid-text">
              <CheckGreenIcon /> Bill paid
            </p>
          )}
          {invoiceData.splitters?.length > 0 &&
            invoiceData.amount_unpaid > 0 && (
              <p className="remaining-guest-text">
                <NotifyMarkNew /> {Number(invoiceData.amount_unpaid).toFixed(2)}{" "}
                remaining for guests to pay
              </p>
            )}
          <p className="remaining-guest-text">
            <PersonIconNew /> {invoiceData.guestsCount} guests
          </p>
          <p className="remaining-guest-text">
            <CalendarIconNew /> {new Date(invoiceData.dateTime).toDateString()}
          </p>
        </div>
      </div>
      {owner && ownerPaid && invoiceData.bill_status !== "paid" && (
        <div
          onClick={async () => {
            await flushSync(() => setAnotherPayment(true))
            settleRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }}
          className="margin-y btn-dark-default"
        >
          Make another payment
        </div>
      )}
      <div className="order-item-view">
        {shownItems.map((item, index) => {
          return (
            <Fragment key={index}>
              <div key={index}>
                <div className="item-inner-view">
                  <p>
                    <strong className="order-quantity-view">
                      {item.quantity}
                    </strong>{" "}
                    <span className="font-light-text">{item.name}</span>
                  </p>
                  <strong>£{item.price.toFixed(2)}</strong>
                </div>
              </div>
              <CuttedHorizontalLineNew />
            </Fragment>
          )
        })}
        {invoiceData.items.length > 3 && (
          <div
            onClick={() => setShowAll(!showAll)}
            className="item-expand-view"
          >
            <strong className="text-color-dark">
              {showAll
                ? "Hide items"
                : `Show full bill (+${unShownLength} items)`}
            </strong>
            {showAll ? (
              <RiArrowUpSLine size={26} className="text-color-dark" />
            ) : (
              <RiArrowDownSLine size={26} className="text-color-dark" />
            )}
          </div>
        )}
      </div>
    </>
  )

  const totalPrice =
    portionPaid || ownerPaid
      ? (
          (invoiceData.price / invoiceData.guestsCount) *
          (owner ? ownerPaid?.portion : portionPaid)
        ).toFixed(2)
      : Number(invoiceData.price).toFixed(2)

  const guestsPaidPrice =
    Math.ceil(
      (invoiceData.price / invoiceData.guestsCount) *
        (owner ? ownerPaid?.portion : portionPaid) *
        100,
    ) / 100

  const yourTotalTabContent = (
    <div className="width-max">
      <div className="order-bottom-view">
        <span className="text-color-dark">Subtotal</span>
        <span className="text-color-dark">
          {currencySymbol}
          {(+invoiceData.subtotal).toFixed(2)}
        </span>
      </div>
      <div className="flex-center-between">
        <span className="text-color-dark">VAT</span>
        <span className="text-color-dark">
          {currencySymbol}
          {Number(
            invoiceData.vatTotal === 0
              ? invoiceData.vat_calc
              : invoiceData.vatTotal,
          ).toFixed(2)}
        </span>
      </div>
      <div className="flex-center-between">
        <span className="text-color-dark">Service Charge (12.5%)</span>
        <span className="text-color-dark">
          {currencySymbol}
          {(+invoiceData.serviceAmount).toFixed(2)}
        </span>
      </div>
      {portionPaid || ownerPaid ? (
        <>
          <div className="flex-center-between">
            <span className="text-color-dark">Total</span>
            <span className="text-color-dark">
              {currencySymbol}
              {Number(invoiceData.price).toFixed(2)}
            </span>
          </div>
          <div className="flex-center-between">
            <span className="text-color-dark">
              Paying for {owner ? ownerPaid?.portion : portionPaid} guest
            </span>
            <span className="text-color-dark">
              {isNaN(guestsPaidPrice)
                ? "Loading..."
                : `${currencySymbol}${guestsPaidPrice.toFixed(2)}`}
            </span>
          </div>
        </>
      ) : null}
      <div className="total-text-view">
        <span className="total-text-style">Total</span>
        <span className="total-digit-style">
          {isNaN(totalPrice)
            ? "Loading..."
            : `${currencySymbol}${Number(invoiceData.price).toFixed(2)}`}
        </span>
      </div>
    </div>
  )

  const unPaidSettleTabContent = (
    <>
      <div className={isWalletConfirming ? "hidden" : ""}>
        {!statusPaid &&
          showPaymentBtn &&
          invoiceData.pay_method !== "split" && (
            <div className="btn-bottom-view">
              <button
                onClick={() => {
                  setPayFull(true)
                  setSplitBill(false)
                }}
                disabled={payInFullMutation.isLoading}
                className={`${splitBill ? "btn-light-border" : "btn-dark-default"}`}
              >
                Pay in full
              </button>
              <button
                onClick={() => {
                  setSplitBill(true)
                  setPayFull(false)
                }}
                className={`${!splitBill ? "btn-light-border" : "btn-dark-default"}`}
              >
                Split bill
              </button>
            </div>
          )}
        {(statusPaid || !showPaymentBtn) && (
          <div className="email-form-view">
            {invoiceData.bill_status === "paid" && (
              <>
                {/* email-form */}
                <div className="email-outer-view">
                  <div className="email-inner-view">
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      className="custom-form-control"
                      value={sendEmail}
                      onChange={(e) => {
                        setSendEmail(e.target.value)
                        validateOnChange(e.target.value)
                      }}
                    />
                    <span className="alert-text-view">
                      {billEmailNotValid ? "Email isn't valid" : ""}
                    </span>
                    <button
                      disabled={disableBillMail}
                      onClick={onBillMail}
                      className="btn-dark-default mb-5 mt-2"
                    >
                      {mailBillMutation.isLoading && (
                        <SpinnerIcon className="inline-block" />
                      )}{" "}
                      {!disableBillMail || !mailBillMutation.isSuccess ? (
                        <span>Send</span>
                      ) : (
                        <span className="receipt-sent-view">
                          <CheckWhiteIcon /> Receipt Sent
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {invoiceData.bill_status !== "paid" && (
          <>
            {!anotherPayment && (
              <div
                className={`pay-full-view ${payFull ? "pay-full-active" : ""}`}
              >
                {!splitBill && (
                  <>
                    {invoiceData?.price > 0 && (
                      <div className="padding-horizontal">
                        <StripeCheckoutForm
                          onConfirm={onConfirm}
                          amount={calculateAmountInPence(invoiceData)}
                        />
                      </div>
                    )}
                    <p className="or-text-style">OR</p>
                    {!!owner &&
                      !ownerPaid &&
                      ownerData?.paymentMethods &&
                      ownerData?.paymentMethods?.map((item, index) => {
                        if (item.isDefault) {
                          return (
                            <Fragment key={index}>
                              <div key={index} className="width-max">
                                <ChangePaymentMethodInfo
                                  bookId={invoiceData.NewBookingID}
                                  isOpen={isChange}
                                />
                                <div className="card-view">
                                  <div className="card-inner-view">
                                    <span>
                                      <CardIcon brand={item.card.brand} />
                                    </span>
                                    <span className="ending-with-text">
                                      {item.card.brand} ending with{" "}
                                      {item.card.last4}
                                    </span>
                                  </div>
                                  {!statusPaid && (
                                    <div
                                      className="change-outer-div"
                                      onClick={() => {
                                        setIsChange(false)
                                        setTimeout(() => setIsChange(true))
                                      }}
                                    >
                                      <div className="change-inner-div">
                                        Change
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )
                        } else return null
                      })}
                    {payInFullMutation.isLoading ? (
                      <CompletePaymentLoadingNew />
                    ) : (
                      owner &&
                      !ownerPaid &&
                      ownerData?.paymentMethods && (
                        <SwipeButtonDark
                          onSwipe={() =>
                            payInFullMutation.mutate({
                              billID: invoiceID,
                              isOwner: owner,
                            })
                          }
                        />
                      )
                    )}
                    {(!ownerData?.paymentMethods || !guestID) && (
                      <PaymentFormInfo
                        state={{
                          payMethod: "full",
                        }}
                        setIsWalletConfirming={setIsWalletConfirming}
                      />
                    )}
                  </>
                )}
              </div>
            )}
            <div
              className={`split-bill ${splitBill ? "split-bill-active" : ""}`}
            >
              <SplitBillInfo
                setSomeoneElse={setSomeoneElse}
                anotherPayment={anotherPayment}
                setAnotherPayment={setAnotherPayment}
                setIsWalletConfirming={setIsWalletConfirming}
              />
            </div>
          </>
        )}
      </div>
      {isWalletConfirming && (
        <div className="processing-div">
          <CustomLoader />
          <p className="loader-fetching-text">Processing...</p>
        </div>
      )}
    </>
  )

  const billDetails = [
    { label: "Server", value: invoiceData.server },
    {
      label: "Date / time",
      value: `${new Date(invoiceData.dateTime).toLocaleDateString()} / ${new Date(invoiceData.dateTime).toLocaleTimeString()}`,
    },
    { label: "Table No.", value: invoiceData.table },
    { label: "Reservation No.", value: `#${invoiceData.reservationCode}` },
    { label: "Bill No.", value: `#${invoiceData.receiptNum}` },
  ]

  const socialLinksData = [
    {
      platform: "x",
      key: "x",
      icon: <FaXTwitter size={20} />,
    },
    {
      platform: "facebook",
      key: "facebook",
      icon: <FaFacebookF size={20} />,
    },
    {
      platform: "instagram",
      key: "instagram",
      icon: <FaInstagram size={20} />,
    },
  ]

  const socialLinks = socialLinksData
    .filter((link) => restaurantInfo?.social?.[link.key])
    .map((link) => ({
      platform: link.platform,
      url: restaurantInfo.social[link.key],
      icon: link.icon,
    }))

  const paidSection = (
    <div className="paid-section">
      <p className="total-text-style">
        {(portionPaid || ownerPaid) && invoiceData.bill_status !== "paid"
          ? "Portion paid"
          : "Bill paid"}
      </p>
      <button className="btn-green-default my-5">
        You've paid <CheckWhiteIcon className="ms-3" />
      </button>
      <ProblemWithBill />
    </div>
  )
  const tabs = [
    // {
    //   title: `Bill #${invoiceData.reservationCode}`,
    //   component: yourBillTabContent,
    // },
    {
      title: "Your total",
      component: yourTotalTabContent,
    },
    {
      title:
        invoiceData.bill_status === "paid"
          ? "Email your bill"
          : "Settle your bill",
      component: unPaidSettleTabContent,
    },
  ]
  if (isLoading) return null
  if (isError)
    return (
      <div className="reservation-not-found">
        <h1 className="reservation-not-found-text">Reservation Not Found</h1>
      </div>
    )
  return (
    <>
      <div className="bill-section-paid">
        <div className="flex-1 w-full">
          <div>{yourBillTabContent}</div>
          {tabs.map((item, index) => {
            return (
              <Fragment key={index}>
                <div
                  key={index}
                  className="bill-detail-view"
                  hidden={
                    !!(
                      index === 2 &&
                      (portionPaid || ownerPaid) &&
                      invoiceData.bill_status !== "paid"
                    ) && !anotherPayment
                  }
                  ref={index === 2 ? settleRef : null}
                >
                  <Accordion
                    allowZeroExpanded
                    preExpanded={expandedSectionTab}
                    onChange={handleAccordionChangeTab}
                    className="custom-accordion"
                  >
                    <AccordionItem uuid={index}>
                      <AccordionItemButton>
                        <div className="item-paid-view">
                          {invoiceData.bill_status === "paid" &&
                            index === 0 && <CheckGreenFillIcon />}
                          <h6
                            className="title24px"
                            // className={`${index == 0 ? "title32px" : "title24px"}`}
                          >
                            {item.title}
                          </h6>
                        </div>
                      </AccordionItemButton>
                      <AccordionItemPanel>{item.component}</AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                {(portionPaid ||
                  ownerPaid ||
                  invoiceData.bill_status === "paid") &&
                index === 1
                  ? paidSection
                  : null}
              </Fragment>
            )
          })}
          {invoiceData.bill_status !== "paid" && (
            <div className="some-one-text">
              <button onClick={() => setSomeoneElse(!someoneElse)}>
                Someone else wants to pay the bill
              </button>
            </div>
          )}
          {invoiceData.bill_status !== "paid" && someoneElse && (
            <div className="share-bill-div">
              <p className="share-bill-text">
                Share the bill to the remaining guests
              </p>
              <p className="share-bill-desc">
                Have your guests scan the QR code below to settle their share of
                the bill.
              </p>
              <div className="qr-code-view">
                <img
                  src={invoiceData.billQRCode}
                  className="h-48"
                  alt="qr-code"
                />
              </div>
              <p onClick={() => onCopy()} className="underline-style">
                Having trouble scanning? Share the link instead
              </p>
            </div>
          )}
        </div>
        <Accordion
          allowZeroExpanded
          preExpanded={expandedFooter}
          onChange={handleAccordionFooter}
          className="custom-accordion showBillInfo"
        >
          <AccordionItem>
            <AccordionItemButton>
              <div className="dotted-seperated-line">
                <p className="text-grey-color-shade">Powered by Zumi</p>
                <p className="hide-show-text">
                  {expandedFooter.length !== 0
                    ? "Hide bill information"
                    : "Show bill information"}
                </p>
              </div>
            </AccordionItemButton>
            <AccordionItemPanel>
              <div className="pt-2">
                {billDetails.map((detail, index) => (
                  <div key={index} className="bill-section-detail">
                    <span>{detail.label}</span>
                    <span className="semibold-text">{detail.value}</span>
                  </div>
                ))}
                <div className="social-view">
                  {socialLinks.map(
                    (link, index) =>
                      link.url && (
                        <a
                          key={index}
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-icon-view"
                        >
                          {link.icon}
                        </a>
                      ),
                  )}
                  <h5>{restaurantInfo?.social?.socialId}</h5>
                </div>
                <p className="res-location-text">
                  {invoiceData.restaurantLocation}
                </p>
                <p className="email-text">{invoiceData.restaurantEmail}</p>
                <p className="website-text">{restaurantInfo.website}</p>
                <p className="vat-no-text">
                  Includes VAT at current rate <br /> VAT No. -{" "}
                  {restaurantInfo.vat_number} • Inv No. -{" "}
                  {invoiceData.reservationCode}
                </p>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      {showConfirmComponent && (
        <ConfirmPayment
          data={ownerData}
          price={invoiceData.price}
          onPay={() => {
            setShowConfirmComponent(false)
            payInFullMutation.mutate({ billID: invoiceID, isOwner: owner })
          }}
          onBack={() => setShowConfirmComponent(false)}
        />
      )}
      {showModal && isLoadingModal && paymentIntent && (
        <div className="processing-div">
          <CustomLoader />
          <p className="loader-fetching-text">Processing...</p>
        </div>
      )}
      {!showModal && !paymentIntent && (
        <div className="payment-intent-view">
          <iframe
            className="h-w-full"
            title="stripeconfirm"
            data-hj-allow-iframe="true"
            src={paymentIntent.next_action.redirect_to_url.url}
          ></iframe>
        </div>
      )}
    </>
  )
}

const PayingBillInfo = () => {
  return (
    <Elements stripe={stripePromise}>
      <PayingBillComponentInfo />
    </Elements>
  )
}

export default PayingBillInfo
