export const calculateUnpaid = (data, index) => {
  const splitters = data.splitters
  const totalPrice = data.price
  const portionPrice = totalPrice / data.guestsCount

  let unpaidAmount = totalPrice
  for (let i = 0; i <= index; i++) {
    const splitter = splitters[i]
    const portion = splitter.portion
    const portionPriceForSplitter = portion * portionPrice
    unpaidAmount -= portionPriceForSplitter
  }

  return unpaidAmount.toFixed(2)
}

export const calcAmountInPence = (invoiceData, payingCount, guestsCount) => {
  if (guestsCount === invoiceData.pay_portions + payingCount) {
    //get the min between remaining amount and the amount to be paid
    return Math.min(
      Math.round((invoiceData.price - invoiceData.pay_total) * 100),
      Math.round((invoiceData.price / guestsCount) * payingCount * 100),
    )
  } else {
    const priceInPence = Math.floor(invoiceData.price * 100)
    const portionPriceInPence = Math.ceil(priceInPence / guestsCount)
    return portionPriceInPence * payingCount
  }
}

export const calcFullAmountInPence = (invoiceData) => {
  return Math.min(
    Math.round((invoiceData.price - invoiceData.pay_total) * 100),
    Math.round(invoiceData.price * 100),
  )
}

export const calculateAmountInPence = (invoiceData) => {
  if (
    !invoiceData ||
    typeof invoiceData.price !== "number" ||
    typeof invoiceData.pay_total !== "number"
  ) {
    return 0
  }
  const amountToPay = Math.round(
    (invoiceData.price - invoiceData.pay_total) * 100,
  )
  const totalPriceInPence = Math.round(invoiceData.price * 100)
  return Math.min(amountToPay, totalPriceInPence)
}

export const getSubtenantId = (subtenantId) => {
  const specialPaths = [
    "reservations",
    "paidreservation",
    "checks",
    "reports",
    "users",
    "setting",
    "floorplan",
    "stats",
  ]
  if (subtenantId) {
    const firstPart = subtenantId.split("/")[0]
    if (specialPaths.includes(firstPart)) {
      return null
    } else {
      return firstPart
    }
  }
  return subtenantId
}
