import cn from "classnames"
import React from "react"
const HorizontalLine = ({ forcedTheme, className, newDesign }) => {
  if (newDesign) {
    return (
      <div
        className={`${className} ${cn("border-t border-grey-color-shade")}`}
      />
    )
  }
  return (
    <div
      className={`${className} ${cn(
        "h-[0px] border-t-[1px] dark:border-[#525C81] border-zinc-300 px-[5px] w-[calc(100vw-6px)] lg:w-[calc(50vw-50px)]",
        {
          "!border-[#525C81]": forcedTheme === "dark",
        },
        {
          "!border-zinc-300": forcedTheme === "light",
        },
      )}`}
    />
  )
}

export default HorizontalLine
