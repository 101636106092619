import DetermineHostname from "../../configs/DetermineHostname"

const fetchBrandDetails = async (tenantId, subtenantId) => {
  let url = `${DetermineHostname().hostV2}brand/?tenantId=${tenantId}`
  if (subtenantId) {
    url += `&subtenantId=${subtenantId}`
  }
  const response = await fetch(url)
  const data = await response.json()
  if (response.ok) {
    return data
  } else {
    throw new Error(data?.message)
  }
}

export { fetchBrandDetails }
