import React, { useMemo } from "react"
import moment from "moment-timezone"
import { useParams } from "react-router"
import DataRangeTable from "../components/DataRangeTable"
import DetermineHostname from "../../configs/DetermineHostname"
import StatusComponent from "../components/StatusComponent"
import { getSubtenantId } from "../../utils/helperFunctions"

moment.tz.setDefault("Europe/London")

const PaidReservations = () => {
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params["*"])
  const statsHeaders = useMemo(
    () => [
      [
        { id: 1, name: "First Name", key: "firstName", sortable: true },
        { id: 7, name: "Last Name", key: "lastName", sortable: true },
        { id: 2, name: "Customer Email", key: "guestEmail", sortable: true },
        { id: 15, name: "Table Number", key: "tables", sortable: true },
        { id: 24, name: "receiptNum", key: "receiptNum", sortable: true },
        { id: 3, name: "Reservation Date", key: "date", sortable: true },
        {
          id: 9,
          name: "Reservation Code",
          key: "reservationCode",
          sortable: true,
        },
        { id: 4, name: "Time", key: "time", sortable: true },
        { id: 12, name: "total", key: "price", sortable: true },
        {
          id: 5,
          name: "Status",
          key: "status",
          sortable: true,
          renderContent(row, col) {
            return <StatusComponent status={row["status"]} />
          },
        },
        {
          id: 7,
          name: "Bill",
          key: "bill_status",
          sortable: true,
          renderContent(row, col) {
            return <StatusComponent status={row["bill_status"]} />
          },
        },
        {
          id: 12,
          name: "Refunded",
          key: "refund_status",
          sortable: true,
          renderContent(row, _col) {
            return row.refund_status ?? "No"
          },
        },
        {
          id: 12,
          name: "Refunded Amount",
          key: "refundedAmount",
          sortable: true,
          renderContent(row, _col) {
            return row.refundedAmount
              ? (row.refundedAmount / 100).toFixed(2)
              : "0"
          },
        },
      ],
    ],
    [],
  )

  const fetchReservations = async ({ startDate, endDate }) => {
    let url = `${DetermineHostname().hostV2}reservationV2/all?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&date=${startDate}&endDate=${endDate}&billStatus=paid`
    const response = await fetch(url)
    return response.json()
  }

  const fetchStats = async ({ startDate, endDate }) => {
    try {
      const { data } = await fetchReservations({ startDate, endDate })
      data.sort((a, b) => -1 * moment(b.dateTime).diff(moment(a.dateTime)))
      return [data]
    } catch (e) {
      throw new Error(e.message)
    }
  }

  return (
    <div className={"flex items-center flex-col"}>
      <DataRangeTable
        onSearch={fetchStats}
        headers={statsHeaders}
        title={"Paid Reservations"}
        navRoot={"reservations"}
        paginate={true}
      />
    </div>
  )
}

export default PaidReservations
