import { Button, TextField } from "@mui/material"
import React, { useState } from "react"

function EditableData({ onSubmit, data, title }) {
  const [toggleField, setToggleField] = useState(false)
  const [fieldValue, setFieldValue] = useState()
  const handleDateEdit = () => {
    if (toggleField) {
      onSubmit(fieldValue)
      setToggleField(false)
    } else {
      setToggleField(true)
    }
  }
  return (
    <div className="flex gap-2 items-center">
      <span className="font-semibold">{title}</span>
      {toggleField ? (
        <TextField
          variant="standard"
          size="small"
          onChange={({ target }) => setFieldValue(target.value)}
        />
      ) : (
        <span className="font-semibold">{data}</span>
      )}
      <Button className="h-[20px]" onClick={handleDateEdit}>
        {toggleField ? "Submit" : "Edit"}
      </Button>
      {toggleField && (
        <Button
          className="h-[20px]"
          onClick={() => setToggleField(false)}
          color="error"
        >
          Cancel
        </Button>
      )}
    </div>
  )
}

export default EditableData
