import { CircularProgress } from "@mui/material"
import React from "react"

const CompletePaymentLoading = () => {
  return (
    <button
      style={{ border: "1px solid #EBFFA8" }}
      className="w-full my-5 bg-transperant text-[#EBFFA8] p-3 rounded-3xl font-semibold"
    >
      <CircularProgress
        sx={{ color: "#EBFFA8", zoom: "30%" }}
        className="me-4"
      />{" "}
      Completing payment
    </button>
  )
}

export default CompletePaymentLoading
