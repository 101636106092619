import React from "react"
import { Link } from "react-router-dom"

function Rewards() {
  return (
    <div className="h-screen text-white p-4">
      <h1 className="text-4xl mb-4">Rewards</h1>
      <ul className="space-y-4">
        <li>
          <h2 className="text-2xl">Reward 1</h2>
          <p>Details about reward 1.</p>
        </li>
        <li>
          <h2 className="text-2xl">Reward 2</h2>
          <p>Details about reward 2.</p>
        </li>
        {/* Add more rewards as needed */}
      </ul>
      <Link to="/" className="text-blue-300 mt-8">
        Back to Home
      </Link>
    </div>
  )
}

export default Rewards
