import React from "react"

const CalendarIconNew = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.91675 7.1582H17.0834"
        stroke="#2A2B49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 6.55405V13.4459C17.5 15.8784 16.25 17.5 13.3333 17.5H6.66667C3.75 17.5 2.5 15.8784 2.5 13.4459V6.55405C2.5 4.12162 3.75 2.5 6.66667 2.5H13.3333C16.25 2.5 17.5 4.12162 17.5 6.55405Z"
        stroke="#2A2B49"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99632 11.0002H10.0038"
        stroke="#2A2B49"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.91185 11.0002H6.91933"
        stroke="#2A2B49"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.91185 13.5002H6.91933"
        stroke="#2A2B49"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CalendarIconNew
