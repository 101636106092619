import React, { useState } from "react"
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar"
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock"

import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { useNavigate, useOutletContext, useParams } from "react-router"
import HorizontalLine from "../../components/custom/HorizontalLine"
import classNames from "classnames"
import styled from "./Calender.module.css"
import moment from "moment-timezone"
moment.tz.setDefault("Europe/London")

const Calender = () => {
  const [guestCount, setGuestCount] = useState(2)
  const [showCalender, setshowCalender] = useState(false)
  const [showClock, setshowClock] = useState(false)
  const [selectedTime, setselectedTime] = useState(null)
  const today = moment()
  let initialDate = moment()
  if (today.day() === 1) {
    initialDate = moment().add(2, "days")
  }
  if (today.day() === 2) {
    initialDate = moment().add(1, "days")
  }
  const [selectedDate, setselectedDate] = useState(initialDate)
  const nav = useNavigate()
  const { restaurant } = useParams()
  const { guestsNumber } = useOutletContext()

  const isToday = moment(selectedDate)
    .startOf("day")
    .isSame(moment().startOf("day"))

  const onIncrease = () => {
    if (guestCount + 1 <= guestsNumber) setGuestCount(guestCount + 1)
  }
  const onDecrease = () => {
    if (guestCount > 1) setGuestCount(guestCount - 1)
  }
  const toggleCalenderView = () => setshowCalender(!showCalender)
  const toggleClockView = () => setshowClock(!showClock)

  const onDatePick = (newDate) => {
    setselectedDate(newDate)
    setshowCalender(false)
  }

  const onTimeSelect = (newTime) => {
    setselectedTime(newTime)
    setshowClock(false)
  }

  const maxDate = moment()
  maxDate.month(maxDate.month() + 2)

  const shouldDisableDate = (date) => {
    return date.day() === 1 || date.day() === 2 // Disable Mondays and Thursdays
  }

  const search = () => {
    const selectedDateTime = moment(selectedDate)
    if (selectedTime) {
      selectedDateTime.hour(selectedTime.hour())
      selectedDateTime.minute(selectedTime.minute())
    } else {
      selectedDateTime.hour(12)
      selectedDateTime.minute(30)
    }

    nav(`/booking/${restaurant}/availability`, {
      state: {
        selectedDateTime: selectedDateTime.format(),
        guestCount,
      },
    })
  }

  const getMinTime = () => {
    if (
      (selectedDate.day() >= 3 && selectedDate.day() <= 6) ||
      selectedDate.day() == 0
    ) {
      // Wednesday to Saturday
      return moment({ hour: 12, minute: 0 })
    } else {
      return null // Closed for Monday and Tuesday
    }
  }

  const getMaxTime = () => {
    if (selectedDate.day() >= 3 && selectedDate.day() <= 6) {
      // Wednesday to Saturday
      return moment({ hour: 21, minute: 30 })
    } else if (selectedDate.day() === 0) {
      // Sunday
      return moment({ hour: 21, minute: 0 })
    } else {
      return null // Closed for Monday and Tuesday
    }
  }

  return (
    <div className="flex flex-col mx-[30px]  gap-y-7 items-center h-[80%] pt-5">
      <div className="flex w-full justify-between mx-[30px]">
        <div className="w-[167px] h-[37px] text-black text-[22px] font-semibold leading-normal">
          Guests
        </div>
        <div className="flex items-center gap-x-5 font-bold text-xl">
          <p
            onClick={onDecrease}
            className="cursor-pointer w-[25px] text-left select-none"
          >
            -
          </p>
          <p>{guestCount}</p>
          <p
            onClick={onIncrease}
            className="cursor-pointer w-[25px] text-right select-none"
          >
            +
          </p>
        </div>
      </div>

      <HorizontalLine />
      <div className="flex w-full justify-between mx-[30px]">
        <div className="w-[167px] h-[37px] text-black text-[22px] font-semibold leading-normal">
          Date
        </div>
        <div
          className="flex items-center gap-x-5 font-bold text-xl"
          onClick={toggleCalenderView}
        >
          {isToday ? "Today" : moment(selectedDate).format("ddd, MMM D")}
        </div>
      </div>
      {showCalender && (
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
        >
          <DateCalendar
            sx={{ width: "100%", flexShrink: 0 }}
            onChange={onDatePick}
            disablePast
            maxDate={maxDate}
            shouldDisableDate={shouldDisableDate}
          />
        </LocalizationProvider>
      )}

      <HorizontalLine />
      <div className="flex w-full justify-between mx-[30px]">
        <div className="w-[167px] h-[37px] text-black text-[22px] font-semibold leading-normal">
          Time
        </div>
        <div
          className="flex items-center gap-x-5 font-bold text-xl"
          onClick={toggleClockView}
        >
          {selectedTime ? selectedTime.format("HH:mm") : "All Day"}
        </div>
      </div>

      {showClock && (
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          dateLibInstance={moment}
        >
          <DigitalClock
            ampm={false}
            skipDisabled
            minTime={getMinTime()}
            maxTime={getMaxTime()}
            className={classNames(styled.digitalClock)}
            onChange={onTimeSelect}
          />
        </LocalizationProvider>
      )}
      <div className="bottom-[38px] w-full h-[50px] justify-center items-center inline-flex mt-auto">
        <button
          onClick={search}
          className="grow shrink basis-0 h-12 px-8 py-3 bg-slate-900 rounded-3xl justify-center items-center gap-2 flex"
        >
          <div className="text-center text-amber-50 text-[22px] font-semibold leading-normal">
            Search
          </div>
        </button>
      </div>
      <div className={styled.BookMore}>
        To book for more than 6 people <br></br>contact us directly.
        <div className={styled.email}>
          <a href="020 7722 2800" className={styled.mail}>
            T: 020 7722 2800
          </a>
          <br />
          <a
            href="mailto:primrose@restaurant-michaelnadra.co.u"
            className={styled.mail}
          >
            E: primrose@restaurant-michaelnadra.co.u
          </a>
        </div>
      </div>
    </div>
  )
}

export default Calender
