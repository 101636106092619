import React, { useEffect, useState } from "react"
import {
  AmExpressIcon,
  DiscoverCardIcon,
  MasterCardIcon,
  SpinnerIcon,
  VisaIcon,
} from "../../components/custom/Icons"
import HorizontalLine from "../../components/custom/HorizontalLine"
import { Collapse, Typography } from "@mui/material"
import { Check } from "@mui/icons-material"
import {
  addNewReservationPayment,
  deleteCard,
  fetchReservationOwnerPayments,
  fetchRestaurantInfoByReservationID,
  getClientSecret,
  setHolderDefaultPayment,
} from "../../api/reservations"
import { useMutation, useQuery } from "@tanstack/react-query"
import cn from "classnames"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { IoIosCloseCircleOutline } from "react-icons/io"
import { toast } from "react-toastify"
import MuiFontTheme from "../../components/custom/MuiTheme"

export const CardIcon = ({ brand }) => {
  switch (brand) {
    case "visa":
      return <VisaIcon />
    case "amex":
      return <AmExpressIcon />
    case "discover":
      return <DiscoverCardIcon />
    case "mastercard":
      return <MasterCardIcon />

    default:
      return null
  }
}

const ModifyPaymentMain = ({ bookId }) => {
  const [modifyId, setModifyId] = useState(0)
  const [isModifying, setIsModifying] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [deletingId, setDeletingId] = useState({
    id: 0,
    isDefault: false,
  })
  const bookingID = bookId
  const stripe = useStripe()
  const elements = useElements()
  const deleteBody = {
    paymentID: deletingId.id,
    isDefault: deletingId.isDefault,
  }

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [bookingID, "Payments"],
    queryFn: () => fetchReservationOwnerPayments(bookingID),
    retry: false,
  })

  const {
    data: restaurantInfo,
    isLoadingRestaurant,
    isErrorRestaurant,
  } = useQuery({
    queryKey: [bookingID, "restaurantInfo"],
    queryFn: () => fetchRestaurantInfoByReservationID(bookingID),
    retry: false,
    enabled: !!bookingID,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const clientSecretMutation = useMutation({
    mutationFn: getClientSecret,
  })

  const addPaymentMutation = useMutation({
    mutationFn: addNewReservationPayment,
    onSuccess: () => refetch(),
  })

  const deleteCardMutation = useMutation({
    mutationFn: deleteCard,
    onSuccess: () => refetch(),
  })

  const changeDefaultCard = useMutation({
    mutationFn: setHolderDefaultPayment,
    onSuccess: () => {
      refetch()
      toast.success("Card Changed Successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })

  const onAddPayment = async () => {
    if (!stripe || !elements) {
      return null
    }

    const { error: submitError } = await elements.submit()
    if (submitError) {
      toast.error(submitError.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      return
    }

    const { client_secret } = await clientSecretMutation.mutateAsync({
      customerID: data.paymentMethods[0].customer,
    })

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: "if_required",
    })
    if (error) {
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      return
    }

    addPaymentMutation.mutate({
      bookingID,
      paymentID: setupIntent.payment_method,
    })
  }

  const onSetDefault = async (paymentID) => {
    changeDefaultCard.mutate({ bookingID, paymentID })
  }

  useEffect(() => {
    if (modifyId !== 0) {
      setIsModifying(true)
    }
  }, [modifyId])

  useEffect(() => {
    if (elements) {
      elements.update({
        appearance: {
          variables: {
            colorTextSecondary: "white",
          },
          rules: {
            ".Label": {
              color: "white",
            },
          },
        },
      })
    }
  }, [elements])

  if (isLoading || isLoadingRestaurant) return null

  if (isError || isErrorRestaurant)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center ">
        <h1 className="text-amber-50 text-[40px] font-extrabold leading-[60px]">
          Reservation Not Found
        </h1>
      </div>
    )

  return (
    <MuiFontTheme font={restaurantInfo?.main_font ?? ""}>
      <div>
        <>
          <div
            className={`w-full lg:max-w-[50%] relative flex flex-col items-center py-[18px] px-[24px] lg:px-[70px] lg:py-[45px] bg-[#212D43]`}
            style={{ fontFamily: restaurantInfo?.main_font ?? "" }}
          >
            <div className="w-full ">
              <div className="w-full h-[33px] text-[#9AAAFF] text-[22px] font-semibold leading-normal text-center mb-5">
                Select a payment method
              </div>
            </div>
            <div className=" flex flex-col gap-y-3 w-[calc(100%+24px)] lg:w-[calc(100%+70px)] ">
              {data.paymentMethods.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={cn(
                      {
                        "order-first": item.isDefault,
                      },
                      "w-full",
                    )}
                  >
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        {item.isDefault && (
                          <Check className="default-card-check" />
                        )}
                        <div
                          onClick={() => {
                            if (!item.isDefault) {
                              setModifyId(item.id)
                            }
                          }}
                          className="gap-x-2 mb-4"
                        >
                          <span className="text-amber-50 text-[14px] font-normal leading-none">
                            {item.card.brand} ending in {item.card.last4}{" "}
                          </span>
                          <br />
                          <span className="text-slate-400 text-xs font-normal">
                            Exp {item.card.exp_month}/{item.card.exp_year}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-4 -mt-1">
                        {deletingId === item.id ? (
                          <button
                            onClick={() => deleteCardMutation(deleteBody)}
                            className="text-[#EC9C9C] text-[14px] bg-[#EC9C9C4D] py-1 px-2 rounded-full flex items-center"
                          >
                            <IoIosCloseCircleOutline
                              onClick={() =>
                                setDeletingId({
                                  id: item.id,
                                  isDefault: item.isDefault,
                                })
                              }
                              color="#EC9C9C"
                              size={20}
                              className="me-1"
                            />
                            Confirm
                          </button>
                        ) : (
                          <>
                            <span>
                              <CardIcon brand={item.card.brand} />
                            </span>
                            <span>
                              <IoIosCloseCircleOutline
                                onClick={() => setDeletingId(item.id)}
                                color="#EC9C9C"
                                size={30}
                              />
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="  flex justify-center">
                      <HorizontalLine />
                    </div>
                  </div>
                )
              })}
            </div>
            <Typography
              marginTop={3}
              onClick={() => setIsAdd(!isAdd)}
              className="text-amber-50 text-[22px] font-[600] leading-[110%] fo mt-4 text-center underline"
            >
              Add new payment method
            </Typography>
            <Collapse in={isAdd} className="mt-3 w-full">
              <div className="w-full">
                <div className="w-full flex flex-col items-center">
                  <div className="w-full flex flex-col gap-[10px] payment-form">
                    <PaymentElement />
                  </div>
                </div>
              </div>
              <button
                onClick={onAddPayment}
                className="w-full h-[50px] justify-center items-center inline-flex mb-15 lg:mt-[48px]"
                disabled={addPaymentMutation.isLoading}
              >
                <div className="grow shrink basis-0 h-12 px-8 py-3 bg-lime-50 rounded-3xl justify-center items-center gap-2 flex mt-4">
                  <div className="text-center text-slate-900 text-lg font-semibold leading-tight">
                    <div className="flex gap-2 items-center dark">
                      {addPaymentMutation.isLoading && <SpinnerIcon />}
                      <span> Save payment method</span>
                    </div>
                  </div>
                </div>
              </button>
            </Collapse>
            {isModifying && (
              <div className="flex justify-between w-full my-5">
                <button
                  onClick={() => {
                    setModifyId(0)
                    setIsModifying(false)
                  }}
                  className="w-[45%] text-[#fff] border-2 border-[#fff] border-solid rounded-full font-semibold"
                >
                  Cancel
                </button>
                <button
                  onClick={() => onSetDefault(modifyId)}
                  className="w-[45%] text-[#29304E] bg-[#9AAAFF] py-3 rounded-full font-semibold"
                >
                  Confirm
                </button>
              </div>
            )}
          </div>
        </>
      </div>
    </MuiFontTheme>
  )
}

loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)
const ModifyPayment = ({ bookId }) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{ mode: "setup", currency: "gbp" }}
    >
      <ModifyPaymentMain bookId={bookId} />
    </Elements>
  )
}

export default ModifyPayment
