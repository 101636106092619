import { React } from "react"
import Card from "@mui/material/Card"
import LargeTableComponet from "../../components/largeTableComponent"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { useParams } from "react-router"
import { editReservationV2 } from "../../../api/v2/reservations"

function GuestDetails({ data, refetchReservation }) {
  const params = useParams()
  const reservationId = params.id
  const reservationEditMutation = useMutation({
    mutationFn: editReservationV2,
    onSuccess: refetchReservation,
    onError: (err) => {
      toast.error(err.message || "update failed", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })
  const handleGuestEdit = (data) => {
    reservationEditMutation.mutate({
      data: { ...data },
      reservationID: reservationId,
    })
  }
  const guestsHeaders = [
    { id: 1, name: "First Name", key: "firstName", sortable: true },
    { id: 2, name: "Last Name", key: "lastName", sortable: true },
    { id: 3, name: "Customer Email", key: "guestEmail", sortable: true },
    { id: 4, name: "Phone number", key: "guestPhone", sortable: true },
  ]

  return (
    <>
      {" "}
      <Card sx={{ flexDirection: "row", p: 2, m: 2 }}>
        <span>
          <LargeTableComponet
            edit={true}
            hasSearch={false}
            hasSortFilter={false}
            hasDateFilter={false}
            headers={guestsHeaders}
            data={data}
            title="Guest details  "
            onSubmit={handleGuestEdit}
            //  extraClassName='h-[390px]'
          />
        </span>
      </Card>
    </>
  )
}

export default GuestDetails
