import {
  useParams,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom"
import cn from "classnames"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography,
} from "@mui/material"
import { ExpandMoreOutlined } from "@mui/icons-material"
import HorizontalLine from "../../components/custom/HorizontalLine"
import React, { useEffect } from "react"
import { fetchAvailableTimes } from "../../api/reservations"
import { useQueries } from "@tanstack/react-query"
import moment from "moment"

const SelectedDateAvailableTimes = ({
  selectedDateTime,
  availabileTimes,
  next,
  slotDateTime,
}) => {
  return (
    <div className="flex flex-col gap-y-[34px] my-[24px] w-full">
      <div className="w-full text-black text-[22px] font-semibold leading-normal">
        {moment(slotDateTime).format("ddd, MMM D")}
        <div className="grid grid-cols-2 gap-y-[14px] gap-x-[28px] my-[16px]">
          {availabileTimes.length > 0 ? (
            availabileTimes.map((t, index) => {
              const isSelectedTime = moment(selectedDateTime).isSame(moment(t))
              return (
                <div
                  onClick={() => next(t)}
                  key={index}
                  className={cn(
                    "h-[60px] bg-gray-200 rounded-lg flex justify-center items-center cursor-pointer",
                    {
                      "!bg-slate-900": isSelectedTime,
                    },
                  )}
                >
                  <div
                    className={cn(
                      "w-[124px] h-9 text-center text-slate-900 text-[22px] font-semibold leading-normal",
                      { "!text-white": isSelectedTime },
                    )}
                  >
                    {moment(t).format("HH:mm")}
                  </div>
                </div>
              )
            })
          ) : (
            <>
              <p className="text-lg mt-[10px]">
                No Reservations available for today
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const ModifyAvailability = () => {
  const { reservationData } = useOutletContext()
  const { restaurant, bookingID } = useParams()
  const nav = useNavigate()
  const { state } = useLocation()

  const { selectedDateTime } = state ?? {
    selectedDateTime: moment(),
  }

  const firstDateTime = moment(selectedDateTime).format()
  const secondDateTime = moment(selectedDateTime).add(1, "days").format()
  const thirdDateTime = moment(selectedDateTime).add(2, "days").format()

  const [firstDateQuery, secondDateQuery, thirdDateQuery] = useQueries({
    queries: [
      {
        queryKey: [
          "availabileTimes",
          restaurant,
          firstDateTime,
          reservationData.guestsCount,
        ],
        queryFn: () =>
          fetchAvailableTimes(
            restaurant,
            firstDateTime,
            reservationData.guestsCount,
          ),
      },
      {
        queryKey: [
          "availabileTimes",
          restaurant,
          secondDateTime,
          reservationData.guestsCount,
        ],
        queryFn: () =>
          fetchAvailableTimes(
            restaurant,
            secondDateTime,
            reservationData.guestsCount,
          ),
      },
      {
        queryKey: [
          "availabileTimes",
          restaurant,
          thirdDateTime,
          reservationData.guestsCount,
        ],
        queryFn: () =>
          fetchAvailableTimes(
            restaurant,
            thirdDateTime,
            reservationData.guestsCount,
          ),
      },
    ],
  })

  const goToInformation = (time) => {
    nav(`/modify-reservation/${restaurant}/${bookingID}`, {
      state: {
        selectedDateTime: moment(time).format(),
      },
    })
  }

  useEffect(() => {
    if (!state) {
      nav("/booking/" + restaurant)
    }
  }, [state, nav, restaurant])

  if (!state) return null

  return (
    <div className="mt-[50px]">
      <div className="flex flex-col mx-[30px]  items-center">
        {firstDateQuery.data && (
          <SelectedDateAvailableTimes
            selectedDateTime={selectedDateTime}
            slotDateTime={firstDateTime}
            availabileTimes={firstDateQuery.data.available_times}
            next={goToInformation}
          />
        )}
        {firstDateQuery.isLoading && (
          <Skeleton width="100%" height={"400px"} animation="wave" />
        )}
        <HorizontalLine />
        <div className="w-full ">
          <Accordion
            disableGutters
            sx={{
              boxShadow: "none",
              "&:before": {
                backgroundColor: "transparent !important",
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="text-black text-[22px] font-[600] leading-[110%] fo">
                View additional dates
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {secondDateQuery.data && (
                <SelectedDateAvailableTimes
                  selectedDateTime={selectedDateTime}
                  slotDateTime={secondDateTime}
                  availabileTimes={secondDateQuery.data.available_times}
                  next={goToInformation}
                />
              )}
              {secondDateQuery.isLoading && (
                <Skeleton
                  width="100%"
                  height={"400px"}
                  animation="wave"
                  className="relative -top-[90px]"
                />
              )}
              {thirdDateQuery.data && (
                <SelectedDateAvailableTimes
                  selectedDateTime={selectedDateTime}
                  slotDateTime={thirdDateTime}
                  availabileTimes={thirdDateQuery.data.available_times}
                  next={goToInformation}
                />
              )}
              {thirdDateQuery.isLoading && (
                <Skeleton
                  width="100%"
                  height={"400px"}
                  animation="wave"
                  className="relative -top-[220px]"
                />
              )}
            </AccordionDetails>
          </Accordion>
        </div>
        <HorizontalLine />
      </div>
    </div>
  )
}

export default ModifyAvailability
