import React, { useEffect } from "react"
import { CircularProgress } from "@mui/material"

const SucceededPayment = () => {
  useEffect(() => {
    window.top.postMessage("3DS-authentication-complete", "*")
  }, [])
  return (
    <div className="flex flex-col flex-1 gap-3 justify-center items-center text-custom-primary">
      <CircularProgress size={70} color={"inherit"} />
      <p className="text-custom-primary">Fetching your bill...</p>
    </div>
  )
}

export default SucceededPayment
