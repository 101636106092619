import React, { useEffect, useState } from "react"
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar"
import { DigitalClock, LocalizationProvider } from "@mui/x-date-pickers"
import {
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router"
import HorizontalLine from "../../components/custom/HorizontalLine"
import moment from "moment"
import classNames from "classnames"
import styled from "../booking/Calender.module.css"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"

const ModifyCalender = () => {
  const { reservationData } = useOutletContext()
  const [showCalender, setshowCalender] = useState(false)
  const [showClock, setshowClock] = useState(false)
  const [selectedDateTime, setselectedDateTime] = useState(moment())

  const nav = useNavigate()
  const { restaurant, bookingID } = useParams()
  const { state } = useLocation()

  useEffect(() => {
    if (state) {
      setselectedDateTime(moment(state.dateTime))
    }
  }, [state])

  const isToday = moment(selectedDateTime)
    .startOf("day")
    .isSame(moment().startOf("day"))

  const toggleCalenderView = () => setshowCalender(!showCalender)
  const toggleClockView = () => setshowClock(!showClock)

  const onDatePick = (newDate) => {
    newDate.hour(selectedDateTime.hour())
    newDate.minute(selectedDateTime.minute())

    setselectedDateTime(newDate)
    setshowCalender(false)
  }

  const onTimeSelect = (newTime) => {
    const timeDate = selectedDateTime
    timeDate.hour(newTime.hour())
    timeDate.minute(newTime.minute())
    timeDate.second(0)
    timeDate.millisecond(0)
    setselectedDateTime(timeDate)
    setshowClock(false)
  }

  const getDisabledDates = (date) =>
    moment(date).startOf("day").isBefore(moment().startOf("day"))

  const search = () => {
    nav(`/modify-reservation/${restaurant}/${bookingID}/availability`, {
      state: {
        selectedDateTime: selectedDateTime.format(),
      },
    })
  }

  useEffect(() => {
    if (!state) {
      nav(`/modify-reservation/${restaurant}/${bookingID}`)
    }
  }, [state, nav, restaurant, bookingID])

  if (!state) return null

  return (
    <div className="mt-[50px]">
      <div className="flex flex-col mx-[30px]  gap-y-7 items-center  mb-[150px]">
        <div className="flex w-full justify-between mx-[30px]">
          <div className="w-[167px] h-[37px] text-black text-[22px] font-semibold leading-normal">
            Guests
          </div>
          <div className="flex items-center gap-x-5 font-bold text-xl">
            <p>{reservationData.guestsCount}</p>
          </div>
        </div>

        <HorizontalLine />
        <div className="flex w-full justify-between mx-[30px]">
          <div className="w-[167px] h-[37px] text-black text-[22px] font-semibold leading-normal">
            Date
          </div>
          <div
            className="flex items-center gap-x-5 font-bold text-xl"
            onClick={toggleCalenderView}
          >
            {isToday ? "Today" : moment(selectedDateTime).format("ddd, MMM D")}
          </div>
        </div>
        {showCalender && (
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}
          >
            <DateCalendar
              sx={{ width: "100%" }}
              onChange={onDatePick}
              shouldDisableDate={getDisabledDates}
            />
          </LocalizationProvider>
        )}

        <HorizontalLine />
        <div className="flex w-full justify-between mx-[30px]">
          <div className="w-[167px] h-[37px] text-black text-[22px] font-semibold leading-normal">
            Time
          </div>
          <div
            className="flex items-center gap-x-5 font-bold text-xl"
            onClick={toggleClockView}
          >
            {selectedDateTime ? selectedDateTime.format("HH:mm") : "All Day"}
          </div>
        </div>
        {showClock && (
          <LocalizationProvider
            dateAdapter={AdapterMoment}
            dateLibInstance={moment}
          >
            <DigitalClock
              ampm={false}
              skipDisabled
              minTime={moment({ hour: 12, minute: 30 })}
              maxTime={moment({ hour: 21, minute: 0 })}
              className={classNames("h-[150px]", styled.digitalClock)}
              onChange={onTimeSelect}
            />
          </LocalizationProvider>
        )}
      </div>
      <div className="absolute bottom-[38px] w-[95%] h-[50px] justify-center items-center inline-flex">
        <button
          onClick={search}
          className="grow shrink basis-0 h-12 px-8 py-3 bg-slate-900 rounded-3xl justify-center items-center gap-2 flex"
        >
          <div className="text-center text-amber-50 text-[22px] font-semibold leading-normal">
            Search
          </div>
        </button>
      </div>
    </div>
  )
}

export default ModifyCalender
