import "../index.css"
import React from "react"
import HomeLogo from "../components/SVG/homeLogo"

const App = () => {
  const styles = {
    homeContainer: {
      padding: "2rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      boxSizing: "border-box",
      rowGap: "2rem",
      backgroundImage: 'url("/bg.jpg")',
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "50% 50%",
      height: "100vh",
      fontFamily: "Arial, Helvetica, sans-serif",
    },
    logo: {
      maxWidth: "12rem",
      marginRight: "-1rem",
      width: "100%",
    },
    button: {
      padding: "1rem 2rem",
      fontSize: "1.25rem",
      borderRadius: "10rem",
      backgroundColor: "#29304e",
      color: "#fff",
      textDecoration: "none",
      textAlign: "center",
      transition: "all 0.2s ease",
    },
    buttonHover: {
      opacity: 0.7,
    },
  }
  return (
    <div className=" ">
      <div className="homeContainer" style={styles.homeContainer}>
        <HomeLogo className="logo" alt="The Zumi logo" />
        <a className="button" href="https://hi.zumi.app" style={styles.button}>
          Take a look
        </a>
      </div>
    </div>
  )
}

export default App
