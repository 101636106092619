import React, { useEffect, useRef, useState } from "react"
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import cn from "classnames"

import { CircularProgress, Collapse } from "@mui/material"
import { Check } from "@mui/icons-material"
import { SpinnerIcon } from "../../components/custom/Icons"
import currencies from "../../utils/currencies.json"

import {
  addChargeToSvr,
  addPaymentFailure,
  addStripePaymentLog,
  createPaymentIntent,
  getPaymentMethod,
  mailBill,
  payBillInFull,
  payInFull3DS,
  payInFullApplePayment,
  updateSvrReservation,
} from "../../api/bills"

import { CardIcon } from "../modify payment"
import { fetchReservationOwnerPayments } from "../../api/reservations"
import ConfirmPayment from "../../components/custom/ConfirmPayment"
import moment from "moment"
import { toast } from "react-toastify"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri"
import SplitBill from "./SplitBill"
import BottomDrawer from "../../components/custom/BottomDrawer"
import CompletePaymentLoading from "../../components/custom/CompletePaymentLoading"
import PaymentForm from "./PaymentForm"
import { flushSync } from "react-dom"
import CuttedHorizontalLine from "../../components/custom/CuttedHorizontalLine"
import CustomHR from "../../components/custom/CustomHR"
import PersonIcon from "../../components/SVG/PersonIcon"
import CalendarIcon from "../../components/SVG/CalendarIcon"
import ChangePaymentMethod from "./ChangePaymentMethod"
import StripeCheckoutForm from "../../components/CheckoutForm"
import NotifyMark from "../../components/SVG/NotifyMark"
import { FaFacebookF, FaInstagram, FaXTwitter } from "react-icons/fa6"
import SwipeButton from "../../components/custom/SwipeButton"
import { calcFullAmountInPence } from "../../utils/helperFunctions"

loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

const PayingBillComponent = () => {
  const [ownerPaid, setOwnerPaid] = useState(false)
  const [sendEmail, setsendEmail] = useState("")
  const [showConfirmComponent, setShowConfirmComponent] = useState(false)
  const [owner, setOwner] = useState(null)
  const { reservationID } = useParams()
  const [searchParams] = useSearchParams()
  const guestID = searchParams.get("id")
  const [disableBillMail, setDisableBillMail] = useState(false)
  const [billEmailNotValid, setBillEmailNotValid] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState({})
  const [isLoadingModal, setIsLoadingModal] = useState(true)
  const [openedTabs, setOpenedTabs] = React.useState([0, 1, 2])
  const [showAll, setShowAll] = React.useState(false)
  const [splitBill, setSplitBill] = useState(false)
  const [payFull, setPayFull] = useState(false)
  const [somenoneElse, setSomeoneElse] = useState(false)
  const [anotherPayment, setAnotherPayment] = useState(false)
  const [isWalletConfirming, setIsWalletConfirming] = useState(false)
  const [isChange, setIsChange] = useState(false)
  const [showFooter, setShowFooter] = useState(false)
  const settleRef = useRef()

  const handleTabClick = (index) => {
    if (openedTabs.includes(index)) {
      setOpenedTabs(openedTabs.filter((i) => i !== index))
    } else {
      setOpenedTabs(openedTabs.concat([index]))
    }
  }

  const nav = useNavigate()

  const stripe = useStripe()

  const {
    invoiceData,
    statusPaid,
    setStatusPaid,
    invoiceID,
    setShowPaymentBtn,
    showPaymentBtn,
    portionPaid,
    restaurantInfo,
  } = useOutletContext()

  const shownItems = showAll ? invoiceData.items : invoiceData.items.slice(0, 3)
  const unShownLength = invoiceData.items.length - 3
  const currencySymbol = Object.values(currencies).find(
    (c) => c.code.toLowerCase() === invoiceData.currency.toLowerCase(),
  )?.symbol

  const { data, isLoading, isError } = useQuery({
    queryKey: [invoiceData.NewBookingID, "Payments"],
    queryFn: () => fetchReservationOwnerPayments(invoiceData.NewBookingID),
    retry: false,
    refetchOnWindowFocus: false,
  })

  const payInFullMutation = useMutation({
    mutationFn: payBillInFull,
    onSuccess: async (data) => {
      if (data?.next_action?.redirect_to_url) {
        setPaymentIntent(data)
        setIsLoadingModal(true)
        setShowModal(true)
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
        return
      }
      updateReservationMutation.mutate({
        reservation_id: reservationID,
        data: { status: "CUSTOM_STATUS_24" },
      })
      addChargeToSvrMutation.mutate({
        reservation_id: reservationID,
        paymentIntentID: data.paymentIntentID,
        amountInPound: invoiceData.price,
        subtotal: invoiceData.subtotal,
      })
      setStatusPaid(true)
      setShowPaymentBtn(false)
    },
    onError: (err) => {
      toast.error(err.message ?? "pay in full failed")
    },
  })
  const payInFullApplePaymentMutation = useMutation({
    mutationFn: payInFullApplePayment,
    onSuccess: (data) => {
      updateReservationMutation.mutate({
        reservation_id: reservationID,
        data: { status: "CUSTOM_STATUS_24" },
      })
      addChargeToSvrMutation.mutate({
        reservation_id: reservationID,
        paymentIntentID: data.paymentIntentID,
        amountInPound: invoiceData.price,
        subtotal: invoiceData.subtotal,
      })
      setStatusPaid(true)
      setShowPaymentBtn(false)
      setIsWalletConfirming(false)
    },
    onError: (err) => {
      toast.error(err.message ?? "pay in full with wallet failed")
      setIsWalletConfirming(false)
    },
  })
  const payInFull3DSMutation = useMutation({
    mutationFn: payInFull3DS,
    onSuccess: (data) => {
      updateReservationMutation.mutate({
        reservation_id: reservationID,
        data: { status: "CUSTOM_STATUS_24" },
      })
      addChargeToSvrMutation.mutate({
        reservation_id: reservationID,
        paymentIntentID: data.paymentIntentID,
        amountInPound: invoiceData.price,
        subtotal: invoiceData.subtotal,
      })
      setShowModal(false)
      document.body.style.overflow = "auto"
      setStatusPaid(true)
      setShowPaymentBtn(false)
      setIsLoadingModal(false)
    },
    onError: (err) => {
      setShowModal(false)
      document.body.style.overflow = "auto"
      toast.error(err.message ?? "payment in 3ds failed")
      setIsLoadingModal(false)
    },
  })

  const mailBillMutation = useMutation({
    mutationFn: mailBill,
    onError: (err) => {
      toast.error(err.message ?? "sending email failed")
    },
  })
  const updateReservationMutation = useMutation({
    mutationFn: updateSvrReservation,
    onSuccess: () => {},
    onError: (err) => {
      toast.error(err.message ?? "updating reservation failed")
    },
  })

  const addPaymentFailedMutation = useMutation(addPaymentFailure)

  const addChargeToSvrMutation = useMutation({
    mutationFn: addChargeToSvr,
    onSuccess: () => {},
    onError: (err) => {
      toast.error(err.message ?? "adding charge failed")
    },
  })
  const onBillMail = () => {
    const validEmail = sendEmail.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    if (!validEmail) return setBillEmailNotValid(true)
    setBillEmailNotValid(false)
    mailBillMutation.mutate(
      {
        billID: invoiceID,
        email: sendEmail,
        paid:
          (invoiceData.price / invoiceData.guestsCount) *
          (portionPaid || ownerPaid?.portion),
      },
      {
        onSuccess: () => {
          setsendEmail("")
        },
      },
    )
    setDisableBillMail(true)
  }

  const validateOnChange = (e) => {
    const validEmail = e.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
    if (!validEmail) return setBillEmailNotValid(true)
    else return setBillEmailNotValid(false)
  }

  const onCopy = () => {
    window.navigator.clipboard
      .writeText(`${window.location.origin}/bill/${reservationID}`)
      .then(() => {
        toast.success("Link has been copied")
      })
  }
  useEffect(() => {
    if (invoiceData.locked === true && statusPaid) {
    }
  }, [invoiceData])

  useEffect(() => {
    const isOwner = invoiceData.guestID === guestID
    const ownerData = invoiceData?.splitters?.find((i) => i.isOwner)

    if (invoiceData.pay_method === "split") {
      setSplitBill(true)
    }

    if (ownerData) setShowPaymentBtn(false)
    if (!isOwner) {
      setOwner(false)
    } else {
      if (ownerData) setOwnerPaid(ownerData)
      if (isOwner) setOwner(true)
    }

    if (guestID && !isOwner) {
      return nav("/")
    }
  }, [invoiceData])

  useEffect(() => {
    const handleMessage = async (event) => {
      if (
        event.data === "3DS-authentication-complete" &&
        paymentIntent?.client_secret
      ) {
        const { paymentIntent: intent } = await stripe.retrievePaymentIntent(
          paymentIntent.client_secret,
        )
        if (intent.status === "succeeded") {
          payInFull3DSMutation.mutate({
            invoiceID,
            paymentIntentID: intent.id,
          })
        } else {
          toast.error(
            intent.last_payment_error.message ?? "authenticating failed",
          )
          setShowModal(false)
          setIsLoadingModal(false)
          document.body.style.overflow = "auto"
        }
      }
    }

    window.addEventListener("message", handleMessage, false)

    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [stripe, paymentIntent])

  useEffect(() => {
    if (isWalletConfirming) {
      window.scrollTo(0, 0)
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isWalletConfirming])

  /*  useMutation({
      mutationFn: getClientSecret,
    });*/
  const onConfirm = async (elementsObj, stripeObj) => {
    try {
      // Confirm the PaymentIntent without handling potential next actions (yet).

      setIsWalletConfirming(true)
      const { client_secret } = await createPaymentIntent({
        amountInPence: calcFullAmountInPence(invoiceData),
        description: `Invoice:${invoiceID},Reservation:${
          invoiceData.NewBookingID
        },Restaurant:${invoiceData.restaurantName} ${invoiceData.restaurantID},Date: ${moment().format()}`,
        meta: {
          invoiceNum: invoiceData.receiptNum,
          table: invoiceData.table,
        },
      })
      let { error, paymentIntent } = await stripeObj.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements: elementsObj,
        clientSecret: client_secret,
        confirmParams: {
          expand: ["payment_method"],
          return_url: `${window.location.origin}/bill/${reservationID}/`,
        },
        redirect: "if_required",
      })

      let modifiedPaymentIntent = paymentIntent ? { ...paymentIntent } : {}
      let paymentBodySucceeded = {}
      let paymentModifiedBodySucceeded = {}
      let paymentBodyNotSucceeded = {}
      let paymentBodyError = {}

      if (error) {
        toast.error(error.message)
        paymentBodyError = {
          reservationID,
          payment_intent: error.payment_intent,
          payment_method: error.payment_method,
          isOwner: owner,
          reason: error.message,
        }
        addPaymentFailedMutation.mutate(paymentBodyError)
        setIsWalletConfirming(false)
      } else if (paymentIntent.status !== "succeeded") {
        toast.error("payment not succeeded because of some reason")
        paymentBodyNotSucceeded = {
          reservationID,
          payment_intent: paymentIntent,
          payment_method: paymentIntent.payment_method,
          isOwner: owner,
          reason: "payment not succeeded because of some reason",
        }
        addPaymentFailedMutation.mutate(paymentBodyNotSucceeded)
        setIsWalletConfirming(false)
      } else if (paymentIntent.status === "succeeded") {
        paymentBodySucceeded = {
          billID: invoiceID,
          email: owner
            ? invoiceData?.guestEmail ?? "no-email@owner.com"
            : "guest@guest.com",
          name: owner
            ? `${invoiceData.firstName} ${invoiceData.lastName}`
            : "guest",
          paymentMethod: {
            id: paymentIntent.payment_method?.id ?? null,
            card: { last4: paymentIntent.payment_method?.card?.last4 ?? null },
          },
          paymentType:
            paymentIntent.payment_method.card?.wallet ?? null
              ? paymentIntent.payment_method?.card?.wallet?.type ?? null
              : paymentIntent.payment_method?.type ?? null,
          isOwner: !!guestID ?? null,
          paymentIntent: paymentIntent ?? null,
        }

        if (!modifiedPaymentIntent.payment_method.id) {
          modifiedPaymentIntent.payment_method = await getPaymentMethod(
            modifiedPaymentIntent.payment_method,
          )
        }

        if (!modifiedPaymentIntent.payment_method.card) {
          modifiedPaymentIntent.payment_method.card = {
            last4: null,
            wallet: { type: modifiedPaymentIntent.payment_method.type ?? null },
          }
        }

        paymentModifiedBodySucceeded = {
          billID: invoiceID,
          email: owner
            ? invoiceData.guestEmail ?? "no-email@owner.com"
            : "guest@guest.com",
          name: owner
            ? `${invoiceData.firstName} ${invoiceData.lastName}`
            : "guest",
          paymentMethod: {
            id: modifiedPaymentIntent.payment_method.id,
            card: { last4: modifiedPaymentIntent.payment_method.card.last4 },
          },
          paymentType: modifiedPaymentIntent.payment_method.card?.wallet
            ? modifiedPaymentIntent.payment_method.card.wallet.type
            : modifiedPaymentIntent.payment_method.type,
          isOwner: !!guestID,
          paymentIntent: modifiedPaymentIntent,
        }
        payInFullApplePaymentMutation.mutate(paymentModifiedBodySucceeded)
      }
      await addStripePaymentLog({
        reservationID,
        stripeLog: {
          stripePaymentIntent: paymentIntent ?? {},
          stripeModifiedPaymentIntent: modifiedPaymentIntent ?? {},
          stripePaymentError: error ?? {},
        },
        zumiLog: {
          zumiPaymentLog: paymentBodySucceeded,
          zumiModifiedPaymentLog: paymentModifiedBodySucceeded,
          zumiPaymentError: paymentBodyError,
        },
      })
    } catch (err) {
      toast.error(err.message)

      console.log(typeof err)
      console.log(err.message)
      console.log(err.stack)
      await addStripePaymentLog({
        reservationID,
        errorLog: { message: err.message, stack: err.stack },
      })
      setIsWalletConfirming(false)
    }
  }
  // invoiceData.bill_status = " ";
  if (isLoading) return null

  const yourBillTabContent = (
    <>
      <div className="w-full flex flex-col gap-y-3 mt-3">
        <div className="mb-4">
          {invoiceData.bill_status === "paid" && (
            <p className="text-[#EBFFA8] font-semibold my-2">
              <Check /> Bill paid
            </p>
          )}
          {invoiceData.splitters?.length > 0 &&
            invoiceData.amount_unpaid > 0 && (
              <p className="flex align-center gap-2">
                <NotifyMark /> {Number(invoiceData.amount_unpaid).toFixed(2)}{" "}
                remaining for guests to pay
              </p>
            )}
          <p className="flex align-center gap-2">
            <PersonIcon /> {invoiceData.guestsCount} guests
          </p>
          <p className="flex align-center gap-2">
            <CalendarIcon /> {new Date(invoiceData.dateTime).toDateString()}
          </p>
        </div>
      </div>
      {owner && ownerPaid && invoiceData.bill_status !== "paid" && (
        <span
          onClick={async () => {
            await flushSync(() => setAnotherPayment(true))
            settleRef.current.scrollIntoView({
              behavior: "smooth",
              block: "start",
            })
          }}
          className="my-[22px] px-[10px] py-[9px] bg-lime-50 rounded-[19.02px] border border-slate-900 justify-center gap-x-1 items-center inline-flex w-full"
        >
          <div className="h-5 text-center text-black text-base font-medium leading-[17.60px]">
            Make another payment
          </div>
        </span>
      )}

      <div className="w-full flex flex-col gap-2 overflow-x-hidden">
        {shownItems.map((item, index) => {
          return (
            <>
              {/* <CustomHR /> */}
              <CuttedHorizontalLine />
              <div key={index}>
                <div className="flex align-center justify-between">
                  <p>
                    <strong className="me-2">{item.quantity}</strong>{" "}
                    <span className="font-light">{item.name}</span>
                  </p>
                  <strong>£{item.price.toFixed(2)}</strong>
                </div>
              </div>
            </>
          )
        })}
        <CustomHR />
      </div>
      {invoiceData.items.length > 3 && (
        <div
          onClick={() => setShowAll(!showAll)}
          className="flex align-center justify-between my-3 cursor-pointer"
        >
          <strong>
            {showAll
              ? "Hide items"
              : `Show full bill (+${unShownLength} items)`}
          </strong>
          {showAll ? (
            <RiArrowUpSLine size={22} />
          ) : (
            <RiArrowDownSLine size={22} />
          )}
        </div>
      )}
    </>
  )

  const totalPrice =
    portionPaid || ownerPaid
      ? (
          (invoiceData.price / invoiceData.guestsCount) *
          (owner ? ownerPaid?.portion : portionPaid)
        ).toFixed(2)
      : Number(invoiceData.price).toFixed(2)

  const guestsPaidPrice =
    Math.ceil(
      (invoiceData.price / invoiceData.guestsCount) *
        (owner ? ownerPaid?.portion : portionPaid) *
        100,
    ) / 100

  const yourTotalTabContent = (
    <div className="w-full">
      <div className="flex align-center justify-between mt-2">
        <span className="text-[#FEFFFACC]">Subtotal</span>
        <span>
          {currencySymbol}
          {(+invoiceData.subtotal).toFixed(2)}
        </span>
      </div>
      <div className="flex align-center justify-between">
        <span className="text-[#FEFFFACC]">VAT @ 20%</span>
        <span>
          {currencySymbol}
          {Number(
            invoiceData.vatTotal === 0
              ? invoiceData.vat_calc
              : invoiceData.vatTotal,
          ).toFixed(2)}
        </span>
      </div>
      <div className="flex align-center justify-between">
        <span className="text-[#FEFFFACC]">Service Charge (12.5%)</span>
        <span>
          {currencySymbol}
          {(+invoiceData.serviceAmount).toFixed(2)}
        </span>
      </div>
      {portionPaid || ownerPaid ? (
        <>
          <div className="flex align-center justify-between">
            <span className="text-[#FEFFFACC]">Total</span>
            <span>
              {currencySymbol}
              {Number(invoiceData.price).toFixed(2)}
            </span>
          </div>
          <div className="flex align-center justify-between">
            <span className="text-[#FEFFFACC]">
              Paying for {owner ? ownerPaid?.portion : portionPaid} guest
            </span>
            <span>
              {isNaN(guestsPaidPrice)
                ? "Loading..."
                : `${currencySymbol}${guestsPaidPrice.toFixed(2)}`}
            </span>
          </div>
        </>
      ) : null}
      <CustomHR className="my-3" />
      <div className="flex align-center justify-between">
        <span className="text-[20px] text-[#9AAAFF] font-semibold">Total</span>
        <span className="text-[20px] text-[#9AAAFF]">
          {isNaN(totalPrice)
            ? "Loading..."
            : `${currencySymbol}${Number(invoiceData.price).toFixed(2)}`}
        </span>
      </div>
    </div>
  )

  const unPaidSettleTabContent = (
    <>
      <div className={isWalletConfirming ? "hidden" : ""}>
        {!statusPaid && showPaymentBtn && (
          <div className="relative flex flex-1 flex-col flex-wrap justify-start gap-[10px] pt-10 w-full">
            {invoiceData.pay_method !== "split" && (
              <div className="flex justify-between">
                <div className="flex-1 mx-1 w-[48%] h-[50px] justify-center items-center inline-flex cursor-pointer">
                  <div
                    onClick={() => {
                      setPayFull((prevState) => !prevState)
                      setSplitBill(false)
                    }}
                    style={payFull ? { background: "#fff" } : {}}
                    className="grow shrink basis-0 h-12 px-8 py-3 bg-slate-900 rounded-3xl border border-lime-50 justify-center items-center gap-2 flex"
                  >
                    <button
                      disabled={payInFullMutation.isLoading}
                      className="text-center text-lime-50 text-base font-semibold leading-normal"
                      style={payFull ? { color: "#17243A" } : {}}
                    >
                      Pay in full
                    </button>
                  </div>
                </div>
                <div className="flex-1 mx-1 h-[50px] justify-center items-center inline-flex cursor-pointer">
                  <div
                    onClick={() => {
                      setSplitBill((prevState) => !prevState)
                      setPayFull(false)
                    }}
                    style={splitBill ? { background: "#fff" } : {}}
                    className="grow shrink basis-0 h-12 px-8 py-3 bg-slate-900 rounded-3xl border border-lime-50 justify-center items-center gap-2 flex"
                  >
                    <button
                      className="text-center text-lime-50 text-base font-semibold leading-normal"
                      style={splitBill ? { color: "#17243A" } : {}}
                    >
                      Split bill
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {(statusPaid || !showPaymentBtn) && (
          <div className="w-full flex flex-col">
            {invoiceData.bill_status === "paid" && (
              <>
                <div className="flex flex-col gap-2 w-full mt-5 email-form">
                  <div className="flex">
                    <input
                      type="email"
                      placeholder="Enter your email address"
                      className="rounded-3xl border h-[53px] border-slate-400 text-amber-50 bg-transparent w-full px-[16px] mr-[2px]"
                      value={sendEmail}
                      onChange={(e) => {
                        setsendEmail(e.target.value)
                        validateOnChange(e.target.value)
                      }}
                    />
                    <button
                      disabled={disableBillMail}
                      onClick={onBillMail}
                      className="grow shrink basis-0 h-12  rounded-3xl justify-center items-center gap-2 flex mt-[2px] me-[2px]"
                      style={{
                        minWidth: "120px",
                        background: mailBillMutation.isSuccess
                          ? "white"
                          : "#9AAAFF",
                      }}
                    >
                      <div className="text-center text-slate-900 text-base font-semibold leading-normal ">
                        <div className="flex w-full gap-3 items-center">
                          {mailBillMutation.isLoading && <SpinnerIcon />}{" "}
                          {!disableBillMail || !mailBillMutation.isSuccess ? (
                            <span>Send</span>
                          ) : (
                            <span>
                              <Check /> Receipt Sent
                            </span>
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                <span className="text-sm text-left w-full mb-5 mt-1 text-[red]">
                  {billEmailNotValid ? "Email isn't valid" : ""}
                </span>
              </>
            )}
          </div>
        )}
        {invoiceData.bill_status !== "paid" && (
          <>
            <Collapse in={splitBill}>
              <SplitBill
                setSomeoneElse={setSomeoneElse}
                anotherPayment={anotherPayment}
                setAnotherPayment={setAnotherPayment}
                setIsWalletConfirming={setIsWalletConfirming}
              />
            </Collapse>
            <Collapse in={payFull} className="mt-6">
              {invoiceData?.price > 0 && (
                <div className="px-[5px]">
                  <StripeCheckoutForm
                    onConfirm={onConfirm}
                    amount={calcFullAmountInPence(invoiceData)}
                  />
                </div>
              )}

              <p className="text-center my-5 text-[20px]">OR</p>
              {!!owner &&
                !ownerPaid &&
                data?.paymentMethods &&
                data?.paymentMethods?.map((item, index) => {
                  if (item.isDefault) {
                    return (
                      <>
                        <div key={index} className="w-full">
                          <ChangePaymentMethod
                            bookId={invoiceData.NewBookingID}
                            isOpen={isChange}
                          />
                          <div
                            className={cn(
                              "w-full grid grid-cols-2 items-center my-3",
                            )}
                          >
                            <div className="col-span-1 flex gap-x-2 align-baseline">
                              <span>
                                <CardIcon brand={item.card.brand} />
                              </span>
                              <span className="text-amber-50 text-xs font-normal leading-none">
                                {item.card.brand} ending with {item.card.last4}
                              </span>
                            </div>
                            {!statusPaid && (
                              <div
                                className="col-span-1 flex justify-end"
                                onClick={() =>
                                  // nav(`/modify-payment/${invoiceData.restaurantID}/${invoiceData.NewBookingID}`)
                                  {
                                    setIsChange(false)
                                    setTimeout(() => setIsChange(true))
                                  }
                                }
                              >
                                <div className="text-[#fff] text-[14px] bg-[#555] px-2 py-1 rounded-full">
                                  Change
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="  flex justify-center"></div>
                        </div>
                      </>
                    )
                  } else return null
                })}
              {/* <button className="w-full my-2 bg-[#fff] text-[#fff] p-3 rounded-3xl amex-button">Click to pay</button> */}
              {payInFullMutation.isLoading ? (
                <CompletePaymentLoading />
              ) : (
                owner &&
                !ownerPaid &&
                data?.paymentMethods && (
                  <SwipeButton
                    onSwipe={() =>
                      payInFullMutation.mutate({
                        billID: invoiceID,
                        isOwner: owner,
                      })
                    }
                  />
                )
              )}

              {(!data?.paymentMethods || !guestID) && (
                <PaymentForm
                  state={{
                    payMethod: "full",
                  }}
                  setIsWalletConfirming={setIsWalletConfirming}
                />
              )}
            </Collapse>
          </>
        )}
      </div>
      {isWalletConfirming && (
        <div className="h-screen absolute inset-0 flex justify-center items-center text-custom-primary bg-black bg-opacity-50 gap-2">
          <CircularProgress size={50} color={"inherit"} />
          <p className="text-custom-primary">Processing...</p>
        </div>
      )}
    </>
  )

  const paidSection = (
    <div className="w-full p-4">
      <p className="text-[#EBFFA8] font-semibold">
        {(portionPaid || ownerPaid) && invoiceData.bill_status !== "paid"
          ? "Portion paid"
          : "Bill paid"}
      </p>
      <button className="w-full my-5 bg-[#EBFFA8] text-[#17243A] p-3 rounded-3xl">
        You've paid <Check className="ms-3" />
      </button>
      <BottomDrawer />
    </div>
  )
  const tabs = [
    {
      title: `Your bill #${invoiceData.reservationCode}`,
      component: yourBillTabContent,
    },
    {
      title: "Your Total",
      component: yourTotalTabContent,
    },
    {
      title:
        invoiceData.bill_status === "paid"
          ? "Email Your Bill"
          : "Settle Your Bill",
      component: unPaidSettleTabContent,
    },
  ]

  if (isLoading) return null

  if (isError)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center ">
        <h1 className="text-amber-50 text-[40px] font-extrabold leading-[60px]">
          Reservation Not Found
        </h1>
      </div>
    )
  return (
    <>
      <div className="w-full min-h-[calc(100vh-100px)] lg:min-h-[calc(100vh-100px)] lg:max-w-[50%] relative flex flex-col items-center bg-[#131C2B] overflow-hidden py-4">
        {tabs.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className="w-full px-4"
                hidden={
                  !!(
                    index === 2 &&
                    (portionPaid || ownerPaid) &&
                    invoiceData.bill_status !== "paid"
                  ) && !anotherPayment
                }
                ref={index === 2 ? settleRef : null}
              >
                <div
                  onClick={() => handleTabClick(index)}
                  className="flex align-center justify-between cursor-pointer"
                >
                  <h6 className="text-[#9AAAFF] font-semibold text-[20px]">
                    {item.title}
                  </h6>
                  {openedTabs.includes(index) ? (
                    <RiArrowUpSLine size={26} color="#9AAAFF" />
                  ) : (
                    <RiArrowDownSLine size={26} color="#9AAAFF" />
                  )}
                </div>
                <Collapse in={openedTabs.includes(index)}>
                  {item.component}
                </Collapse>
              </div>
              {index === 2 && openedTabs.includes(index) ? null : (
                <CustomHR className="my-3" />
              )}
              {(portionPaid ||
                ownerPaid ||
                invoiceData.bill_status === "paid") &&
              index === 1
                ? paidSection
                : null}
            </>
          )
        })}
        {!(portionPaid || ownerPaid) && invoiceData.bill_status !== "paid" && (
          <p
            onClick={() => setSomeoneElse(!somenoneElse)}
            className="text-center text-[#9AAAFF] underline cursor-pointer my-5"
          >
            Someone else wants to pay the bill
          </p>
        )}
        <Collapse
          className="px-4"
          in={
            somenoneElse ||
            ((portionPaid || ownerPaid) && invoiceData.bill_status !== "paid")
          }
        >
          <p className="text-[20px] text-[#9AAAFF] text-left mt-3">
            Share the bill to the remaining guests
          </p>
          <p className="text-[#FEFFFACC] mt-4">
            Have your guests scan the QR code below to settle their share of the
            bill.
          </p>
          <div className="h-[250px] w-full bg-[#fff] rounded-lg mt-3 flex align-center justify-center">
            <img
              src={invoiceData.billQRCode}
              className="h-[200px] mt-[25px]"
              alt="qr-code"
            />
          </div>
          <p
            onClick={() => onCopy()}
            className="text-center underline my-3 cursor-pointer"
          >
            Having trouble scanning? Share the link instead
          </p>
        </Collapse>
        <div
          style={{ width: "100%" }}
          className="flex-1 flex flex-col justify-end"
        >
          <CustomHR />
          <div className="flex justify-between mt-3 w-full px-2">
            <p className="text-[#9AAAFF] text-[13px]">Powered by Zumi</p>
            <p
              className="flex text-[#FEFFFA80] text-[13px] cursor-pointer"
              onClick={() => setShowFooter(!showFooter)}
            >
              {showFooter ? (
                <>
                  <RiArrowUpSLine size={18} className="me-2" /> Hide bill info
                </>
              ) : (
                <>
                  <RiArrowDownSLine size={18} className="me-2" /> Show bill info
                </>
              )}
            </p>
          </div>
          <Collapse className="px-2 mt-4" in={showFooter}>
            <div className="flex align-center justify-between text-[#14px]">
              <span>Server</span>
              <span>{invoiceData.server}</span>
            </div>
            <div className="flex align-center justify-between text-[#14px]">
              <span>Date / time</span>
              <span>
                {new Date(invoiceData.dateTime).toLocaleDateString()} /{" "}
                {new Date(invoiceData.dateTime).toLocaleTimeString()}
              </span>
            </div>
            {/* <div className="flex align-center justify-between text-[#14px]">
              <span>Covers</span>
              <span>{invoiceData.table}</span>
            </div> */}
            <div className="flex align-center justify-between text-[#14px]">
              <span>Table No.</span>
              <span>{invoiceData.table}</span>
            </div>
            <div className="flex align-center justify-between text-[#14px]">
              <span>Reservation No.</span>
              <span>#{invoiceData.reservationCode}</span>
            </div>
            <div className="flex align-center justify-between text-[#14px]">
              <span>Bill No.</span>
              <span>#{invoiceData.receiptNum}</span>
            </div>
            <CustomHR className="my-8" />
            <div className="flex justify-center align-center gap-4">
              <FaXTwitter color="#FEFFFA" size={20} />
              <FaFacebookF color="#FEFFFA" size={20} />
              <FaInstagram color="#FEFFFA" size={20} />
              <h5>{restaurantInfo.socialID}</h5>
            </div>
            <p className="my-5 text-center text-[14px]">
              {invoiceData.restaurantLocation}
            </p>
            <p className="text-center text-[14px]">
              {invoiceData.restaurantEmail}
            </p>
            <p className="mt-2 text-center text-[14px]">
              {restaurantInfo.website}
            </p>
            <p className="mt-4 mb-8 text-center text-[14px]">
              Includes VAT at current rate <br /> VAT No.{" "}
              {restaurantInfo.vatNum} • Inv No. - {invoiceData.reservationCode}
            </p>
          </Collapse>
        </div>
      </div>

      {showConfirmComponent && (
        <ConfirmPayment
          data={data}
          price={invoiceData.price}
          onPay={() => {
            setShowConfirmComponent(false)
            payInFullMutation.mutate({ billID: invoiceID, isOwner: owner })
          }}
          onBack={() => setShowConfirmComponent(false)}
        />
      )}
      {showModal && isLoadingModal && paymentIntent && (
        <div className="h-screen absolute inset-0 flex justify-center items-center text-custom-primary bg-black bg-opacity-50 gap-2">
          <CircularProgress size={50} color={"inherit"} />
          <p className="text-custom-primary">Processing...</p>
        </div>
      )}
      {showModal && paymentIntent && (
        <div className="fixed inset-0 bottom-0 flex justify-center items-center h-screen">
          <iframe
            className="w-full h-full p-1/6"
            title="stripeconfirm"
            data-hj-allow-iframe="true"
            src={paymentIntent.next_action.redirect_to_url.url}
          ></iframe>
        </div>
      )}
    </>
  )
}

const PayingBill = () => {
  return (
    <Elements stripe={stripePromise}>
      <PayingBillComponent />
    </Elements>
  )
}

export default PayingBill
