import React, { createContext, useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { onAuthStateChanged } from "firebase/auth"
import Login from "./screens/Login"
import SignUp from "./screens/SignUp"
import Home from "./screens/Home"
import FAQ from "./screens/FAQ"
import Rewards from "./screens/Rewards"
import { auth, db } from "./firebaseConfig"
import { doc, getDoc } from "firebase/firestore"
import UserContext from "./components/UserContext"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import moment from "moment-timezone"
import TestTag from "./components/TestTag"

import Booking, {
  Availability,
  Calender,
  Confirm,
  Information,
} from "./screens/booking"
import ModifyReservation, {
  ModifyAvailability,
  ModifyCalender,
  ModifyInformation,
} from "./screens/modify reservation"
import Reservations, {
  GuestConfirm,
  GuestInformation,
} from "./screens/reservations"
import ModifyPayment from "./screens/modify payment/index"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import Cancelled from "./screens/modify reservation/CancelledPage"
import PublicRoute from "./components/router/PublicRoute"
import PayingBill from "./screens/paying bill/PayingBill"
import SplitBill from "./screens/paying bill/SplitBill"
import PaymentForm from "./screens/paying bill/PaymentForm"
import Bills from "./screens/paying bill"
import MenuPage from "./screens/menu"
import FAQSUPPORT from "./screens/menu/FAQ"
import AdminDashboard from "./adminDashboard/pages/index"

import { ToastContainer } from "react-toastify"
import ScrollToTop from "./components/custom/ScrollToTop"
import SucceededPayment from "./screens/paying bill/SucceededPayment"

import Hotjar from "@hotjar/browser"
import BillsInfo from "./screens/payingBillInfo"
import PayingBillInfo from "./screens/payingBillInfo/PayingBillInfo"
import SplitBillInfo from "./screens/payingBillInfo/SplitBillInfo"
import PaymentFormInfo from "./screens/payingBillInfo/PaymentFormInfo"
import SucceededPaymentInfo from "./screens/payingBillInfo/SucceededPaymentInfo"
import SideMenu from "./screens/sideMenu"
import FAQSUPPORTS from "./components/TermsAndConditions/FAQS"
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions"
import BookingInfo, {
  AvailabilityInfo,
  CalenderInfo,
  ConfirmInfo,
  InformationInfo,
} from "./screens/bookingInfo"

const LoggedInContext = createContext()

const queryClient = new QueryClient()
moment.tz.setDefault("Europe/London")

const HOTJAR_ENABLED = process.env.REACT_APP_HOTJAR_ENABLED

if (HOTJAR_ENABLED) {
  const HOTJAR_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID
  const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION
  Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION)
}

function App() {
  const [user, setUser] = useState(null)
  const [Loggedin, setLoggedin] = useState(
    localStorage.getItem("token") !== null ? true : false,
  )
  const [loading, setLoading] = useState(true)
  const ENV = process.env.REACT_APP_ENVIRONMENT
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (metaThemeColor) {
      if (ENV === "production") {
        metaThemeColor.setAttribute("content", "#18243a")
      } else {
        metaThemeColor.setAttribute("content", "#EE4B2B")
      }
    }
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        // Get the user role from Firestore
        // Assuming you have the `users` collection in Firestore
        const getUserRole = async () => {
          // Fetch the user document from Firestore
          const userDocRef = doc(db, "users", currentUser.uid)
          const userDocSnap = await getDoc(userDocRef)

          if (userDocSnap.exists()) {
            // Set the user object with the role field
            setUser({ ...currentUser, role: userDocSnap.data().role })
          } else {
            // User document not found, set the user object without the role field
            setUser(currentUser)
          }
        }

        getUserRole().then(() => setLoading(false))
      } else {
        setUser(null)
        setLoading(false)
      }
    })

    return () => unsubscribe()
  }, [])

  if (loading) return null

  return (
    <Router>
      <div className="text-white min-h-screen bg-color-customblue app">
        <ScrollToTop />
        <TestTag />
        <LoggedInContext.Provider value={{ Loggedin, setLoggedin }}>
          <UserContext.Provider value={{ user, setUser }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <QueryClientProvider client={queryClient}>
                <Routes>
                  <Route path="/" element={<PublicRoute />}>
                    <Route index element={<Home />} />
                    <Route path="/tos" element={<FAQ />} />
                    <Route path="/rewards" element={<Rewards />} />
                    <Route path="/login/:tenantId" element={<Login />} />
                    <Route
                      path="/login/:tenantId/:subtenantId"
                      element={<Login />}
                    />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="*" element={<Home />} />
                  </Route>
                  <Route path="/booking/:restaurant" element={<Booking />}>
                    <Route index element={<Calender />} />
                    <Route
                      path="/booking/:restaurant/availability"
                      element={<Availability />}
                    />
                    <Route
                      path="/booking/:restaurant/information"
                      element={<Information />}
                    />
                    <Route
                      path="/booking/:restaurant/confirm"
                      element={<Confirm />}
                    />
                  </Route>
                  <Route
                    path="/booking-info/:tenantId"
                    element={<BookingInfo />}
                  >
                    <Route index element={<CalenderInfo />} />
                    <Route
                      path="/booking-info/:tenantId/availability"
                      element={<AvailabilityInfo />}
                    />
                    <Route
                      path="/booking-info/:tenantId/information"
                      element={<InformationInfo />}
                    />
                    <Route
                      path="/booking-info/:tenantId/confirm"
                      element={<ConfirmInfo />}
                    />
                  </Route>
                  <Route
                    path="/booking-info/:tenantId/:subtenantId"
                    element={<BookingInfo />}
                  >
                    <Route index element={<CalenderInfo />} />
                    <Route
                      path="/booking-info/:tenantId/:subtenantId/availability"
                      element={<AvailabilityInfo />}
                    />
                    <Route
                      path="/booking-info/:tenantId/:subtenantId/information"
                      element={<InformationInfo />}
                    />
                    <Route
                      path="/booking-info/:tenantId/:subtenantId/confirm"
                      element={<ConfirmInfo />}
                    />
                  </Route>
                  <Route
                    path="/modify-reservation/:restaurant/:bookingID"
                    element={<ModifyReservation />}
                  >
                    <Route index element={<ModifyInformation />} />
                    <Route
                      path="/modify-reservation/:restaurant/:bookingID/calender"
                      element={<ModifyCalender />}
                    />
                    <Route
                      path="/modify-reservation/:restaurant/:bookingID/availability"
                      element={<ModifyAvailability />}
                    />
                    <Route
                      path="/modify-reservation/:restaurant/:bookingID/cancelled"
                      element={<Cancelled />}
                    />
                  </Route>
                  <Route
                    path="/reservations/:tenantId/:bookingID"
                    element={<Reservations />}
                  >
                    <Route index element={<GuestInformation />} />
                    <Route
                      path="/reservations/:tenantId/:bookingID/confirmed"
                      element={<GuestConfirm />}
                    />
                  </Route>
                  <Route
                    path="/reservations/:tenantId/:subtenantId/:bookingID"
                    element={<Reservations />}
                  >
                    <Route index element={<GuestInformation />} />
                    <Route
                      path="/reservations/:tenantId/:subtenantId/:bookingID/confirmed"
                      element={<GuestConfirm />}
                    />
                  </Route>
                  <Route
                    path="/modify-payment/:restaurant/:bookingID/"
                    element={<ModifyPayment />}
                  />
                  <Route path="/bill/:reservationID" element={<Bills />}>
                    <Route index element={<PayingBill />} />
                    <Route
                      path="/bill/:reservationID/split"
                      element={<SplitBill />}
                    />
                    <Route
                      path="/bill/:reservationID/card"
                      element={<PaymentForm />}
                    />
                    <Route
                      path="/bill/:reservationID/succeeded"
                      element={<SucceededPayment />}
                    />
                  </Route>
                  <Route
                    path="/bill-info/:reservationID"
                    element={<BillsInfo />}
                  >
                    <Route index element={<PayingBillInfo />} />
                    <Route
                      path="/bill-info/:reservationID/split"
                      element={<SplitBillInfo />}
                    />
                    <Route
                      path="/bill-info/:reservationID/card"
                      element={<PaymentFormInfo />}
                    />
                    <Route
                      path="/bill-info/:reservationID/succeeded"
                      element={<SucceededPaymentInfo />}
                    />
                  </Route>
                  <Route path="/menu" element={<MenuPage />} />
                  <Route path="/sidemenu" element={<SideMenu />} />
                  <Route path="/faq-support" element={<FAQSUPPORT />} />
                  <Route path="/toc" element={<TermsAndConditions />} />
                  <Route path="/faq-supports" element={<FAQSUPPORTS />} />
                  <Route
                    path="/admin/:tenantId/*"
                    element={<AdminDashboard />}
                  />
                </Routes>
                <ToastContainer
                  position={"bottom-right"}
                  autoClose={2000}
                  hideProgressBar={false}
                  closeOnClick={true}
                  draggable={true}
                  progress={undefined}
                  theme={"dark"}
                />
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
              </QueryClientProvider>
            </LocalizationProvider>
          </UserContext.Provider>
        </LoggedInContext.Provider>
      </div>
    </Router>
  )
}

export { App, LoggedInContext }
