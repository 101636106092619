import moment from "moment-timezone"
import DetermineHostname from "../../configs/DetermineHostname"

moment.tz.setDefault("Europe/London")

const fetchRestaurantInfo = async (tenantId, subtenantId) => {
  let url = `${DetermineHostname().hostV2}restaurant/info?tenantId=${tenantId}`
  if (subtenantId) {
    url += `&subtenantId=${subtenantId}`
  }
  const response = await fetch(url)
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const confirmReservationV2 = async ({
  // restaurantID,
  dateTime,
  guestsCount,
  firstName,
  lastName,
  guestPhone,
  guestEmail,
  stripePaymentID,
  acceptPolicy,
  acceptRestaurantMails,
  acceptZumiMails,
  customerID,
  tenantId,
  subtenantId,
}) => {
  const day = dateTime.date()
  const month = dateTime.month()
  const hour = dateTime.hour()
  const minute = dateTime.minute()
  const response = await fetch(
    `${DetermineHostname().hostV2}reservationV2/confirm-reservation/`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        // restaurantID,
        dateTime: dateTime.format(),
        guestsCount,
        firstName,
        lastName,
        guestPhone,
        guestEmail,
        stripePaymentID,
        acceptPolicy,
        acceptRestaurantMails,
        acceptZumiMails,
        hour,
        minute,
        month,
        day,
        customerID,
        tenantId,
        ...(subtenantId && { subtenantId }),
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const editReservationV2 = async (reservationData) => {
  try {
    const response = await fetch(
      DetermineHostname().hostV2 + "reservationV2/edit-reservation/",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(reservationData),
      },
    )
    const data = await response.json()
    if (response.ok) {
      return data
    } else throw new Error(data?.message)
  } catch (error) {
    throw error
  }
}

const cancelReservationV2 = async (bookingID) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}reservationV2/cancel-reservation/${bookingID}`,
    { method: "PATCH" },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const updateReservationV2 = async ({ dateTime, reservationID }) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "reservationV2/update-reservation/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
        dateTime,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const savePreferencesV2 = async (
  NewBookingID,
  occasion,
  restrictions,
  requests,
) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "reservationV2/save-preferences/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        NewBookingID: NewBookingID.toString(),
        occasion,
        restrictions,
        requests,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const fetchAvailableTimesV2 = async (
  dateTime,
  guestsCount,
  tenantId,
  subtenantId,
) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "reservationV2/available-times/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        date: dateTime,
        guestsCount: guestsCount,
        tenantId,
        ...(subtenantId && { subtenantId }),
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const getOccasions = async (tenantId, subtenantId) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}restaurant/get-occasions?id=${subtenantId ?? tenantId}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

// remaining from backend

const phoneLookUp = async ({ guestPhone }) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}reservationV2/phone-lookup`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          guestPhone,
        }),
      },
    )
    const data = await response.json()
    if (response.ok) {
      return data
    } else throw new Error(data?.message)
  } catch (error) {
    console.error(error)
  }
}

// const fetchRestaurantInfoByReservationID = async (bookingID) => {
//   const response = await fetch(
//     `${DetermineHostname().hostname}reservations/restaurant-info-by-reservation-id/${bookingID}`,
//   )
//   const data = await response.json()
//   if (response.ok) {
//     return data
//   } else throw new Error(data?.message)
// }

const fetchReservationInfo = async (bookingID) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "reservationV2/reservation-info/" + bookingID,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const fetchReservationOwnerPayments = async (bookingID) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "reservationV2/owner-payments/" + bookingID,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const addNewReservationPayment = async ({
  bookingID,
  paymentID,
  isDefault,
}) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "reservationV2/add-payments/" + bookingID,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paymentID,
        isDefault,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const setDefaultPayment = async ({ reservationID, customerID, paymentID }) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}reservationV2/set-default-payment/${reservationID}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          paymentID,
          customerID,
        }),
      },
    )
    const data = await response.json()
    if (response.ok) {
      return data
    } else throw new Error(data?.message)
  } catch (error) {
    throw error
  }
}

const setHolderDefaultPayment = async ({ bookingID, paymentID }) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}reservationV2/set-holder-default-payment/${bookingID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paymentID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const confirmInvitation = async ({ bookingID, email, name, phone }) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}reservationV2/confirm-invitation/${bookingID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email,
        name,
        phone,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const saveGuestPreferences = async ({
  NewBookingID,
  email,
  requests,
  restrictions,
  occasion,
}) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "reservationV2/save-guest-preferences",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        NewBookingID,
        email,
        requests,
        restrictions,
        occasion,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

// const getGuestsNumber = async (restaurantID) => {
//   const response = await fetch(
//     DetermineHostname().hostname + `reservations/guests-number/${restaurantID}`,
//     {
//       method: "GET",
//       headers: {
//         "content-type": "application/json",
//       },
//     },
//   )
//   const data = await response.json()
//   if (response.ok) {
//     return data
//   } else throw new Error(data?.message)
// }

const getClientSecret = async ({ guestEmail, guestName, customerID }) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "reservationV2/client-secret",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        guestEmail,
        guestName,
        customerID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const deleteCard = async ({ paymentID, isDefault }) => {
  try {
    const response = await fetch(
      DetermineHostname().hostV2 + "reservationV2/remove-payment",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          paymentID,
          isDefault,
        }),
      },
    )
    const data = await response.json()
    if (response.ok) {
      return data
    } else throw new Error(data?.message)
  } catch (error) {
    console.error(error)
  }
}

export {
  fetchRestaurantInfo,
  confirmReservationV2,
  editReservationV2,
  cancelReservationV2,
  updateReservationV2,
  savePreferencesV2,
  fetchAvailableTimesV2,
  getOccasions,
  phoneLookUp,
  // fetchRestaurantInfoByReservationID,
  fetchReservationInfo,
  fetchReservationOwnerPayments,
  addNewReservationPayment,
  setDefaultPayment,
  setHolderDefaultPayment,
  confirmInvitation,
  saveGuestPreferences,
  // getGuestsNumber,
  getClientSecret,
  deleteCard,
}
