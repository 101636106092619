import DetermineHostname from "../../configs/DetermineHostname"
import { getSubtenantId } from "../../utils/helperFunctions"

const updateBlinq = async (email, password, blinkme_api_pass, tenantId) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}settingsV2/update-blinq`,
    {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        blinkme_api_pass: blinkme_api_pass,
        tenantId: tenantId,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const updateEtb = async (
  email,
  password,
  id,
  etb_restaurant_id,
  tenantId,
  subtenantId,
) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}settingsV2/update-etb`,
    {
      method: "PATCH",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        etb_id: id,
        etb_restaurant_id: etb_restaurant_id,
        tenantId: tenantId,
        ...(subtenantId && { subtenantId }),
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const updateSettings = async (tenantId, settings, subtenantId = null) => {
  const keys = [
    "max_payment",
    "max_splits",
    "active",
    "backup_url",
    "bill_sms_status",
    "widget",
    "api",
    "walkin",
    "other",
    "guest_sms_lock",
    "charge",
    "enable_email",
    "emergency_shutoff",
  ]
  const payload = keys.reduce((acc, key) => {
    if (Object.prototype.hasOwnProperty.call(settings, key))
      acc[key] = settings[key]
    return acc
  }, {})
  payload.tenantId = tenantId
  if (subtenantId) {
    payload.subtenantId = getSubtenantId(subtenantId)
  }
  const url = `${DetermineHostname().hostV2}settingsV2/update-settings`
  const response = await fetch(url, {
    method: "PATCH",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(payload),
  })

  const data = await response?.json()
  if (response.ok) {
    return data
  } else {
    console.error(data?.message)
    return false
  }
}
const fetchRestaurantById = async (tenantId, subtenantId) => {
  let url = `${DetermineHostname().hostV2}restaurant/detail?tenantId=${tenantId}`
  let subtenantID = getSubtenantId(subtenantId)
  if (subtenantID) {
    url += `&subtenantId=${subtenantID}`
  }
  const response = await fetch(url)
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const getBillByTableAPI = async (tableID) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}settingsV2/get-bill-by-table/${tableID}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const getTablesData = async (tables) => {
  const response = await fetch(
    DetermineHostname().hostV2 + "settingsV2/get-bills-by-tables",
    {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify({ tables }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const rsyncReservations = async ({
  sevenrooms_venue_id,
  from_date,
  to_date,
  tenantId,
  subtenantId = null,
}) => {
  const body = {
    sevenrooms_venue_id,
    from_date,
    to_date,
  }
  if (getSubtenantId(subtenantId)) {
    body.subtenantId = getSubtenantId(subtenantId)
  } else {
    body.tenantId = tenantId
  }
  const response = await fetch(
    `${DetermineHostname().hostV2}settingsV2/rsync-reservations/`,
    {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: JSON.stringify(body),
    },
  )
  const data = await response?.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const getStats = async ({ tenantId, subtenantId, startDate, endDate }) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}settingsV2/stats`,
    {
      method: "POST",
      headers: { "content-type": "application/json" },
      body: subtenantId
        ? JSON.stringify({ tenantId, subtenantId, startDate, endDate })
        : JSON.stringify({ tenantId, startDate, endDate }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else {
    return data
  }
}

export {
  updateBlinq,
  updateEtb,
  updateSettings,
  fetchRestaurantById,
  getBillByTableAPI,
  getTablesData,
  rsyncReservations,
  getStats,
}
