import DetermineHostname from "../configs/DetermineHostname"
import axios from "axios"

const adminSignIn = async (email, password) => {
  return axios({
    method: "post",
    url: DetermineHostname().hostV2 + "userV2/login",
    data: {
      email: email,
      password: password,
    },
  })
}

export { adminSignIn }
