import React from "react"

const CuttedHorizontalLineNew = ({ color }) => {
  const dividerStyle = {
    borderBottom: `1px dashed ${color || "#C3C4CC"}`,
    width: "100%",
  }

  return <div style={dividerStyle}></div>
}

export default CuttedHorizontalLineNew
