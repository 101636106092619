import React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import {
  FilterIcon,
  NextIcon,
  PreviousIcon,
  SortIcon,
} from "../../components/custom/IconsDashBoard"
import { MobileDatePicker } from "@mui/x-date-pickers"
import { useContext } from "react"
import { SelectedDateContext } from "../pages/home"
import SelectedSortContext from "../context/SortContext"
import { useNavigate, useParams } from "react-router"
import "./../styles/styles.css"
import { getSubtenantId } from "../../utils/helperFunctions"

export default function TableComponet({
  hasSearch,
  data,
  secondColData,
  extraClassName,
  title,
  headers,
  navRoot = "",
  hasDateFilter = false,
  verticalHeader = false,
}) {
  const selectedDateObj = useContext(SelectedDateContext)
  const navigate = useNavigate()
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params["*"])

  const { selectedDate, setSelecedDate } = selectedDateObj
    ? selectedDateObj
    : {}
  const { selectedSort, setSelectedSort } = useContext(SelectedSortContext)
  const handleSort = (selectedColumn) => {
    let filter = " "
    if (selectedColumn === selectedSort?.filter) {
      filter =
        selectedColumn[0] === "-"
          ? selectedColumn.substr(1)
          : "-" + selectedColumn
    } else filter = selectedColumn
    setSelectedSort({
      filter: filter,
      table: title,
    })
  }
  const handleOpen = (id) => {
    navigate(
      `/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${navRoot}/${id}`,
    )
  }
  const addDays = (val) => {
    const newDate = new Date(selectedDate?.date)
    newDate.setDate(newDate.getDate() + val)
    setSelecedDate({ date: newDate.toDateString(), table: title })
  }
  return (
    <>
      {(title || hasDateFilter || hasSearch) && (
        <div className="grid grid-cols-1 md:grid-cols-2 p-4">
          <div className="justify-self-start flex items-center flex-nowrap">
            {title && (
              <p
                className="me-3"
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "Mulish",
                  fontWeight: 600,
                  lineHeight: "22px",
                  wordWrap: "break-word",
                }}
              >
                {title}
              </p>
            )}

            {hasDateFilter && (
              <>
                <button
                  className="bg-[#F4F4F5] p-2 text-3xl"
                  onClick={() => addDays(-1)}
                >
                  <PreviousIcon />
                </button>
                <MobileDatePicker
                  onChange={(val, _context) => {
                    setSelecedDate({ date: val, table: title })
                  }}
                  value={new Date(selectedDate?.date)}
                  closeOnSelect={true}
                  className="hideBorder w-[120px]"
                  slotProps={{
                    textField: {
                      size: "small",
                      style: { borderColor: " white" },
                    },
                  }}
                />
                <button
                  className="bg-[#F4F4F5] p-2 text-3xl"
                  onClick={() => addDays(1)}
                >
                  <NextIcon />
                </button>
              </>
            )}
          </div>
          {hasSearch && (
            <div className="justify-self-end flex">
              <div className="relative hidden sm:block">
                <label className="sr-only" htmlFor="search">
                  {" "}
                  Search{" "}
                </label>

                <input
                  className="h-10 w-full bg-[#ECECEC] rounded-2xl border-none bg-white ps-10 ps-4 text-sm shadow-sm sm:w-56 mx-5"
                  type="search"
                  placeholder="Search."
                />

                <button
                  type="button"
                  className="absolute start-1 top-5 -translate-y-1/2 rounded-md bg-transparent p-3 text-gray-600 transition hover:text-gray-700"
                >
                  <span className="sr-only">Search</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 mx-1"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
              <button className="flex items-center mx-2 ">
                {" "}
                <span className="text-[#C5C7CD] flex items-center mx-2">
                  <SortIcon />
                </span>{" "}
                Sort
              </button>
              <button className="flex items-center mx-2 ">
                {" "}
                <span className="text-[#C5C7CD] flex items-center mx-2">
                  <FilterIcon />
                </span>
                Filter
              </button>
            </div>
          )}
        </div>
      )}
      {verticalHeader ? (
        <TableContainer component={Paper} className={extraClassName}>
          <Table stickyHeader className="z-10" aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={"w-[25%] min-w-[250px]"}>
                  Headers
                </TableCell>
                <TableCell>Data</TableCell>
                <TableCell>%</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {headers.map((head, index) => (
                <TableRow key={index}>
                  <TableCell
                    className="hover-row"
                    style={{
                      color: "#9FA2B4",
                      fontSize: "14px",
                      fontFamily: "Mulish",
                      fontWeight: 700,
                      letterSpacing: "0.20px",
                      wordWrap: "break-word",
                    }}
                  >
                    {head.name}
                  </TableCell>
                  <TableCell>{data[0] && data[0][head.key]}</TableCell>
                  <TableCell>
                    {secondColData?.[0] ? secondColData[0][head.key] : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableContainer component={Paper} className={extraClassName}>
          <Table stickyHeader className="z-10" aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers &&
                  headers.map((head) => {
                    return (
                      <TableCell
                        className="hover-row"
                        style={{
                          color: "#9FA2B4",
                          fontSize: "14px",
                          fontFamily: "Mulish",
                          fontWeight: 700,
                          letterSpacing: "0.20px",
                          wordWrap: "break-word",
                        }}
                        key={head.name}
                        onClick={() => handleSort(head.key)}
                      >
                        {head.name}
                      </TableCell>
                    )
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, rowNumber) => (
                <TableRow
                  onClick={() => !!navRoot && handleOpen(row.id)}
                  className="hover-row"
                  key={rowNumber}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {headers.map((col) => (
                    <TableCell
                      {...(col.props || {})}
                      key={col.key + "row" + rowNumber}
                    >
                      {col.renderContent
                        ? col.renderContent(row, col, { data, rowNumber })
                        : row[col.key]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  )
}
