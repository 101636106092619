import React from "react"
import "../../styles/style.css"
const ContactUs = () => {
  return (
    <div className="faq-contact-div">
      <div className="faq-zumi-text">Powered by Zumi</div>
      <div className="faq-zumi-desc">
        If you require support or would like to reach out to us direct for any
        reason, please click contact us below.
      </div>
      <div className="faq-contact-btn">
        <a href="mailto:info@zumi.app" className="faq-contact-btn-text">
          Contact us
        </a>
      </div>
    </div>
  )
}

export default ContactUs
