import React, { useParams, Outlet, useLocation } from "react-router-dom"
import GoBack from "../../components/custom/Goback"
import Calender from "./Calender"
import Availability from "./Availability"
import Information from "./Information"
import Confirm from "./Confirm"
import { useQuery } from "@tanstack/react-query"
import { Skeleton } from "@mui/material"
import { fetchRestaurantInfo, getGuestsNumber } from "../../api/reservations"
import { useEffect } from "react"
import MuiFontTheme from "../../components/custom/MuiTheme"

const Booking = () => {
  const { restaurant } = useParams()
  const location = useLocation()
  const isConfirmInPath = location.pathname.includes("confirm")

  const { data, isLoading, isError } = useQuery({
    queryKey: [restaurant],
    queryFn: () => fetchRestaurantInfo(restaurant),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  const {
    data: guestsNumber,
    isLoading: isLoadingGuest,
    isError: isErrorGuest,
  } = useQuery({
    queryKey: ["guestsNumber", restaurant],
    queryFn: () => getGuestsNumber(restaurant),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  useEffect(() => {
    const handleBeforeUnload = () => {
      window.history.replaceState({}, document.title)
    }

    window.addEventListener("beforeunload", handleBeforeUnload)

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])
  if (isError || isErrorGuest)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          Resturant Not Found
        </h1>
      </div>
    )

  if (isLoading || isLoadingGuest) return

  return (
    <MuiFontTheme font={data?.main_font ?? ""}>
      <div
        className={`relative h-full flex justify-center pt-0`}
        style={{ fontFamily: data?.main_font ?? "" }}
        id="booking"
      >
        <div className="w-full lg:max-w-[50%] relative">
          {!isConfirmInPath && (
            <div className="absolute top-[32px] left-[10px]">
              <GoBack />
            </div>
          )}
          <div className="w-screen lg:ml-[-50%] flex justify-center h-[131px] text-center text-black text-[40px] font-extrabold leading-[60px] capitalize bg-[#f0e6cd]">
            {isLoading || isLoadingGuest ? (
              <Skeleton
                animation="wave"
                className="w-[100px] rounded-xl !mt-4"
              />
            ) : (
              <img
                className="max-w-[170px] h-[66px] mt-[10px]"
                src={data.logo}
                alt="logo"
              />
            )}
          </div>
          {isLoading || isLoadingGuest ? (
            <Skeleton animation="wave" className="!mt-[50px]  !h-[60vh]" />
          ) : (
            <Outlet context={{ restaurantData: data, guestsNumber }} />
          )}
        </div>
      </div>
    </MuiFontTheme>
  )
}

export default Booking

export { Calender, Availability, Information, Confirm }
