import React from "react"
import ReactDOM from "react-dom/client"
import "react-toastify/dist/ReactToastify.css"
import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

import "./index.css"
import { App } from "./App"

const container = document.getElementById("root")
const root = ReactDOM.createRoot(container)

root.render(<App />)
