import React from "react"
import Card from "@mui/material/Card"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import StatusComponent from "../../components/StatusComponent"
import { Box } from "@mui/system"
import GuestDetails from "./GuestDetails"
import PaymentDetails from "./PaymentDetails"
import { useNavigate, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import moment from "moment"
import { getBillInfo } from "../../../api/v2/bills"

function CheckSingle({ restaurantData }) {
  const params = useParams()
  const checkId = params.id
  const navigate = useNavigate()
  const goHome = () => {
    navigate(window.location.pathname.replace(`/${checkId}`, ""))
  }

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["reservationData"],
    queryFn: () => getBillInfo(checkId),
    retry: false,
  })

  if (isLoading) return null
  if (isError)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          Check Not Found
        </h1>
      </div>
    )
  return (
    <>
      <span
        className={
          "text-[#252733] text-[19px] font-bold cursor-pointer font-[Mulish] ml-4"
        }
        onClick={() => goHome()}
      >
        <ArrowBackIosIcon color="action" /> All Checks
      </span>
      {/*Card Deails */}
      <Card sx={{ flexDirection: "row", p: 2, m: 2 }}>
        <span>
          check {checkId} <StatusComponent status={data?.status} />
        </span>
        <Box sx={{ m: 2, display: "flex", justifyContent: "space-around" }}>
          <span>{`${data?.firstName} ${data?.lastName}`}</span>
          <span>{moment(data?.dateTime).format("YYYY-MM-DD | HH:MM")}</span>
          <span>{data?.guestsCount} Guests</span>
        </Box>
      </Card>
      <GuestDetails data={data ? [{ ...data, ...restaurantData }] : []} />
      <PaymentDetails
        data={data ? [{ ...data, ...restaurantData }] : []}
        refetchReservation={refetch}
      />
    </>
  )
}

export default CheckSingle
