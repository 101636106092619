import React from "react"

const TestTag = () => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    return null
  }

  return (
    <div className="fixed top-0 left-0 bg-red-800 text-white px-2 py-1 rounded-br-lg">
      Test Environment
    </div>
  )
}

export default TestTag
