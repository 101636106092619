import { React, useState } from "react"
import Card from "@mui/material/Card"
import TableComponet from "../../components/tableComponent"

function GuestDetails({ data }) {
  const guestsHeaders = [
    { id: 1, name: "First Name", key: "firstName", sortable: true },
    { id: 2, name: "Last Name", key: "lastName", sortable: true },
    { id: 3, name: "Customer Email", key: "guestEmail", sortable: true },
    { id: 4, name: "Phone number", key: "guestPhone", sortable: true },
  ]

  return (
    <>
      {" "}
      <Card sx={{ flexDirection: "row", p: 2, m: 2 }}>
        <span>
          <TableComponet
            hasSearch={false}
            hasSortFilter={false}
            hasDateFilter={false}
            headers={guestsHeaders}
            data={data}
            title="Guest details  "
            //  extraClassName='h-[390px]'
          />
        </span>
      </Card>
    </>
  )
}

export default GuestDetails
