import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext, useParams } from "react-router"
import moment from "moment-timezone"
import { addMonths, isBefore, isMonday, isTuesday } from "date-fns"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import "./bookingStyle.css"
import CustomFontComponent from "../../components/custom/CustomFontComponent"

moment.tz.setDefault("Europe/London")

const CalenderInfo = () => {
  const [guestCount, setGuestCount] = useState(2)
  const [showCalender, setShowCalender] = useState(false)
  const [showClock, setShowClock] = useState(false)
  const [selectedTime, setselectedTime] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const today = new Date()
  const maxDate = addMonths(today, 2)
  const nav = useNavigate()
  const { tenantId, subtenantId } = useParams()
  const [showTodayText, setShowTodayText] = useState(false)
  const { guestsNumber, brandData, restaurantData } = useOutletContext()
  // Define disabled days and past dates
  const isDateDisabled = (date) => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0) // Normalize the time to midnight to avoid timezone issues
    date.setHours(0, 0, 0, 0) // Normalize the time to midnight to avoid timezone issues
    return isBefore(date, currentDate) || isMonday(date) || isTuesday(date)
  }
  // Determine initial date
  const getInitialDate = () => {
    const dayOfWeek = today.getDay()
    return dayOfWeek === 1 || dayOfWeek === 2
      ? new Date(today.setDate(today.getDate() + (3 - dayOfWeek))) // Set to Wednesday
      : today
  }

  const generateTimeSlots = (minTime, maxTime, interval) => {
    const slots = []
    let currentTime = moment(minTime, "HH:mm")
    const endTime = moment(maxTime, "HH:mm")
    while (currentTime <= endTime) {
      slots.push(currentTime.format("HH:mm"))
      currentTime.add(interval, "minutes")
    }
    return slots
  }

  useEffect(() => {
    const initialDate = getInitialDate()
    handleDateChange(initialDate)
  }, [])

  const onIncrease = () => {
    if (guestCount + 1 <= guestsNumber) setGuestCount(guestCount + 1)
  }
  const onDecrease = () => {
    if (guestCount > 1) setGuestCount(guestCount - 1)
  }
  const toggleCalenderView = () => setShowCalender(!showCalender)
  const toggleClockView = () => setShowClock(!showClock)

  const handleSearch = () => {
    const selectedDateTime = moment(selectedDate).set({
      hour: selectedTime ? moment(selectedTime, "HH:mm").hour() : 12,
      minute: selectedTime ? moment(selectedTime, "HH:mm").minute() : 30,
    })
    nav(
      `/booking-info/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/availability`,
      {
        state: {
          selectedDateTime: selectedDateTime.format(),
          guestCount,
        },
      },
    )
  }

  const getMinTime = () => {
    if (
      (moment(selectedDate).day() >= 3 && moment(selectedDate).day() <= 6) ||
      moment(selectedDate).day() == 0
    ) {
      // Wednesday to Saturday
      return "12:00"
    } else {
      return null // Closed for Monday and Tuesday
    }
  }

  const getMaxTime = () => {
    if (moment(selectedDate).day() >= 3 && moment(selectedDate).day() <= 6) {
      // Wednesday to Saturday
      return "21:30"
    } else if (moment(selectedDate).day() === 0) {
      // Sunday
      return "21:00"
    } else {
      return null // Closed for Monday and Tuesday
    }
  }

  const handleDateChange = (newDate) => {
    const normalizedDate = new Date(
      Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()),
    )
    setSelectedDate(normalizedDate)
    setShowTodayText(normalizedDate?.toDateString() === today?.toDateString())
    setShowCalender(false)
  }

  const minTime = getMinTime()
  const maxTime = getMaxTime()
  const timeSlots =
    minTime && maxTime ? generateTimeSlots(minTime, maxTime, 30) : []

  return (
    <CustomFontComponent fontFamily={brandData?.data?.font ?? ""}>
      <div className="calender-div">
        <div className="calender-top-section">
          <div className="guest-cale">Guests</div>
          <div className="guest-button-div">
            <p onClick={onDecrease} className="guest-btn-text">
              -
            </p>
            <p className="w-3">{guestCount}</p>
            <p onClick={onIncrease} className="guest-btn-text">
              +
            </p>
          </div>
        </div>
        <div className="calender-seperator" />
        <div className="calender-top-section">
          <div className="guest-cale">Date</div>
          <div className="guest-button-div" onClick={toggleCalenderView}>
            {showTodayText
              ? "Today"
              : moment(selectedDate).format("ddd, MMM D")}
          </div>
        </div>
        {showCalender && (
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileDisabled={({ date }) => isDateDisabled(date)}
            maxDate={maxDate}
            minDate={today}
          />
        )}
        <div className="calender-seperator" />
        <div className="calender-top-section">
          <div className="guest-cale">Time</div>
          <div className="guest-button-div" onClick={toggleClockView}>
            {selectedTime ? selectedTime.format("HH:mm") : "All Day"}
          </div>
        </div>
        {showClock && timeSlots.length > 0 && (
          <div className="clock-view">
            {timeSlots.map((time, index) => (
              <div
                key={index}
                onClick={() => {
                  setselectedTime(moment(time, "HH:mm"))
                  setShowClock(false)
                }}
                className={`clock-time ${
                  selectedTime && selectedTime.format("HH:mm") === time
                    ? "bg-color-dark text-white"
                    : "bg-color-body-default"
                }`}
              >
                {time}
              </div>
            ))}
          </div>
        )}
        <div className="search-btn-div">
          <button onClick={handleSearch} className="btn-dark-default">
            Search
          </button>
        </div>
        <div className="color-center-style">
          To book for more than 6 people <br />
          contact us directly.
          <div>
            <a
              href={`tel:${restaurantData?.phone_number}`}
              className="underline-text"
            >
              T: {restaurantData?.phone_number}
            </a>
            <br />
            <a
              href={`mailto:${restaurantData?.email}`}
              className="underline-text"
            >
              E: {restaurantData?.email}
            </a>
          </div>
        </div>
      </div>
    </CustomFontComponent>
  )
}

export default CalenderInfo
