import { useState } from "react"
import { Button, Collapse, List, ListItemButton } from "@mui/material"

function HideList({ list }) {
  const [open, setOpen] = useState(false)

  const handleOpenList = () => {
    setOpen(!open)
  }

  return (
    <List component="nav" className="relative">
      <Button onClick={handleOpenList} variant="outlined">
        Hide List
      </Button>
      <Collapse
        in={open}
        timeout="auto"
        className="absolute right-0 w-max bg-white shadow-md z-10"
        unmountOnExit
      >
        <List>
          {list.map((button, index) => (
            <ListItemButton
              key={index}
              onClick={() => button.onClick(!button.active)}
              style={
                button.active ? { backgroundColor: "blue", color: "white" } : {}
              }
            >
              {button.label}
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </List>
  )
}

export default HideList
