import React from "react"
import PropTypes from "prop-types"
import {
  Elements,
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"

const CheckoutForm = ({ onConfirm }) => {
  const stripe = useStripe()
  const elements = useElements()

  if (!stripe || !elements) return null

  return (
    <ExpressCheckoutElement
      onConfirm={() => onConfirm(elements, stripe)}
      options={{
        buttonType: { googlePay: "plain", applePay: "plain" },
        wallets: { googlePay: "always", applePay: "always" },
        buttonHeight: 50,
        buttonTheme: { googlePay: "white", applePay: "white-outline" },
        layout: {
          maxRows: 1,
          maxColumns: 1,
        },
      }}
    />
  )
}

CheckoutForm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
}

export default function StripeCheckoutForm({ onConfirm, amount }) {
  loadStripe.setLoadParameters({ advancedFraudSignals: false })
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        currency: "gbp",
        amount,
        appearance: {
          variables: {
            borderRadius: "78px",
          },
        },
      }}
    >
      <CheckoutForm onConfirm={onConfirm} />
    </Elements>
  )
}

StripeCheckoutForm.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
}
