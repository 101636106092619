import React, { useEffect, useState } from "react"
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Alert, CircularProgress, Snackbar } from "@mui/material"
import HorizontalLine from "../../components/custom/HorizontalLine"
import currencies from "../../utils/currencies.json"

import {
  createPaymentIntent,
  increaseGuestsCount,
  splitBill,
  splitBillApplePayment,
  payInSplit3DS,
  updateSvrReservation,
  addChargeToSvr,
  addPaymentFailure,
  addStripePaymentLog,
  getPaymentMethod,
} from "../../api/bills"
import { toast } from "react-toastify"
import { Elements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import moment from "moment"
import { fetchReservationOwnerPayments } from "../../api/reservations"
import PaymentForm from "./PaymentForm"
import SwipeButton from "../../components/custom/SwipeButton"
import CompletePaymentLoading from "../../components/custom/CompletePaymentLoading"
import { CardIcon } from "../modify payment"
import cn from "classnames"
import StripeCheckoutForm from "../../components/CheckoutForm"
import ChangePaymentMethod from "./ChangePaymentMethod"
import { calcAmountInPence } from "../../utils/helperFunctions"

loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

const SplitBillComponent = ({
  setSomeoneElse,
  anotherPayment,
  setAnotherPayment,
  setIsWalletConfirming,
}) => {
  const stripe = useStripe()
  const [showModal, setShowModal] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState()
  const [isLoadingModal, setIsLoadingModal] = useState(true)
  const [isChange, setIsChange] = useState(false)

  const {
    invoiceData,
    isInvited,
    guestID,
    setStatusPaid,
    invoiceID,
    setShowPaymentBtn,
    guestsNumber,
    setPortionPaid,
  } = useOutletContext()
  const { reservationID } = useParams()
  const nav = useNavigate()
  const [guestsCount, setGuestsCount] = useState(
    Number(invoiceData.guestsCount),
  )

  const currencySymbol = Object.values(currencies).find(
    (c) => c.code.toLowerCase() === invoiceData.currency.toLowerCase(),
  )?.symbol

  const [payingCount, setpayingCount] = useState(1)
  const [disableGuestsCounter, setDisabledGuestsCounter] = useState(
    !!invoiceData?.guestsCountUpdated,
  )
  const [isConfirmed, setisConfirmed] = useState(false)
  const [totalShare, settotalShare] = useState(
    invoiceData.price / invoiceData.guestsCount,
  )
  const [snackbarVisisble, setSnackbarVisisble] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [amountInPound, setAmountInPound] = useState(
    calcAmountInPence(invoiceData, payingCount, guestsCount) / 100,
  )
  const [amountInPence, setAmountInPence] = useState(
    calcAmountInPence(invoiceData, payingCount, guestsCount),
  )
  const [searchParams] = useSearchParams()
  const second = searchParams.has("second")
  const { data, isLoading } = useQuery({
    queryKey: [invoiceData.NewBookingID, "Payments"],
    queryFn: () => fetchReservationOwnerPayments(invoiceData.NewBookingID),
    retry: false,
    refetchOnWindowFocus: false,
  })

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setSnackbarVisisble(false)
  }

  const onIncrease = () => {
    if (guestsCount - invoiceData.pay_portions - payingCount <= 0) return
    if (payingCount < guestsCount) {
      setpayingCount(payingCount + 1)
      settotalShare((invoiceData.price / guestsCount) * (payingCount + 1))
    }
  }
  const onDecrease = () => {
    if (payingCount > 1) {
      setpayingCount(payingCount - 1)
      settotalShare((invoiceData.price / guestsCount) * (payingCount - 1))
    }
  }

  const onGuestIncrease = () => {
    if (disableGuestsCounter || guestsCount + 1 > guestsNumber) return
    setGuestsCount(guestsCount + 1)
    settotalShare((invoiceData.price / (guestsCount + 1)) * payingCount)
  }

  const onGuestDecrease = () => {
    if (disableGuestsCounter) return
    const newGuestCount = guestsCount - 1
    if (payingCount > newGuestCount && newGuestCount !== 0) {
      setpayingCount(payingCount - 1)
      setGuestsCount(newGuestCount)
      settotalShare((invoiceData.price / newGuestCount) * (payingCount - 1))
    } else if (newGuestCount > 0) {
      setGuestsCount(newGuestCount)
      settotalShare((invoiceData.price / newGuestCount) * payingCount)
    }
  }

  const splitBillMutation = useMutation({
    onMutate: async (args) => {
      if (!disableGuestsCounter) {
        const newGuestsCount = await increaseGuestsCount({
          reservationID: args.reservationID,
          guestCount: guestsCount,
        })
        setDisabledGuestsCounter(true)
        return { ...args, newGuestsCount }
      }
    },
    mutationFn: splitBill,
    onSuccess: async (data) => {
      if (data?.next_action?.redirect_to_url) {
        setPaymentIntent(data)
        setIsLoadingModal(true)
        setShowModal(true)
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
        return
      }
      if (data.status === "paid") {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_24" },
        })
        addChargeToSvrMutation.mutate({
          reservation_id: reservationID,
          paymentIntentID: data.paymentIntentID,
          amountInPound: invoiceData.price,
          subtotal: invoiceData.subtotal,
        })
      } else {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_25" },
        })
      }
      setisConfirmed(true)
      setShowPaymentBtn(false)
      setStatusPaid(data.status === "paid")
      setPortionPaid(data.portionPaid)
      setAnotherPayment(false)
      setSomeoneElse(true)
      setIsWalletConfirming(false)
    },
    onError: (err) => {
      setIsWalletConfirming(false)
      toast.error(err.message ? err.message : "Please try again")
    },
  })
  const splitBillApplePaymentMutation = useMutation({
    onMutate: async (args) => {
      if (!disableGuestsCounter) {
        const newGuestsCount = await increaseGuestsCount({
          reservationID: args.reservationID,
          guestCount: guestsCount,
        })
        setDisabledGuestsCounter(true)
        return { ...args, newGuestsCount }
      }
    },
    mutationFn: splitBillApplePayment,
    onSuccess: ({ portionPaid, status, paymentIntentID }) => {
      if (status === "paid") {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_24" },
        })
        addChargeToSvrMutation.mutate({
          reservation_id: reservationID,
          paymentIntentID: paymentIntentID,
          amountInPound: invoiceData.price,
          subtotal: invoiceData.subtotal,
        })
      } else {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_25" },
        })
      }
      setisConfirmed(true)
      setShowPaymentBtn(false)
      setStatusPaid(status === "paid")
      setPortionPaid(portionPaid)
      setSomeoneElse(true)
      setAnotherPayment(false)
      setIsWalletConfirming(false)
    },
    onError: (err) => {
      toast.error(err.message ?? "Please try again")
      setIsWalletConfirming(false)
    },
  })
  const splitBill3DSMutation = useMutation({
    mutationFn: payInSplit3DS,
    onSuccess: ({ portionPaid, status, paymentIntentID }) => {
      if (status === "paid") {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_24" },
        })
        addChargeToSvrMutation.mutate({
          reservation_id: reservationID,
          paymentIntentID: paymentIntentID,
          amountInPound: invoiceData.price,
          subtotal: invoiceData.subtotal,
        })
      } else {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_25" },
        })
      }
      setShowModal(false)
      document.body.style.overflow = "auto"
      setShowPaymentBtn(false)
      setStatusPaid(status === "paid")
      setPortionPaid(portionPaid)
      setIsLoadingModal(false)
      setisConfirmed(true)
      setAnotherPayment(false)
      setSomeoneElse(true)
    },
    onError: (err) => {
      document.body.style.overflow = "auto"
      setShowModal(false)
      toast.error(err.message ? err.message : "Please try again")
      setIsLoadingModal(false)
    },
  })

  const updateReservationMutation = useMutation({
    mutationFn: updateSvrReservation,
    onSuccess: () => {},
    onError: (err) => {
      toast.error(err.message ?? "updating reservation failed")
    },
  })

  const addChargeToSvrMutation = useMutation({
    mutationFn: addChargeToSvr,
    onSuccess: () => {},
    onError: (err) => {
      toast.error(err.message ?? "adding charge failed")
    },
  })

  const addPaymentFailedMutation = useMutation(addPaymentFailure)

  const onConfirmAmount = () => {
    splitBillMutation.mutate({
      billID: invoiceID,
      stripeCustomerID: invoiceData.stripeCustomerID,
      portion: payingCount,
      isOwner: isOwner,
      reservationID: invoiceData.reservationID,
    })
  }

  const onConfirm = async (elementsObj, stripeObj) => {
    try {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      setIsWalletConfirming(true)
      const { client_secret } = await createPaymentIntent({
        amountInPence: amountInPence,
        description: `Invoice:${invoiceID},Reservation:${
          invoiceData.NewBookingID
        },Restaurant:${invoiceData.restaurantName} ${invoiceData.restaurantID},Date: ${moment().format()}`,
        meta: {
          invoiceNum: invoiceData.receiptNum,
          table: invoiceData.table,
        },
      })
      const { error, paymentIntent } = await stripeObj.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements: elementsObj,
        clientSecret: client_secret,
        confirmParams: {
          expand: ["payment_method"],
          return_url: `${window.location.origin}/bill/${reservationID}/`,
        },
        redirect: "if_required",
      })

      let modifiedPaymentIntent = paymentIntent ? { ...paymentIntent } : {}
      let paymentBodySucceeded = {}
      let paymentModifiedBodySucceeded = {}
      let paymentBodyNotSucceeded = {}
      let paymentBodyError = {}

      if (error) {
        paymentBodyError = {
          reservationID,
          payment_intent: error.payment_intent,
          payment_method: error.payment_method,
          isOwner,
          reason: error.message,
        }
        setIsWalletConfirming(false)
        addPaymentFailedMutation.mutate({
          reservationID,
          payment_intent: error.payment_intent,
          payment_method: error.payment_method,
          isOwner,
          reason: error.message,
        })
        toast.error(error.message ?? "payment failed")
      } else if (paymentIntent.status !== "succeeded") {
        toast.error("payment not succeeded because of some reason")
        paymentBodyNotSucceeded = {
          reservationID,
          payment_intent: paymentIntent,
          payment_method: paymentIntent.payment_method,
          isOwner,
          reason: "payment not succeeded because of some reason",
        }
        addPaymentFailedMutation.mutate(paymentBodyNotSucceeded)
        setIsWalletConfirming(false)
      } else if (paymentIntent.status === "succeeded") {
        paymentBodySucceeded = {
          billID: invoiceID,
          portion: payingCount,
          email: isOwner
            ? invoiceData.guestEmail ?? "no-email@owner.com"
            : "guest@guest.com",
          name: isOwner
            ? `${invoiceData.firstName} ${invoiceData.lastName}`
            : "guest",
          reservationID: invoiceData.reservationID,
          paymentMethod: {
            id: paymentIntent.payment_method?.id ?? null,
            card: { last4: paymentIntent.payment_method?.card?.last4 ?? null },
          },
          paymentType:
            paymentIntent.payment_method.card?.wallet ?? null
              ? paymentIntent.payment_method?.card?.wallet?.type ?? null
              : paymentIntent.payment_method?.type ?? null,
          isOwner: !!guestID ?? null,
          paymentIntent: paymentIntent ?? null,
        }

        if (!modifiedPaymentIntent.payment_method.id) {
          modifiedPaymentIntent.payment_method = await getPaymentMethod(
            modifiedPaymentIntent.payment_method,
          )
        }

        if (!modifiedPaymentIntent.payment_method.card) {
          modifiedPaymentIntent.payment_method.card = {
            last4: null,
            wallet: { type: modifiedPaymentIntent.payment_method.type ?? null },
          }
        }

        paymentModifiedBodySucceeded = {
          billID: invoiceID,
          portion: payingCount,
          email: isOwner
            ? invoiceData.guestEmail ?? "no-email@owner.com"
            : "guest@guest.com",
          name: isOwner
            ? `${invoiceData.firstName} ${invoiceData.lastName}`
            : "guest",
          reservationID: invoiceData.reservationID,
          paymentMethod: {
            id: modifiedPaymentIntent.payment_method.id,
            card: { last4: modifiedPaymentIntent.payment_method.card.last4 },
          },
          paymentType: modifiedPaymentIntent.payment_method.card?.wallet
            ? modifiedPaymentIntent.payment_method.card.wallet.type
            : modifiedPaymentIntent.payment_method.type,
          isOwner: !!guestID,
          paymentIntent: modifiedPaymentIntent,
        }

        splitBillApplePaymentMutation.mutate(paymentModifiedBodySucceeded)
      }

      await addStripePaymentLog({
        reservationID,
        stripeLog: {
          stripePaymentIntent: paymentIntent ?? {},
          stripeModifiedPaymentIntent: modifiedPaymentIntent ?? {},
          stripePaymentError: error ?? {},
        },
        zumiLog: {
          zumiPaymentLog: paymentBodySucceeded,
          zumiModifiedPaymentLog: paymentModifiedBodySucceeded,
          zumiPaymentError: paymentBodyError,
        },
      })
    } catch (err) {
      toast.error(err.message)
      console.log(typeof err)
      console.log(err.message)
      console.log(err.stack)
      await addStripePaymentLog({
        reservationID,
        errorLog: { message: err.message, stack: err.stack },
      })
      setIsWalletConfirming(false)
    }
  }

  useEffect(() => {
    if (invoiceData.status === "paid") {
      nav(`/bill/${reservationID}`)
    }

    if (invoiceData.locked === true && invoiceData.status !== "paid") {
      settotalShare(invoiceData.price)
      setisConfirmed(true)
    }

    const isOwner = invoiceData.guestID === guestID
    if (isOwner) setIsOwner(true)

    if (invoiceData.pay_method === "split") {
      const owner = invoiceData?.splitters?.findIndex((i) => i.isOwner)
      const ownerPaid = owner > -1
      if (ownerPaid && isOwner) {
        if (!second) {
          setisConfirmed(true)
        }

        const portion = invoiceData.pay_portions
        settotalShare((invoiceData.price / invoiceData.guestsCount) * portion)
        return
      }
    }

    if (invoiceData.guestsCount - invoiceData.pay_portions - payingCount < 0) {
      setpayingCount(guestsCount - invoiceData.pay_portions)
    }
  }, [invoiceData, isInvited])

  useEffect(() => {
    if (
      invoiceData.guestsCountUpdated &&
      invoiceData.bill_status !== "paid" &&
      !disableGuestsCounter
    ) {
      setDisabledGuestsCounter(true)
      setGuestsCount(invoiceData.guestsCount)

      if (payingCount > invoiceData.guestsCount) {
        setpayingCount(invoiceData.guestsCount)
        settotalShare(
          (invoiceData.price / invoiceData.guestsCount) *
            invoiceData.guestsCount,
        )
      } else {
        settotalShare(
          (invoiceData.price / invoiceData.guestsCount) * payingCount,
        )
      }

      toast.info("Number of guests has changed")
    }
  }, [invoiceData])

  useEffect(() => {
    let newGuestCount = guestsCount
    if (invoiceData.guestsCountUpdated) {
      setGuestsCount(Number(invoiceData.guestsCount))
      newGuestCount = Number(invoiceData.guestsCount)
    }
    const newTotalShare = (invoiceData.price / newGuestCount) * payingCount
    settotalShare(newTotalShare)
  }, [invoiceData])

  useEffect(() => {
    const handleMessage = async (event) => {
      if (
        event.data === "3DS-authentication-complete" &&
        paymentIntent?.client_secret
      ) {
        const { paymentIntent: intent } = await stripe.retrievePaymentIntent(
          paymentIntent.client_secret,
        )
        if (intent.status === "succeeded") {
          splitBill3DSMutation.mutate({
            invoiceID,
            paymentIntentID: intent.id,
            portion: payingCount,
          })
        } else {
          toast.error(
            intent.last_payment_error.message ?? "authenticating failed",
          )
          setShowModal(false)
          setIsLoadingModal(false)
          document.body.style.overflow = "auto"
        }
      }
    }

    window.addEventListener("message", handleMessage, false)

    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [stripe, paymentIntent])

  useEffect(() => {
    if (payingCount > 0 && guestsCount > 0 && !!invoiceData) {
      setAmountInPound(
        calcAmountInPence(invoiceData, payingCount, guestsCount) / 100,
      )
      setAmountInPence(calcAmountInPence(invoiceData, payingCount, guestsCount))
    }
  }, [payingCount, invoiceData, guestsCount])

  if (invoiceData.status === "paid" || isLoading) {
    return null
  }

  return (
    <>
      <div className="w-full  lg:max-w-[100%] relative flex flex-col items-center bg-[#131C2B] ">
        {(!isConfirmed || anotherPayment) && (
          <>
            <div className="mx-[30px] w-full count-container">
              <p className=" text-amber-50 text-base font-normal text-[20px]">
                How many people are at the table?
              </p>
              <div className="flex justify-between align-center w-full">
                <p className="select-none text-[58px]">{guestsCount}</p>
                <div className="flex basis-16 justify-center items-center gap-x-5 font-bold text-xl">
                  {!disableGuestsCounter && (
                    <p
                      onClick={onGuestDecrease}
                      className="cursor-pointer select-none bg-[#FEFFFA33] count-button"
                    >
                      -
                    </p>
                  )}
                  {!disableGuestsCounter && (
                    <p
                      onClick={onGuestIncrease}
                      className="cursor-pointer select-none bg-[#FEFFFA33] count-button"
                    >
                      +
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="mx-[30px] w-full count-container">
              <p className=" text-amber-50 text-base font-normal text-[20px]">
                How many guests would you like to pay for?
              </p>
              <div className="flex justify-between align-center w-full">
                <p className="select-none text-[58px]">{payingCount}</p>
                <div className="flex basis-16 justify-center items-center gap-x-5 font-bold text-xl">
                  <p
                    onClick={onDecrease}
                    className="cursor-pointer select-none bg-[#FEFFFA33] count-button"
                  >
                    -
                  </p>
                  <p
                    onClick={onIncrease}
                    className="cursor-pointer select-none bg-[#FEFFFA33] count-button"
                  >
                    +
                  </p>
                </div>
              </div>
            </div>

            <p className="text-left w-full mt-4">
              Bill total{" "}
              <strong>
                {currencySymbol} {Number(invoiceData.price).toFixed(2)}
              </strong>{" "}
              / by <strong>{guestsCount} guests</strong>
            </p>
            <div className="flex w-full flex-col justify-between mx-[30px]">
              <div className="flex w-full justify-between">
                <div className=" text-indigo-300 text-[22px] font-semibold leading-normal">
                  Your total
                </div>
                <div className=" text-right text-indigo-300 text-[22px] font-semibold leading-normal">
                  {
                    Object.values(currencies).find(
                      (c) =>
                        c.code.toLowerCase() ===
                        invoiceData.currency.toLowerCase(),
                    )?.symbol
                  }
                  {amountInPound.toFixed(2)}
                </div>
              </div>
            </div>
            <HorizontalLine className="my-4" />
          </>
        )}

        {!data?.paymentMethods || !guestID ? (
          <>
            <div className="w-full">
              {totalShare > 0 && (
                <StripeCheckoutForm
                  onConfirm={onConfirm}
                  amount={amountInPence}
                />
              )}
            </div>
            <p className="text-center my-5">OR</p>
            <PaymentForm
              setSomeoneElse={setSomeoneElse}
              setAnotherPayment={setAnotherPayment}
              state={{ portion: payingCount, guestsCount, payMethod: "split" }}
              setIsWalletConfirming={setIsWalletConfirming}
            />
          </>
        ) : (
          <>
            {(!isConfirmed || anotherPayment) && (
              <>
                <div className="w-full">
                  {totalShare > 0 && (
                    <StripeCheckoutForm
                      onConfirm={onConfirm}
                      amount={amountInPence}
                    />
                  )}
                </div>
                <p className="text-center my-5">OR</p>
                {splitBillMutation.isLoading ? (
                  <CompletePaymentLoading />
                ) : (
                  <>
                    {data?.paymentMethods?.map((item, index) => {
                      if (item.isDefault) {
                        return (
                          <>
                            <ChangePaymentMethod
                              bookId={invoiceData.NewBookingID}
                              isOpen={isChange}
                            />
                            <div key={index} className="w-full">
                              <div
                                className={cn(
                                  "w-full grid grid-cols-2 items-center my-3",
                                )}
                              >
                                <div className="col-span-1 flex gap-x-2 align-baseline">
                                  <span>
                                    <CardIcon brand={item.card.brand} />
                                  </span>
                                  <span className="text-amber-50 text-xs font-normal leading-none">
                                    {item.card.brand} ending with{" "}
                                    {item.card.last4}
                                  </span>
                                </div>
                                <div
                                  className="col-span-1 flex justify-end"
                                  onClick={() =>
                                    // nav(`/modify-payment/${invoiceData.restaurantID}/${invoiceData.NewBookingID}`)
                                    {
                                      setIsChange(false)
                                      setTimeout(() => setIsChange(true))
                                    }
                                  }
                                >
                                  <div className="text-[#fff] text-[14px] bg-[#555] px-2 py-1 rounded-full">
                                    Change
                                  </div>
                                </div>
                              </div>
                              <div className="  flex justify-center"></div>
                            </div>
                          </>
                        )
                      } else return null
                    })}
                    <div className=" w-full">
                      <SwipeButton onSwipe={onConfirmAmount} />
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>

      <Snackbar
        open={snackbarVisisble}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error: {splitBillMutation?.error?.message}
        </Alert>
      </Snackbar>
      {showModal && isLoadingModal && paymentIntent && (
        <div className="h-screen fixed inset-0 flex justify-center items-center text-custom-primary bg-black bg-opacity-50 gap-2">
          <CircularProgress size={50} color={"inherit"} />
          <p className="text-custom-primary">Processing...</p>
        </div>
      )}
      {showModal && paymentIntent && (
        <div className="fixed inset-0 bottom-0 flex justify-center items-center h-screen">
          <iframe
            className="w-full h-full p-1/6"
            data-hj-allow-iframe="true"
            title="stripeconfirm"
            src={paymentIntent.next_action.redirect_to_url.url}
          ></iframe>
        </div>
      )}
    </>
  )
}

const SplitBill = ({
  setSomeoneElse,
  anotherPayment,
  setAnotherPayment,
  setIsWalletConfirming,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <SplitBillComponent
        anotherPayment={anotherPayment}
        setSomeoneElse={setSomeoneElse}
        setAnotherPayment={setAnotherPayment}
        setIsWalletConfirming={setIsWalletConfirming}
      />
    </Elements>
  )
}
export default SplitBill
