import React, { useMemo, useState } from "react"
import moment from "moment-timezone"
import { useParams } from "react-router"
import DataRangeTable from "../components/DataRangeTable"
import { getStats } from "../../api/v2/settings"
import { getSubtenantId } from "../../utils/helperFunctions"
moment.tz.setDefault("Europe/London")

const StatsPage = () => {
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params["*"])

  const statsHeaders = useMemo(
    () => [
      [
        {
          id: 1,
          name: "Count of Bookings",
          key: "countOfBookings",
          sortable: true,
        },
        { id: 2, name: "Sum of Guests", key: "sumOfGuests", sortable: true },
        { id: 3, name: "Sum of Total", key: "sumOfTotal", sortable: true },
        {
          id: 4,
          name: "Adjusted Count of Bookings",
          key: "countOfBookingsExcludingNull",
          sortable: true,
        },
        {
          id: 5,
          name: "Count of Bookings Paid",
          key: "countOfBookingsPaid",
          sortable: true,
        },
        {
          id: 6,
          name: "Sum of Paid Guests",
          key: "sumOfPaidGuests",
          sortable: true,
        },
        {
          id: 7,
          name: "Sum of Paid Total",
          key: "sumOfPaidTotal",
          sortable: true,
        },
        {
          id: 9,
          name: "Count of Paid by Apple Pay",
          key: "countOfPaidByApplePay",
          sortable: true,
        },
        {
          id: 10,
          name: "Count of Paid by Google Pay",
          key: "countOfPaidByGooglePay",
          sortable: true,
        },
        {
          id: 11,
          name: "Count of Paid by Credit Card",
          key: "countOfPaidByCreditCard",
          sortable: true,
        },
        {
          id: 15,
          name: "Count of Paid Full",
          key: "countOfPaidFull",
          sortable: true,
        },
        {
          id: 16,
          name: "Count of Paid Split",
          key: "countOfPaidSplit",
          sortable: true,
        },
      ],
      [
        {
          id: 12,
          name: "Sum of Paid Total by Apple Pay",
          key: "sumOfPaidTotalByApplePay",
          sortable: true,
        },
        {
          id: 13,
          name: "Sum of Paid Total by Google Pay",
          key: "sumOfPaidTotalByGooglePay",
          sortable: true,
        },
        {
          id: 14,
          name: "Sum of Paid Total by Credit Card",
          key: "sumOfPaidTotalByCreditCard",
          sortable: true,
        },
        {
          id: 17,
          name: "Sum of Paid Full",
          key: "sumOfPaidFull",
          sortable: true,
        },
        {
          id: 18,
          name: "Sum of Paid Split",
          key: "sumOfPaidSplit",
          sortable: true,
        },
      ],
    ],
    [],
  )
  const [secondColData, setSecondColData] = useState([])

  const fetchStats = async ({ startDate, endDate }) => {
    const response = await getStats({
      tenantId,
      subtenantId,
      startDate,
      endDate,
    })
    console.log("response", response)
    if (response.status == "failed") {
      throw new Error(response?.message)
    }
    const valuesObj = {}
    const percentagesObj = {}
    for (const col in response) {
      valuesObj[col] = {}
      percentagesObj[col] = {}
      for (const key in response[col]) {
        valuesObj[col][key] = response[col][key].value
        percentagesObj[col][key] = response[col][key].percentage
      }
    }
    setSecondColData([[percentagesObj.col1], [percentagesObj.col2]])
    return [[valuesObj.col1], [valuesObj.col2]]
  }

  return (
    <div className={"flex items-center flex-col"}>
      <DataRangeTable
        onSearch={fetchStats}
        secondColData={secondColData}
        headers={statsHeaders}
        title={"Stats details"}
        tableProps={{ verticalHeader: true }}
      />
    </div>
  )
}

export default StatsPage
