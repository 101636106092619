import * as React from "react"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import ModifyPayment from "../modify payment"

export default function ChangePaymentMethod({ bookId, isOpen }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  React.useEffect(() => {
    toggleDrawer("bottom", isOpen)()
  }, [isOpen])
  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            SlideProps={{
              style: {
                background: "#212D43",
                borderRadius: "32px 32px 0px 0px",
                padding: "25px 0",
                maxHeight: "95vh",
              },
            }}
          >
            <ModifyPayment bookId={bookId} />
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  )
}
