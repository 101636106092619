import React, { useState, useEffect } from "react"
import {
  useParams,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom"
import moment from "moment"
import { useQuery } from "@tanstack/react-query"
import { toast } from "react-toastify"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

import { CreditCardIcon, SpinnerIcon } from "../../components/custom/Icons"
import { getOccasions, savePreferencesV2 } from "../../api/v2/reservations"
import "./bookingStyle.css"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import { getSubtenantId } from "../../utils/helperFunctions"

const ConfirmInfo = () => {
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params?.subtenantId)
  const nav = useNavigate()
  const { state } = useLocation()
  const [inviteLoading, setinviteLoading] = useState(false)
  const [preferences, setPreferences] = useState({
    occasion: null,
    restrictions: null,
    special: null,
  })
  const [preferencesLoading, setpreferencesLoading] = useState(false)
  const { restaurantData, brandData } = useOutletContext()
  const [preferencesSaved, setPreferencesSaved] = useState(false)

  let { selectedDateTime, guestCount, last4, newBookingID, reservationCode } =
    state ?? {
      selectedDateTime: null,
      guestCount: null,
      last4: null,
      newBookingID: null,
      reservationCode: null,
    }
  selectedDateTime = moment(selectedDateTime)

  const resLink = `${window.location.origin}/reservations/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${newBookingID}`
  const resNumber = `${reservationCode}`

  const { data: occasions } = useQuery({
    queryKey: ["occasions"],
    queryFn: () => getOccasions(tenantId, subtenantId),
  })

  const onNotesSave = (event) => {
    event.preventDefault()
    setpreferencesLoading(true)
    savePreferencesV2(
      newBookingID,
      preferences.occasion,
      preferences.restrictions,
      preferences.special,
    )
      .then(() => {
        setPreferencesSaved(true)
        setpreferencesLoading(false)
      })
      .catch((err) => {
        toast.error(err.message)
        setinviteLoading(false)
        setPreferencesSaved(false)
        setpreferencesLoading(false)
      })
  }

  const onCopy = (event) => {
    event.preventDefault()
    window.navigator.clipboard.writeText(resLink).then(() => {})
    toast.success("Copied to clipboard!")
  }

  useEffect(() => {
    if (!state) {
      nav(`/booking-info/${tenantId}${subtenantId ? `/${subtenantId}` : ""}`)
    }
  }, [state, nav, tenantId, subtenantId])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  if (!state) return null

  return (
    <CustomFontComponent fontFamily={brandData?.data?.font ?? ""}>
      <div className="bg-color-light-grey">
        <div className="confirm-container">
          <div className="confirm-top-section">
            <p className="guest-text-style">{guestCount} guests</p>
            <p className="guest-text-style">
              {moment(selectedDateTime).format("ddd, MMM D")}
            </p>
            <p className="guest-text-style">
              {moment(selectedDateTime).format("HH:mm")}
            </p>
          </div>
          <div className="confirm-view">
            <h1 className="res-cnf-style">Reservation Confirmed!</h1>
            <div className="res-desc-style">
              Thanks for making a reservation with us! We’re really looking
              forward to your visit.
            </div>
            <div className="res-desc-style">
              Please make sure you arrive within 15 minutes of your reservation
              time or we may not be able to seat you.
            </div>
            <div className="res-desc-style">
              Your table will be reserved for 1 hour and 45 minutes.
            </div>
            <div className="res-desc-style">
              {restaurantData.name} charges an optional 12.5% gratuity charge
              per table.
            </div>
            <div className="res-cnf-bottom">
              <div className="res-cnf-b-text capitalize">
                {restaurantData.name}
              </div>
              <div className="res-cnf-b-text">{guestCount} guests</div>
              <div className="res-cnf-b-text">
                {moment(selectedDateTime).format("ddd, MMM D, YYYY")}
              </div>
              <div className="res-cnf-b-text">
                {moment(selectedDateTime).format("HH:mm")}
              </div>
              <div className="res-cnf-b-text">Res# {resNumber}</div>
              <div className="res-cnf-card">
                <CreditCardIcon /> Card ending in {last4}
              </div>
            </div>
            <h1 className="res-cnf-style">Invite guests</h1>
            <div className="res-desc-style">
              We'd love it if you could invite your dinner guests to join our
              Zumi seamless checkout experience so you can easily split the bill
              without waiting around at the restaurant.
            </div>
            <button onClick={onCopy} className="copy-link-outer">
              <div className="copy-link-inner">
                {inviteLoading && <SpinnerIcon />}
                <span>Copy link to reservation</span>
              </div>
            </button>
            <h1 className="res-cnf-style">Reservation notes</h1>
            <form onSubmit={onNotesSave}>
              <div className="confirm-bottom-sec" />
              <Accordion allowZeroExpanded className="custom-accordion">
                <AccordionItem>
                  <AccordionItemButton>
                    <p className="cnf-bottom-title">What’s the occasion?</p>
                  </AccordionItemButton>
                  <AccordionItemPanel>
                    <div className="cnf-bottom-div">
                      {occasions?.map((occasion, index) => (
                        <div
                          onClick={() => {
                            if (preferences.occasion === occasion) {
                              setPreferences({ ...preferences, occasion: null })
                            } else setPreferences({ ...preferences, occasion })
                          }}
                          key={index}
                          className={`bottom-option ${preferences.occasion === occasion ? "bg-color-dark text-white" : "bg-color-body-default text-color-dark"}`}
                        >
                          <div className="bottom-option-text">{occasion}</div>
                        </div>
                      ))}
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <Accordion
                allowZeroExpanded
                className="custom-accordion showBillInfo"
              >
                <AccordionItem>
                  <AccordionItemButton>
                    <p className="cnf-bottom-title">
                      Any allergies or restrictions?
                    </p>
                  </AccordionItemButton>
                  <AccordionItemPanel>
                    <textarea
                      onChange={(e) =>
                        setPreferences({
                          ...preferences,
                          restrictions: e.target.value,
                        })
                      }
                      className="bottom-textarea"
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <Accordion
                allowZeroExpanded
                className="custom-accordion showBillInfo"
              >
                <AccordionItem>
                  <AccordionItemButton>
                    <p className="cnf-bottom-title">Any special requests?</p>
                  </AccordionItemButton>
                  <AccordionItemPanel>
                    <textarea
                      onChange={(e) =>
                        setPreferences({
                          ...preferences,
                          special: e.target.value,
                        })
                      }
                      className="bottom-textarea"
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
              <div className="border-style" />
              <button
                disabled={
                  !(
                    preferences.occasion ||
                    preferences.restrictions ||
                    preferences.special
                  ) ||
                  preferencesLoading ||
                  preferencesSaved
                }
                onClick={onNotesSave}
                className={`bottom-btn ${preferencesSaved ? "bg-color-green" : "bg-color-dark"}`}
              >
                <div className="bottom-btn-inner">
                  {preferencesLoading && <SpinnerIcon />}
                  <span>
                    {preferencesSaved
                      ? "Reservation notes updated!"
                      : "Save reservation notes"}
                  </span>
                </div>
              </button>
            </form>
          </div>
        </div>
      </div>
    </CustomFontComponent>
  )
}

export default ConfirmInfo
