import React, { useState } from "react"
import moment from "moment"
import Select from "react-select"
import { toast } from "react-toastify"
import { useForm } from "react-hook-form"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { useMutation } from "@tanstack/react-query"
import { useNavigate, useOutletContext, useParams } from "react-router-dom"

import countryCodes from "../../utils/country_codes.json"
import { SpinnerIconWhite } from "../../components/custom/Icons"
import { confirmInvitation } from "../../api/v2/reservations"
import { getSubtenantId } from "../../utils/helperFunctions"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import "./reservations.css"

const GuestInformationMain = () => {
  const nav = useNavigate()
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params?.subtenantId)
  const [countryCode, setcountryCode] = useState({
    value: "+44",
    label: "United Kingdom +44",
  })
  const [newGuestEmail, setnewGuestEmail] = useState("")
  const { restaurantData, reservationData, refetch, brandData } =
    useOutletContext()
  const { dateTime, guestsCount, NewBookingID } = reservationData

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const confirmInviteMutation = useMutation({
    mutationFn: confirmInvitation,
    onSuccess: () => {
      refetch()
      nav(
        `/reservations/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${NewBookingID}/confirmed`,
        {
          state: {
            email: newGuestEmail,
          },
        },
      )
    },
    onError: (error) => {
      toast.error(error?.message)
    },
  })

  const options = countryCodes.map((i) => ({
    value: i.dial_code,
    label: `${i.name} ${i.dial_code}`,
  }))
  const handleChange = (selectedOption) => {
    setcountryCode(selectedOption)
  }

  const onConfirm = async (data) => {
    const { guestName, guestPhone } = data
    confirmInviteMutation.mutate({
      bookingID: NewBookingID,
      email: newGuestEmail,
      phone: `${countryCode}${guestPhone}`,
      name: guestName,
    })
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "9rem",
      minHeight: "3rem",
      height: state.isFocused ? "auto" : "3rem",
      transition: "height 0.3s ease",
    }),
    option: (provided) => ({
      ...provided,
      padding: "0.5rem",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: "20rem",
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "none",
    }),
  }

  return (
    <CustomFontComponent
      fontFamily={brandData?.data?.font ?? ""}
      className="w-full"
    >
      <div className="guest-information-main">
        <div className="border-res-line" />
        <div className="invited-div">You’re invited!</div>
        <div className="border-res-line" />
        <div className="guest-info-desc-view">
          {reservationData?.firstName} has invited you to dinner at Ida. RSVP to
          set your preferences and save a payment method for a seamless checkout
          experience.
        </div>
        <div className="guest-info-middile-sec">
          <div className="guest-info-middle-text capitalize">
            {restaurantData?.name}
          </div>
          <div className="guest-info-middle-text">{guestsCount} guests</div>
          <div className="guest-info-middle-text">
            {moment(dateTime)?.format("ddd, MMM D, YYYY")}
          </div>
          <div className="guest-info-middle-text">
            {moment(dateTime)?.format("HH:mm")}
          </div>
          <div className="border-res-line" />
          <div className="guest-your-info-div">Your information</div>
          <form
            onSubmit={handleSubmit((data) => {
              onConfirm(data)
            })}
          >
            <div className="guest-form-div">
              <input
                type="text"
                placeholder="Name"
                className="info-input-style"
                {...register("guestName", { required: true })}
              />
              {errors.guestName && (
                <span className="guest-form-alert-style">
                  This field is required
                </span>
              )}
              <div className="guest-phone-view">
                <Select
                  id="country-select"
                  value={countryCode}
                  onChange={handleChange}
                  options={options}
                  placeholder="Country"
                  styles={customStyles}
                />
                <input
                  type="text"
                  placeholder="Phone"
                  className="info-input-style flex-1"
                  {...register("guestPhone", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^(\+?\d{1,3}[- ]?)?\d{10}$/,
                      message: "Please Enter a valid phone number",
                    },
                  })}
                />
              </div>
              {errors.guestPhone && (
                <span className="guest-form-alert-style">
                  {errors.guestPhone.message}
                </span>
              )}
              <input
                type="email"
                placeholder="Email"
                className="info-input-style"
                required
                {...register("guestEmail", {
                  required: {
                    value: true,
                    message: "This field is required",
                  },
                  pattern: {
                    value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Please Enter a valid email",
                  },
                })}
                value={newGuestEmail}
                onChange={(e) => setnewGuestEmail(e.target.value)}
              />
              {errors?.guestEmail && (
                <span className="guest-form-alert-style">
                  {errors?.guestEmail?.message}
                </span>
              )}
            </div>
            <button
              disabled={confirmInviteMutation?.isLoading}
              onClick={() => {
                handleSubmit((data) => {
                  onConfirm(data)
                })
              }}
              className="guest-form-button"
            >
              <div className="guest-form-button-inner">
                {confirmInviteMutation?.isLoading && <SpinnerIconWhite />}
                <span>Confirm</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </CustomFontComponent>
  )
}

const GuestInformation = () => {
  loadStripe.setLoadParameters({ advancedFraudSignals: false })
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

  return (
    <Elements stripe={stripePromise}>
      <GuestInformationMain />
    </Elements>
  )
}

export default GuestInformation
