import React, { useEffect, useState } from "react"
import CustomDrawer from "../../components/custom/CustomDrawer"
import { toast } from "react-toastify"
import { CheckWhiteIcon, CustomLoader } from "../../components/custom/Icons"
import { useParams } from "react-router"
import { billGuestProblem } from "../../api/v2/bills"

function ProblemWithBill() {
  const [open, setOpen] = useState(false)
  const { reservationID } = useParams()
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  useEffect(() => {
    setOpen(open)
  }, [open])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await billGuestProblem({
        reservationID,
        problemMessage: message,
      })
      toast.success("Problem has been reported successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      setSubmitted(true)
      setOpen(false)
      setMessage("")
    } catch (err) {
      toast.error(err.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    } finally {
      setIsLoading(false)
    }
  }
  const handleClose = () => {
    setOpen(false)
    setSubmitted(false)
    setMessage("")
  }

  return (
    <div>
      <div className="flex-center">
        <p
          onClick={() => setOpen(true)}
          className="max-w-max text-center underline text-color-dark cursor-pointer font-semibold"
        >
          Problem with your bill?
        </p>
      </div>
      <CustomDrawer anchor="bottom" open={open} onClose={handleClose}>
        {submitted ? (
          <div className="gap-5 w-full">
            <p className="text-color-dark text-center w-full">
              <CheckWhiteIcon className="text-6xl" />
            </p>
            <p className="text-color-dark text-center text-xl my-4">
              Problem submitted
            </p>
            <p className="text-center text-color-light mb-5">
              Thank you for submitting your comments. We’ll get back to you
              shortly.{" "}
            </p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="w-full">
            <p className="text-center text-color-dark font-semibold text-2xl mb-4">
              Problem with your bill?
            </p>
            <textarea
              required
              placeholder="We’re sorry! Please tell us what happened..."
              rows={4}
              className="custom-textarea"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
            <button
              className="btn-dark-default mt-5"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="text-white flex items-center gap-3">
                  <CustomLoader height={24} width={24} />
                  Loading...
                </div>
              ) : (
                "Submit Problem"
              )}
            </button>
          </form>
        )}
        {/* <div className="flex-center w-full mt-4">
          <p
            onClick={handleClose}
            className="text-center text-color-dark underline cursor-pointer"
          >
            Cancel
          </p>
        </div> */}
      </CustomDrawer>
    </div>
  )
}
export default ProblemWithBill
