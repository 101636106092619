import React from "react"
import { Box } from "@mui/system"
import Card from "@mui/material/Card"
import StatusComponent from "../../components/StatusComponent"
import styles from "./reserVationPage.module.css"
import moment from "moment"
import { useParams } from "react-router"
import EditableData from "../../components/EditableData"
import EditableDateData from "../../components/EditableDateData"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { Button } from "@mui/material"
import { editReservationV2 } from "../../../api/v2/reservations"

function HeaderBox({ data, refetchReservation }) {
  const params = useParams()
  const reservationId = params.id
  const reservationEditMutation = useMutation({
    mutationFn: editReservationV2,
    onSuccess: refetchReservation,
    onError: (err) => {
      toast.error(err.message || "update failed", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })

  const handleDateEdit = (date) => {
    reservationEditMutation.mutate({
      reservationID: reservationId,
      data: {
        dateTime: date.format(),
      },
    })
  }
  const handleEtbIDEdit = (etbID) => {
    reservationEditMutation.mutate({
      reservationID: reservationId,
      data: {
        NewBookingID: etbID,
      },
    })
  }
  const handleTableEdit = (table) => {
    reservationEditMutation.mutate({
      reservationID: reservationId,
      data: {
        tables: [table],
      },
    })
  }
  const handleAmountOutstandingEdit = (amount) => {
    reservationEditMutation.mutate({
      reservationID: reservationId,
      data: {
        amount_unpaid: Number(amount),
      },
    })
  }
  const handleReceiptNumEdit = (receiptNum) => {
    reservationEditMutation.mutate({
      reservationID: reservationId,
      data: {
        receiptNum,
      },
    })
  }

  const handleLabelAsTesting = () => {
    reservationEditMutation.mutate({
      reservationID: reservationId,
      data: {
        isTesting: !data.isTesting,
      },
    })
  }

  return (
    <Card sx={{ flexDirection: "row", p: 2, m: 2 }}>
      <div className={styles.headerText}>
        <p>Reservation {data?.id}</p>
        <StatusComponent status={data?.status} className={"mr-auto"} />
        <Button
          disabled={reservationEditMutation.isLoading}
          onClick={handleLabelAsTesting}
          variant="contained"
        >
          Label As {data.isTesting ? "Live Reservation" : "Testing Reservation"}
        </Button>
      </div>
      <Box className={styles.headerContainer}>
        <span>
          {data?.firstName} {data?.lastName}
        </span>
        <span>{moment(data?.dateTime).format("HH:mm")}</span>
        <span>{data?.guestsCount} Guests</span>
      </Box>
      <Box display={"flex"} flexDirection={"column"} gap={1}>
        <EditableDateData
          title={"Date:"}
          date={moment(data?.dateTime)}
          onSubmit={handleDateEdit}
        />
        <div className="flex gap-2">
          <span className="font-semibold">Reservation Code:</span>
          <span className="font-semibold">{data?.reservationCode}</span>
        </div>
        <div className="flex gap-2">
          <span className="font-semibold">Invoice Id:</span>
          <span className="font-semibold">
            {data?.billID ?? "not specified yet"}
          </span>
        </div>
        <div className="flex gap-2">
          <span className="font-semibold">Zumi Reservation Id: </span>
          <span className="font-semibold">{reservationId}</span>
        </div>
        <EditableData
          title={"sevenrooms reservation Id:"}
          data={data?.NewBookingID}
          onSubmit={handleEtbIDEdit}
        />
        <EditableData
          title={"Table:"}
          data={data?.tables?.length > 0 ? data.tables[0] : "not selected yet"}
          onSubmit={handleTableEdit}
        />
        <EditableData
          title={"Receipt Number:"}
          data={data?.receiptNum ?? "not created yet"}
          onSubmit={handleReceiptNumEdit}
        />
        <div className="flex gap-2">
          <span className="font-semibold">Bill Link:</span>
          <span
            className="font-semibold cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(
                `${process.env.REACT_APP_HOST_URL}/bill-info/${reservationId}/?id=${data?.guestID}`,
              )
              toast.success("Link copied to clipboard")
            }}
          >{`${process.env.REACT_APP_HOST_URL}/bill-info/${reservationId}/?id=${data?.guestID}`}</span>
        </div>
        <div className="flex gap-2">
          <span className="font-semibold">subtotal:</span>
          <span className="font-semibold">
            £{Number(data?.subtotal).toFixed(2)}
          </span>
        </div>
        <div className="flex gap-2">
          <span className="font-semibold">vat:</span>
          <span className="font-semibold">
            £{Number(data?.vatTotal).toFixed(2)}
          </span>
        </div>
        <div className="flex gap-2">
          <span className="font-semibold">vat calc:</span>
          <span className="font-semibold">
            £{Number(data?.vat_calc).toFixed(2)}
          </span>
        </div>
        <div className="flex gap-2">
          <span className="font-semibold">service charge:</span>
          <span className="font-semibold">
            £{Number(data?.serviceAmount).toFixed(2)}
          </span>
        </div>
        <div className="flex gap-2">
          <span className="font-semibold">POS Paid: </span>
          <span className="font-semibold">{data?.pos_paid ? "Yes" : "No"}</span>
        </div>
        {data?.amount_unpaid !== 0.01 && (
          <EditableData
            title={"amount outstanding:"}
            data={data.amount_unpaid}
            onSubmit={handleAmountOutstandingEdit}
          />
        )}
        {data?.refundedAmount !== 0.01 && (
          <div className="flex gap-2">
            <span className="font-semibold">Net Payments:</span>
            <span className="font-semibold">
              £
              {(
                ((data?.pay_total || 0) - (data?.refundedAmount || 0) / 100) *
                100
              ).toFixed(0) / 100}
            </span>
          </div>
        )}
      </Box>
    </Card>
  )
}

export default HeaderBox
