import React, { useEffect, useState } from "react"
import {
  AmExpressIcon,
  CheckBlueThemeFillIcon,
  DiscoverCardIcon,
  MasterCardIcon,
  SpinnerIconWhite,
  VisaIcon,
} from "../../components/custom/Icons"
import HorizontalLine from "../../components/custom/HorizontalLine"
import {
  addNewReservationPayment,
  deleteCard,
  fetchReservationOwnerPayments,
  getClientSecret,
  setHolderDefaultPayment,
} from "../../api/v2/reservations"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js/pure"
import { IoIosCloseCircleOutline } from "react-icons/io"
import { toast } from "react-toastify"
import "./modifyPayment.css"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import { useOutletContext } from "react-router"

export const CardIcon = ({ brand }) => {
  switch (brand) {
    case "visa":
      return <VisaIcon />
    case "amex":
      return <AmExpressIcon />
    case "discover":
      return <DiscoverCardIcon />
    case "mastercard":
      return <MasterCardIcon />
    default:
      return null
  }
}

const ModifyPaymentMain = ({ bookId }) => {
  const [modifyId, setModifyId] = useState(0)
  const [isModifying, setIsModifying] = useState(false)
  const [deletingId, setDeletingId] = useState({
    id: 0,
    isDefault: false,
  })
  const bookingID = bookId
  const stripe = useStripe()
  const elements = useElements()
  const [expandedAddMethod, setExpandedAddMethod] = useState([])
  const { brandData } = useOutletContext()
  const handleAddMethodAccordion = (expandedItems) => {
    setExpandedAddMethod(expandedItems)
  }
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: [bookingID, "Payments"],
    queryFn: () => fetchReservationOwnerPayments(bookingID),
    retry: false,
  })

  const clientSecretMutation = useMutation({
    mutationFn: getClientSecret,
  })

  const addPaymentMutation = useMutation({
    mutationFn: addNewReservationPayment,
    onSuccess: () => refetch(),
  })

  const isDeleteCardClick = async (id, isDefault) => {
    setDeletingId({
      id: id,
      isDefault: isDefault,
    })
    const deleteParams = {
      paymentID: id,
      isDefault: isDefault,
    }
    deleteCardMutation.mutate(deleteParams)
  }

  const deleteCardMutation = useMutation({
    mutationFn: deleteCard,
    onSuccess: () => refetch(),
  })

  const changeDefaultCard = useMutation({
    mutationFn: setHolderDefaultPayment,
    onSuccess: () => {
      refetch()
      toast.success("Card Changed Successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })

  const onAddPayment = async () => {
    if (!stripe || !elements) {
      return null
    }

    const { error: submitError } = await elements.submit()
    if (submitError) {
      toast.error(submitError.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      return
    }

    const { client_secret } = await clientSecretMutation.mutateAsync({
      customerID: data.paymentMethods[0].customer,
    })

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      clientSecret: client_secret,
      confirmParams: {
        return_url: `${window.location.origin}`,
      },
      redirect: "if_required",
    })
    if (error) {
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      return
    }

    addPaymentMutation.mutate({
      bookingID,
      paymentID: setupIntent.payment_method,
    })
  }

  const onSetDefault = async (paymentID) => {
    changeDefaultCard.mutate({ bookingID, paymentID })
  }

  useEffect(() => {
    if (modifyId !== 0) {
      setIsModifying(true)
    }
  }, [modifyId])

  useEffect(() => {
    if (elements) {
      elements.update({
        appearance: {
          variables: {
            colorTextSecondary: "white",
          },
          rules: {
            ".Label": {
              color: "white",
            },
          },
        },
      })
    }
  }, [elements])

  if (isLoading) return null

  if (isError) {
    return (
      <div className="no-reservation-view">
        <h1 className="no-reservation-text">Reservation Not Found</h1>
      </div>
    )
  }

  return (
    <CustomFontComponent fontFamily={brandData?.data?.font ?? ""}>
      <div>
        <div className="add-payment-view">
          <div className="width-max">
            <div className="select-text">Select a payment method</div>
          </div>
          <div className="list-payment-method">
            {data.paymentMethods.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`w-full ${item.isDefault ? "order-first" : ""}`}
                >
                  <div className="list-payment-inner-view">
                    <div className="flex items-center gap-2">
                      {item.isDefault && <CheckBlueThemeFillIcon />}
                      <div
                        onClick={() => {
                          if (!item.isDefault) {
                            setModifyId(item.id)
                          }
                        }}
                        className="card-detail-view"
                      >
                        <span className="ard-number-style">
                          {item.card.brand} ending in {item.card.last4}{" "}
                        </span>
                        <br />
                        <span className="card-exp">
                          Exp {item.card.exp_month}/{item.card.exp_year}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="delete-view">
                      {deletingId === item.id ? (
                        <button
                          onClick={() =>
                            isDeleteCardClick(item.id, item.isDefault)
                          }
                          className="confirm-style"
                        >
                          <IoIosCloseCircleOutline
                            onClick={() =>
                              setDeletingId({
                                id: item.id,
                                isDefault: item.isDefault,
                              })
                            }
                            size={20}
                            className="text-red-color-shade"
                          />
                          Confirm
                        </button>
                      ) : (
                        <>
                          <span>
                            <CardIcon brand={item.card.brand} />
                          </span>
                          <span>
                            <IoIosCloseCircleOutline
                              onClick={() => setDeletingId(item.id)}
                              className="text-red-color-shade"
                              size={30}
                            />
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="seperator-line">
                    <HorizontalLine />
                  </div>
                </div>
              )
            })}
          </div>
          <Accordion
            allowZeroExpanded
            preExpanded={expandedAddMethod}
            onChange={handleAddMethodAccordion}
            className="custom-accordion no-arrow"
          >
            <AccordionItem>
              <AccordionItemButton>
                <p className="add-payment-text">Add new payment method</p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <div className="padding-h-4">
                  <div className="width-max">
                    <div className="payment-outer-element">
                      <div className="payment-inner-element">
                        <PaymentElement />
                      </div>
                    </div>
                  </div>
                  <button
                    onClick={onAddPayment}
                    className="btn-dark-default save-btn-style"
                    disabled={addPaymentMutation.isLoading}
                  >
                    {addPaymentMutation.isLoading && <SpinnerIconWhite />}
                    <span>Save payment method</span>
                  </button>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          {isModifying && (
            <div className="is-modifying-btn">
              <button
                onClick={() => {
                  setModifyId(0)
                  setIsModifying(false)
                }}
                className="btn-light-border"
              >
                Cancel
              </button>
              <button
                onClick={() => onSetDefault(modifyId)}
                className="btn-green-default"
              >
                Confirm
              </button>
            </div>
          )}
        </div>
      </div>
    </CustomFontComponent>
  )
}
loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)
const ModifyPayments = ({ bookId }) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "setup",
        currency: "gbp",
        appearance: {
          // theme: "night",
          variables: {
            // colorBackground: "#131C2B",
            // colorPrimary: "#FEFFFA",
          },
          rules: {
            ".AccordionItem": {
              border: 0,
              boxShadow: "none",
            },
          },
        },
      }}
    >
      <ModifyPaymentMain bookId={bookId} />
    </Elements>
  )
}

export default ModifyPayments
