import React, { useState } from "react"
import { Outlet, useParams } from "react-router"
import { useQuery } from "@tanstack/react-query"
import { LazyLoadImage } from "react-lazy-load-image-component"

import {
  fetchReservationInfo,
  fetchRestaurantInfo,
} from "../../api/v2/reservations"
import GuestConfirm from "./confirm"
import GuestInformation from "./information"
import { fetchBrandDetails } from "../../api/v2/brand"
import { getSubtenantId } from "../../utils/helperFunctions"
import SkeletonView from "../../components/custom/SkeletonView"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import "./reservations.css"

const Reservations = () => {
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params?.subtenantId)
  const bookingID = params?.bookingID
  const [isBrandLogoLoading, setIsBrandLogoLoading] = useState(true)
  const {
    data: restaurantData,
    isLoading: isLoadingRestaurant,
    isError: isErrorRestaurant,
  } = useQuery({
    queryKey: [tenantId],
    queryFn: () => fetchRestaurantInfo(tenantId, subtenantId),
    retry: false,
  })

  const {
    data: brandData,
    isError: onBrandError,
    isLoading: brandLoading,
  } = useQuery({
    queryKey: ["brand-info"],
    queryFn: () => fetchBrandDetails(tenantId, subtenantId),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!tenantId,
  })

  const {
    data: reservationData,
    isLoading: isLoadingReservation,
    isError: isErrorReservation,
    refetch,
  } = useQuery({
    queryKey: [bookingID],
    queryFn: () => fetchReservationInfo(bookingID),
    retry: false,
  })

  if (isErrorRestaurant)
    return (
      <div className="guest-no-record-found-div">
        <h1 className="guest-no-record-text">Resturant Not Found</h1>
      </div>
    )

  if (isErrorReservation)
    return (
      <div className="guest-no-record-found-div">
        <h1 className="guest-no-record-text">Reservation Not Found</h1>
      </div>
    )
  if (reservationData && reservationData.status === "cancelled")
    return (
      <div className="guest-no-record-found-div">
        <h1 className="guest-no-record-text">
          This Reservation has been cancelled
        </h1>
      </div>
    )

  return (
    <CustomFontComponent fontFamily={brandData?.data?.font ?? ""}>
      <div className="guest-main-div">
        <div className="guest-top-section">
          <div className="guest-top-inner-section">
            {brandLoading ||
              (isBrandLogoLoading && (
                <div className="absolute">
                  <SkeletonView className="guest-logo-skeleton" />
                </div>
              ))}
            {brandData?.data && (
              <LazyLoadImage
                src={brandData?.data?.logo}
                alt="restaurant_logo"
                className="logo-width"
                onLoad={() => setIsBrandLogoLoading(false)}
                onError={() => setIsBrandLogoLoading(true)}
              />
            )}
          </div>
          <div className="guest-middle-section">
            {isLoadingRestaurant || isLoadingReservation ? (
              <SkeletonView
                animation="wave"
                mainClass="guest-skeleton-main"
                className="guest-skeleton-inner"
              />
            ) : (
              <Outlet
                context={{
                  restaurantData,
                  reservationData,
                  refetch,
                  brandData,
                }}
              />
            )}
          </div>
        </div>
      </div>
    </CustomFontComponent>
  )
}

export default Reservations

export { GuestConfirm, GuestInformation }
