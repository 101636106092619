import DetermineHostname from "../configs/DetermineHostname"

const sendBeforeChargeSMS = async (reservationID) => {
  const response = await fetch(
    `${DetermineHostname().hostname}bills/send-pre-charge-SMS/${reservationID}`,
    {
      method: "POST",
    },
  )
  if (!response.ok) throw new Error((await response.json())?.msg)
}
const manualCharge = async (reservationID) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/manual-charge/" + reservationID,
    {
      method: "POST",
    },
  )
  const data = await response.json()

  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const fetchBillInfo = async (billID) => {
  const response = await fetch(
    DetermineHostname().hostname + `bills/bill-info/${billID}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const fetchBlinqBillInfo = async (reservationID) => {
  const response = await fetch(
    DetermineHostname().hostname + `bills/blinq-bill-info/${reservationID}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const payBillInFull = async ({
  billID,
  stripeCustomerID,
  paymentMethodID,
  isOwner,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + `bills/pay-full/${billID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        stripeCustomerID,
        paymentMethodID,
        isOwner,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const splitBill = async ({
  billID,
  portion,
  stripeCustomerID,
  isOwner,
  guestID,
  newGuestsCount,
  paymentMethodID,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/split-bill/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billID,
        portion,
        stripeCustomerID,
        isOwner,
        guestID,
        guestsCount: newGuestsCount,
        paymentMethodID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const splitBillApplePayment = async ({
  billID,
  portion,
  email,
  name,
  isOwner,
  paymentMethod,
  paymentType,
  paymentIntent,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/split-bill-apple-payment/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billID,
        portion,
        email,
        name,
        isOwner,
        paymentMethod,
        paymentType,
        paymentIntent,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const payInFullApplePayment = async ({
  billID,
  email,
  name,
  isOwner,
  paymentMethod,
  paymentType,
  paymentIntent,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + `bills/pay-full-apple-payment/${billID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billID,
        email,
        name,
        isOwner,
        paymentMethod,
        paymentType,
        paymentIntent,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const mailBill = async ({ billID, email, paid }) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/bill-mail/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billID,
        email,
        paid,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const createPaymentIntent = async ({ amountInPence, description, meta }) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/create-payment-intent/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        amountInPence,
        description,
        meta,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const increaseGuestsCount = async ({ reservationID, guestCount }) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/increase-guest-count/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
        guestCount,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const getBillInfoByResID = async (reservationID) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/bill-info-by-res-id/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const getBillInfo = async (billID) => {
  const response = await fetch(
    DetermineHostname().hostname + `bills/bill-info/${billID}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}

const createReservationBill = async (reservationID) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/create-reservation-bill/`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
      }),
    },
  )
  if (response.ok) {
    return await response.text()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const changeReservationStatus = async ({ reservation_id, status }) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}svrhooksV2/change-status`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservation_id,
        status,
      }),
    },
  )
  if (response.ok) {
    return await response.text()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const billGuestProblem = async ({ reservationID, problemMessage }) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/bill-guest-problem/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
        problemMessage,
      }),
    },
  )
  if (response.ok) {
    return await response.text()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const payInFull3DS = async ({ invoiceID, paymentIntentID }) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/pay-full-3ds/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        invoiceID,
        paymentIntentID,
      }),
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const payInSplit3DS = async ({ invoiceID, paymentIntentID, portion }) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/pay-split-3ds/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        invoiceID,
        paymentIntentID,
        portion,
      }),
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const updateSvrReservation = async ({ reservation_id, data }) => {
  const response = await fetch(
    DetermineHostname().hostname + "svrhooks/update-reservation/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservation_id,
        data,
      }),
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const addChargeToSvr = async ({
  reservation_id,
  paymentIntentID,
  amountInPound,
  subtotal,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + "svrhooks/add-charge/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservation_id,
        paymentIntentID,
        amountInPound,
        subtotal,
      }),
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}

const refundPartial = async ({
  reservationID,
  paymentIntent,
  refundAmount,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + `bills/refund-partial/${reservationID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        paymentIntent,
        refundAmount,
      }),
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const refundFull = async ({ billID }) => {
  const response = await fetch(
    DetermineHostname().hostname + `bills/refund-full/${billID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const addPaymentFailure = async ({
  reservationID,
  payment_intent,
  payment_method,
  isOwner,
  reason,
}) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/add-payment-failed",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
        payment_intent,
        payment_method,
        isOwner,
        reason,
      }),
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}

const getPaymentMethod = async (paymentMethodID) => {
  const response = await fetch(
    `${DetermineHostname().hostname}bills/get-payment-method-stripe/${paymentMethodID}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.msg)
}
const addStripePaymentLog = async ({
  reservationID,
  stripeLog = {},
  errorLog = {},
  zumiLog = {},
}) => {
  const response = await fetch(
    DetermineHostname().hostname + "bills/add-stripe-payment-log/",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
        stripeLog,
        zumiLog,
        errorLog,
      }),
    },
  )
  if (response.ok) {
    return await response.json()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}

export {
  changeReservationStatus,
  createReservationBill,
  sendBeforeChargeSMS,
  manualCharge,
  fetchBillInfo,
  payBillInFull,
  splitBill,
  mailBill,
  createPaymentIntent,
  increaseGuestsCount,
  fetchBlinqBillInfo,
  splitBillApplePayment,
  payInFullApplePayment,
  getBillInfoByResID,
  getBillInfo,
  billGuestProblem,
  payInFull3DS,
  payInSplit3DS,
  updateSvrReservation,
  addChargeToSvr,
  refundPartial,
  refundFull,
  addPaymentFailure,
  getPaymentMethod,
  addStripePaymentLog,
}
