import React from "react"
import { createTheme, ThemeProvider } from "@mui/material/styles"

function MuiFontTheme({ font, children }) {
  const theme = createTheme({
    typography: {
      fontFamily: font || "Roboto",
    },
  })

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default MuiFontTheme
