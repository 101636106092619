import React from "react"

const DashedDivider = ({ color }) => {
  const dividerStyle = {
    borderTop: `1px dashed ${color || "#FEFFFA33"}`,
    width: "100%",
  }

  return <div style={dividerStyle}></div>
}

export default DashedDivider
