import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import React from "react"

import { BurgerIcon } from "../../components/custom/Icons"
import { fetchBillInfo, fetchBlinqBillInfo } from "../../api/bills"
import { useQuery } from "@tanstack/react-query"
import { CircularProgress, Collapse, Skeleton } from "@mui/material"
import { useEffect, useState } from "react"
import { fetchRestaurantInfo, getGuestsNumber } from "../../api/reservations"
import GoBack from "../../components/custom/Goback"
import { CalendarMonthOutlined, PersonOutline } from "@mui/icons-material"
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri"
import { db } from "../../firebaseConfig"
import { doc, onSnapshot } from "firebase/firestore"
import CustomHR from "../../components/custom/CustomHR"
import MuiFontTheme from "../../components/custom/MuiTheme"

const Bills = () => {
  const { reservationID } = useParams()
  let [searchParams] = useSearchParams()
  const location = useLocation()
  const nav = useNavigate()
  const [portionPaid, setPortionPaid] = useState()
  const isInvited = searchParams.has("invited")
  const isCard = location.pathname.includes("card")
  const guestID = searchParams.get("id")
  const [statusPaid, setStatusPaid] = useState()
  const [showPaymentBtn, setShowPaymentBtn] = useState(true)
  const [isBillPayingPage, setIsBillPayingPage] = useState(true)
  const [pauseUpdate, setPauseUpdate] = useState(false)
  const [open, setOpen] = useState(true)

  const { data: blinq, isError: onBlinqError } = useQuery({
    queryKey: ["blinq-bill-info", reservationID],
    queryFn: () => fetchBlinqBillInfo(reservationID),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: !pauseUpdate && isBillPayingPage && 25000,
    refetchIntervalInBackground: true,
  })

  const {
    data: restaurantInfo,
    isError: onRestaurantError,
    isLoading: restaurantLoading,
  } = useQuery({
    queryKey: ["restaurant-info"],
    queryFn: () => fetchRestaurantInfo(blinq?.reservationData?.restaurantID),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!blinq?.reservationData?.restaurantID,
  })

  const {
    data,
    isLoading,
    isError,
    refetch: invoiceRefetch,
  } = useQuery({
    queryKey: ["bill-info", blinq?.invoiceID],
    queryFn: () => fetchBillInfo(blinq?.invoiceID),
    retry: false,
    enabled: !!blinq?.invoiceID,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchInterval: !pauseUpdate && isBillPayingPage && 30000,
    refetchIntervalInBackground: true,
  })

  const {
    data: guestsNumber,
    isLoading: isLoadingGuest,
    isError: isErrorGuest,
    isRefetching: isRefetchingGuest,
  } = useQuery({
    queryKey: ["guestsNumber"],
    queryFn: () => getGuestsNumber(data.restaurantID),
    retry: false,
    enabled: !!data,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  })

  useEffect(() => {
    const isBillPage = !location.pathname.includes("split" || "card")
    const isGuestLocked = data?.guestsCountUpdated
    setIsBillPayingPage(isBillPage || !isGuestLocked)
  }, [location, data])

  useEffect(() => {
    if (!data) return
    if (!statusPaid) {
      setStatusPaid(data.bill_status === "paid")
    }
    // if (isInvited && !guestID) nav("/");
    // if (isInvited && guestID) {
    //   (data.NewBookingID, guestID).catch((err) => {
    //     nav("/");
    //   });
    // }
  }, [isInvited, guestID, nav, data])

  useEffect(() => {
    if (!invoiceRefetch || !reservationID || !blinq?.invoiceID) return
    let ignoreFirstTime = true
    const query = doc(db, "reservations", reservationID)
    const invoiceSnapShot = onSnapshot(query, async (_doc) => {
      if (ignoreFirstTime) return (ignoreFirstTime = false)
      await invoiceRefetch()
    })
    return () => invoiceSnapShot()
  }, [invoiceRefetch, blinq?.invoiceID, reservationID])

  useEffect(() => {
    if (!invoiceRefetch || !reservationID || !blinq?.invoiceID) return
    let ignoreFirstTime = true
    const query = doc(db, "invoices", blinq?.invoiceID)
    const invoiceSnapShot = onSnapshot(query, async (_doc) => {
      if (ignoreFirstTime) return (ignoreFirstTime = false)
      await invoiceRefetch()
    })
    return () => invoiceSnapShot()
  }, [invoiceRefetch, blinq?.invoiceID, reservationID])

  if (isError || isErrorGuest || onBlinqError || onRestaurantError)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center ">
        <h1 className="text-slate-50 text-[40px] font-extrabold leading-[60px]">
          Invoice Not Found
        </h1>
      </div>
    )

  if (
    restaurantInfo?.emergency_shutoff ||
    blinq?.invoiceID === null ||
    data?.tables?.length === 0
  )
    return (
      <MuiFontTheme font={restaurantInfo?.main_font ?? ""}>
        <div
          className={`dark`}
          style={{ fontFamily: restaurantInfo?.main_font ?? "" }}
          id="dark_layout"
        >
          <div className="flex justify-between items-center flex-row-reverse w-full bg-[#17243A] h-[60px]  pt-[60px] pb-[40px] px-[15px] lg:px-[65px]">
            <Link to={"/menu"}>
              <BurgerIcon />
            </Link>
            {restaurantLoading ? (
              <Skeleton
                animation="wave"
                className="w-[100px] h-[60px] rounded-xl"
              />
            ) : (
              <img
                src={restaurantInfo?.endOfNight_logo}
                alt="restaurant_logo"
                className="max-w-[160px]"
              />
            )}
            {isCard ? <GoBack color="white" /> : <span className="w-[24px]" />}
          </div>
          <div className=" w-full min-h-[calc(100vh-120px)] lg:min-h-[calc(100vh-120px)] lg:max-w-[50%] relative flex flex-col items-center gap-y-[18px] py-[18px] lg:py-[45px] bg-[#0B172C] overflow-hidden">
            <div onClick={() => setOpen(!open)} className="w-full px-4">
              <div className="flex align-center justify-between cursor-pointer">
                <h6 className="text-[#9AAAFF] font-semibold text-[20px]">
                  Your bill #{blinq.reservationData.reservationCode}
                </h6>
                {open ? (
                  <RiArrowUpSLine size={26} color="#9AAAFF" />
                ) : (
                  <RiArrowDownSLine size={26} color="#9AAAFF" />
                )}
              </div>
            </div>
            <Collapse in={open} className="w-full px-4">
              <div className="w-full flex flex-col gap-y-3">
                <div>
                  <p className="flex align-center gap-2 text-[#FEFFFA]">
                    <PersonOutline /> {blinq.reservationData.guestsCount} guests
                  </p>
                  <p className="flex align-center gap-2 text-[#FEFFFA]">
                    <CalendarMonthOutlined />{" "}
                    {new Date(blinq.reservationData.dateTime).toDateString()}
                  </p>
                </div>
              </div>
            </Collapse>
            <CustomHR />
            <div className="w-full flex flex-col py-5">
              <div>
                <div className="flex justify-center">
                  <img src="/icons/bill.svg" alt="empty_bill" />
                </div>
                <p className="text-[20px] text-[#EBFFA8] text-center my-2">
                  No Items
                </p>
                <p className="text-center">
                  Items will be added to your bill as you order.
                </p>
              </div>
            </div>
            <CustomHR />
          </div>
        </div>
      </MuiFontTheme>
    )

  return (
    <MuiFontTheme font={restaurantInfo?.main_font ?? ""}>
      <div
        className={`dark`}
        style={{ fontFamily: restaurantInfo?.main_font ?? "" }}
        id="dark_layout"
      >
        <div className="flex justify-between items-center flex-row-reverse w-full bg-[#17243A] h-[60px]  pt-[60px] pb-[40px] px-[15px] lg:px-[65px] ">
          <Link to={"/menu"}>
            <BurgerIcon />
          </Link>
          {restaurantLoading ? (
            <Skeleton
              animation="wave"
              className="w-[100px] h-[60px] rounded-xl"
            />
          ) : (
            <img
              src={restaurantInfo?.endOfNight_logo}
              alt="restaurant_logo"
              className="max-w-[160px]"
            />
          )}
          {isCard ? <GoBack color="white" /> : <span className="w-[24px]" />}
        </div>
        {isLoading || isLoadingGuest || isRefetchingGuest ? (
          <div className="flex flex-col flex-1 gap-3 justify-center items-center text-custom-primary">
            <CircularProgress size={70} color={"inherit"} />
            <p className="text-custom-primary">Fetching your bill...</p>
          </div>
        ) : (
          <Outlet
            context={{
              invoiceData: data,
              isInvited,
              guestID,
              statusPaid,
              setStatusPaid,
              invoiceRefetch,
              invoiceID: blinq.invoiceID,
              setShowPaymentBtn,
              showPaymentBtn,
              guestsNumber,
              setPortionPaid,
              portionPaid,
              setPauseUpdate,
              restaurantInfo,
            }}
          />
        )}
      </div>
    </MuiFontTheme>
  )
}

export default Bills
