import React from "react"

const CustomHR = ({ color, className }) => {
  return (
    <div
      className={className || ""}
      style={{
        width: "100%",
        height: "1px",
        background: `${color || "#424954"}`,
      }}
    ></div>
  )
}

export default CustomHR
