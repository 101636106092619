import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

const SelectedDateContext = createContext()

export const SelectedDateProvider = ({ children }) => {
  const [selectedDate, setSelecedDate] = useState(new Date())
  return (
    <SelectedDateContext.Provider
      value={{
        selectedDate,
        setSelecedDate,
      }}
    >
      {children}
    </SelectedDateContext.Provider>
  )
}

SelectedDateProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SelectedDateContext
