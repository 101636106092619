import { useState } from "react"
import LargeTableComponet from "../components/largeTableComponent"
import DetermineHostname from "../../configs/DetermineHostname"
import StatusComponent from "../components/StatusComponent"
import { useParams } from "react-router"
import { getSubtenantId } from "../../utils/helperFunctions"

function ChecksPage() {
  const [checks, setChecks] = useState([])
  const [checksLoading, setchecksloading] = useState(true)
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params["*"])

  const fetchChecks = (status, date) => {
    setchecksloading(true)
    let url = `${DetermineHostname().hostV2}billsV2/all-bills?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&${date ? "date=" + date : ""}`
    if (status) {
      url = `${DetermineHostname().hostV2} +
        billsV2/all-bills?tenantId=${tenantId}${subtenantId ? `&subtenantId=${subtenantId}` : ""}&?status=${status}${date ? "&date=" + date : ""}`
    }
    return fetch(url)
      .then((res) => res.json())
      .then((d) => {
        setChecks(d.data)
        setchecksloading(false)
      })
  }
  const checksHeaders = [
    { id: 1, name: "First Name", key: "firstName", sortable: true },
    { id: 8, name: "Last Name", key: "lastName", sortable: true },
    { id: 2, name: "Customer Email", key: "guestEmail", sortable: true },
    { id: 3, name: "Check total", key: "price", sortable: true },
    { id: 4, name: "Creation Date", key: "date", sortable: true },
    { id: 5, name: "Table", key: "table", sortable: true },
    {
      id: 7,
      name: "Status",
      key: "status",
      sortable: true,
      renderContent(row, _col) {
        return <StatusComponent status={row["status"]} />
      },
    },
  ]

  return (
    <>
      <main className="p-6 m-2 rounded-lg bg-[#FFF] min-h-screen">
        <LargeTableComponet
          openRow={true}
          hasDateFilter={false}
          headers={checksHeaders}
          fetchReservations={fetchChecks}
          data={checks}
          title="Checks"
          extraClassName="h-full"
          selectToday={true}
          loading={checksLoading}
          from={"checks"}
        />
      </main>
    </>
  )
}

export default ChecksPage
