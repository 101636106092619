import React, { createContext, useState } from "react"
import PropTypes from "prop-types"

const AdminDashboardContext = createContext()

export const AdminDashboardProvider = ({ children }) => {
  const [sidebar, setSidebar] = useState(false)
  return (
    <AdminDashboardContext.Provider
      value={{
        sidebar,
        setSidebar,
      }}
    >
      {children}
    </AdminDashboardContext.Provider>
  )
}

AdminDashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AdminDashboardContext
