import { React, useEffect, useState } from "react"
import Card from "@mui/material/Card"
import ActionIcon from "../../components/ActionIcon"
import StatusComponent from "../../components/StatusComponent"
import { Box } from "@mui/system"
import LargeTableComponet from "../../components/largeTableComponent"
import styles from "./reserVationPage.module.css"
import { Button, TextField, Typography } from "@mui/material"
import SendIcon from "@mui/icons-material/Send"
import PaidIcon from "@mui/icons-material/Paid"
import ReceiptIcon from "@mui/icons-material/Receipt"
import Modal from "@mui/material/Modal"
import { useMutation } from "@tanstack/react-query"
import moment from "moment"
import { toast } from "react-toastify"
import { calculateUnpaid } from "../../../utils/helperFunctions"
import { TfiReload } from "react-icons/tfi"
import { db } from "../../../firebaseConfig"
import { doc, onSnapshot } from "firebase/firestore"
import RefundDialog from "../../components/RefundDialog"
import {
  changeReservationStatus,
  createReservationBill,
  getBillInfoByResID,
  manualCharge,
  refundFull,
  refundPartial,
  sendBeforeChargeSMS,
} from "../../../api/v2/bills"

function PaymentDetails({ data, reservationID, refetchReservation }) {
  const [dataSet, setDataSet] = useState(data)
  const [guestsHeaders, setGuestHeaders] = useState([])
  const [openRefundModal, setOpenRefundModal] = useState(false)
  const [modalData, setModalData] = useState({})
  const [refundAmount, setRefundAmount] = useState(0)
  const [openDialog, setOpenDialog] = useState(false)

  const handleOpenModal = () => setOpenRefundModal(true)
  const handleCloseModal = () => {
    setOpenRefundModal(false)
    setRefundAmount(0)
  }
  useEffect(() => {
    setDataSet(data)
  }, [data])

  useEffect(() => {
    let count = 0
    const query = doc(db, "reservations", reservationID)
    const unsubscribe = onSnapshot(query, async (_doc) => {
      count++
      if (count > 1) {
        refetchReservation()
      }
    })
    return () => unsubscribe()
  }, [])

  const manualChargeMutation = useMutation({
    mutationFn: manualCharge,
    onSuccess: async () => {
      const newData = await getBillInfoByResID(reservationID)
      setDataSet([newData])
      toast.success("Payment success", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
    onError: (err) => {
      toast.error(err.message || "Payment field", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })

  const sendSMS = async () => {
    try {
      await sendBeforeChargeSMS(reservationID)
      toast.success("SMS Sent Successful", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    } catch (error) {
      toast.error(error.message || "SMS didn't Reach", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    }
  }
  const manualChargeHandle = () => {
    manualChargeMutation.mutate(reservationID)
  }

  const createBillMutation = useMutation({
    mutationFn: createReservationBill,
    onSuccess: () => {
      refetchReservation()
    },
    onError: () => {
      toast.error("create bill failed", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })

  const changeReservationStatusMutation = useMutation({
    mutationFn: changeReservationStatus,
    onSuccess: () => {
      refetchReservation()
    },
    onError: (err) => {
      toast.error(err.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })

  const refundPartialMutation = useMutation({
    mutationFn: refundPartial,
    onSuccess: () => {
      refetchReservation()
      handleCloseModal()
      toast.success("Refund Successful", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
    onError: (err) => {
      toast.error(err.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })
  const refundFullMutation = useMutation({
    mutationFn: refundFull,
    onSuccess: () => {
      refetchReservation()
      handleCloseModal()
      toast.success("Refund Successful", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
    onError: (err) => {
      toast.error(err.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })
  const createBill = async () => {
    createBillMutation.mutate(reservationID)
  }

  const changeStatus = async () => {
    changeReservationStatusMutation.mutate({
      reservation_id: reservationID,
      status: "ARRIVED",
    })
  }

  const handleRefund = async () => {
    if (
      refundAmount >
      (modalData?.stripeAmountInPence - (modalData?.refundedAmount ?? 0)) / 100
    ) {
      toast.error("Refund amount exceeds charged amount")
      return
    }
    refundPartialMutation.mutate({
      reservationID: data[0]?.reservationID,
      refundAmount: Number(refundAmount),
      paymentIntent:
        data[0]?.pay_method === "split"
          ? modalData?.paymentIntentID
          : data[0]?.paymentIntents[0],
    })
    // Call your refund API here with the refundAmount
    // Make sure to handle the response and any potential errors
  }

  const handleCloseRefundDialog = () => {
    setOpenDialog(false)
  }

  const handleFullRefund = () => {
    // Add your logic here
    refundFullMutation.mutate({ billID: data[0]?.billID })
    setOpenDialog(false)
  }

  useEffect(() => {
    if (data?.[0].splitters) {
      const reservationAndBillInfo = data[0]
      const splitters = reservationAndBillInfo.splitters.map((splitter) => ({
        ...splitter,
        amount: (
          splitter.portion *
          (reservationAndBillInfo.price / reservationAndBillInfo.guestsCount)
        ).toFixed(2),
      }))
      //
      setDataSet(splitters)
      setGuestHeaders([
        {
          id: 8,
          name: "Payment Method",
          key: "paymentType",
          sortable: true,
        },
        {
          id: 9,
          name: "Last 4 Digits",
          key: "payment_last4",
          sortable: true,
        },
        { id: 1, name: "Email", key: "email", sortable: true },
        {
          id: 1,
          name: "stripeCustomerID",
          key: "stripeCustomerID",
          sortable: true,
        },
        {
          id: 7,
          name: "Unpaid Amount",
          key: "amount_unpaid",
          sortable: true,
          renderContent(row, col, { rowNumber }) {
            return calculateUnpaid(data[0], rowNumber)
          },
        },
        { id: 1, name: "Charged Amount", key: "amount", sortable: true },
        {
          id: 1,
          name: "Refunded Amount",
          key: "refundedAmount",
          sortable: true,
          renderContent(data) {
            // eslint-disable-next-line react/prop-types
            return <p>{data.refundedAmount / 100}</p>
          },
        },
        {
          id: 6,
          name: "Action",
          key: "action",
          sortable: true,
          renderContent(data) {
            return (
              <ActionIcon
                title={"Refund"}
                onClick={() => {
                  handleOpenModal()
                  setModalData(data)
                }}
              />
            )
          },
        },
      ])
    } else if (!data?.[0].splitters) {
      setDataSet(data)
      setGuestHeaders([
        {
          id: 8,
          name: "Payment Method",
          key: "guestPaymentType",
          sortable: true,
        },
        {
          id: 9,
          name: "Last 4 Digits",
          key: "payment_last4",
          sortable: true,
        },
        { id: 1, name: "First Name", key: "firstName", sortable: true },
        { id: 2, name: "Last Name", key: "lastName", sortable: true },
        {
          id: 3,
          name: "Stripe Customer ID",
          key: "stripeCustomerID",
          sortable: true,
        },
        { id: 4, name: "Amount due", key: "price", sortable: true },
        { id: 7, name: "Unpaid Amount", key: "amount_unpaid", sortable: true },
        {
          id: 8,
          name: "Charged Amount",
          key: "amount",
          sortable: true,
          renderContent(data) {
            return data.bill_status === "paid" ? <p>{data.price}</p> : <p>0</p>
          },
        },
        {
          id: 5,
          name: "Status",
          key: "status",
          sortable: true,
          renderContent(row) {
            return <StatusComponent status={row.bill_status} />
          },
        },
        {
          id: 6,
          name: "Action",
          key: "action",
          sortable: true,
          renderContent(data) {
            return (
              <ActionIcon
                title={"Refund"}
                onClick={() => {
                  handleOpenModal()
                  setModalData(data)
                }}
              />
            )
          },
        },
      ])
    }
  }, [data])

  return (
    <>
      <Card sx={{ flexDirection: "row", p: 2, m: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            p: 2,
            width: "100%",
            alignItems: "center",
          }}
        >
          <span>
            <p className={styles.totalTitle}> Total </p>
            <p className={styles.Total}>
              {" "}
              £{data[0] && data[0].price ? data[0].price : 0}{" "}
            </p>
          </span>
          <span>
            <p className={styles.totalTitle}> Due </p>
            <p className={styles.Due}>
              {" "}
              {moment(data[0]?.dateTime).format("YYYY-MM-DD")}
            </p>
          </span>
          <Button
            disabled={
              (data?.[0]?.guestSmsLock && data?.[0]?.guestsCount > 6) ||
              !data?.[0]?.bill_sms_status ||
              !(
                (data?.[0]?.booked_by_api &&
                  data?.[0]?.booked_by === "Alberti API") ||
                (data?.[0]?.booked_by_wedgit &&
                  data?.[0]?.booked_by === "Booking Widget") ||
                (data?.[0]?.booked_by_walkin &&
                  data?.[0]?.booked_by === "Walk In") ||
                (data?.[0]?.booked_by_other &&
                  !["Alberti API", "Booking Widget", "Walk In"].includes(
                    data?.[0]?.booked_by,
                  ))
              )
            }
            onClick={async () => {
              await sendSMS()
            }}
            variant="contained"
            endIcon={<SendIcon />}
          >
            Send SMS
          </Button>

          <Button
            onClick={() => {
              manualChargeHandle()
            }}
            variant="contained"
            endIcon={<PaidIcon />}
          >
            Manual Charge
          </Button>
          <Button
            onClick={async () => {
              await changeStatus()
            }}
            variant="contained"
            endIcon={<ReceiptIcon />}
            disabled={
              changeReservationStatusMutation.isLoading ||
              changeReservationStatusMutation.isSuccess ||
              data?.[0].status === "Seated" ||
              data?.[0].status === "Order Placed"
            }
          >
            Set Seated Status
          </Button>
          <Button
            onClick={async () => {
              await createBill()
            }}
            variant="contained"
            endIcon={<ReceiptIcon />}
            disabled={createBillMutation.isLoading}
          >
            Create Bill
          </Button>

          <Button
            onClick={() => setOpenDialog(true)}
            disabled={
              refundFullMutation.isLoading ||
              data[0]?.pay_total === 0 ||
              data[0]?.refund_status === "refunded" ||
              data[0]?.refundedAmount > 0 ||
              data[0]?.splitters?.some(
                (splitter) => splitter.refundedAmount > 0,
              )
            }
            variant="contained"
            endIcon={<TfiReload />}
          >
            Refund All Payments
          </Button>
        </Box>

        <span>
          <LargeTableComponet
            hasSearch={false}
            hasSortFilter={false}
            hasDateFilter={false}
            headers={guestsHeaders}
            data={dataSet}
            title="Payment details"
          />
        </span>
        <Modal open={openRefundModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "white",
              boxShadow: 24,
              p: 2, // Add padding
              display: "flex", // Add display
              flexDirection: "column", // Add flexDirection
              alignItems: "center", // Add alignItems
            }}
          >
            <Typography variant="h6" component="h2">
              Refund
            </Typography>
            <Typography sx={{ mt: 2 }}>
              Amount Charged:{" "}
              {modalData?.stripeAmountInPence >= 0 && data[0]?.pay_total > 0
                ? modalData?.stripeAmountInPence / 100
                : 0}
            </Typography>
            <TextField
              disabled={
                data[0]?.pay_total === 0 ||
                modalData?.stripeAmountInPence -
                  (modalData?.refundedAmount ?? 0) ===
                  0
              }
              type="number"
              inputProps={{
                min: "0",
                max:
                  (modalData.stripeAmountInPence -
                    (modalData.refundedAmount ?? 0)) /
                  100,
              }}
              value={refundAmount}
              onChange={(e) => setRefundAmount(e.target.value)}
              sx={{ mt: 2 }} // Add margin top
            />
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
              Maximum refundable amount:{" "}
              {modalData?.stripeAmountInPence >= 0 && data[0]?.pay_total > 0
                ? (modalData?.stripeAmountInPence -
                    (modalData.refundedAmount ?? 0)) /
                  100
                : 0}
            </Typography>
            <Button
              disabled={
                data[0]?.pay_total === 0 ||
                refundPartialMutation.isLoading ||
                Number(refundAmount) <= 0 ||
                modalData.stripeAmountInPence - modalData.refundedAmount === 0
              }
              variant="contained"
              color="primary"
              onClick={handleRefund}
              sx={{ mt: 2 }} // Add margin top
            >
              {modalData?.stripeAmountInPence - modalData?.refundedAmount === 0
                ? "Refunded Already"
                : "Refund"}
            </Button>
          </Box>
        </Modal>
        <RefundDialog
          onSubmit={handleFullRefund}
          handleClose={handleCloseRefundDialog}
          open={openDialog}
          title={"Refund All Payments"}
          text={
            "Refund will refund all payments for this particular reservation. To refund 1 payment please select refund option for that particular payment and enter refund amount."
          }
        />
      </Card>
    </>
  )
}

export default PaymentDetails
