import React from "react"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import GuestDetails from "./GuestDetails"
import PaymentDetails from "./PaymentDetails"
import { useNavigate, useParams } from "react-router-dom"
import HeaderBox from "./HeaderBox"
import styles from "./reserVationPage.module.css"
import { useQuery } from "@tanstack/react-query"
import BillDetails from "./BillDetails"
import PaymentsFailed from "./PaymentsFailed"
import { getBillInfoByResID } from "../../../api/v2/bills"

function ReservationSingle({ restaurantData }) {
  const params = useParams()
  const reservationId = params.id
  const navigate = useNavigate()
  const goHome = () => {
    navigate(window.location.pathname.replace(`/${reservationId}`, ""))
  }

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["reservationData"],
    queryFn: () => getBillInfoByResID(reservationId),
    retry: false,
  })

  if (isLoading) return null

  if (isError)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          Reservation Not Found
        </h1>
      </div>
    )
  return (
    <>
      <span className={styles.title} onClick={() => goHome()}>
        <ArrowBackIosIcon color="action" /> All Reservations
      </span>
      {/*Card Deails */}
      <HeaderBox data={data} refetchReservation={refetch} />
      <GuestDetails
        data={data ? [{ ...data, ...restaurantData }] : []}
        refetchReservation={refetch}
      />
      <PaymentDetails
        data={data ? [{ ...data, ...restaurantData }] : []}
        reservationID={reservationId}
        refetchReservation={refetch}
      />
      <PaymentsFailed data={data?.payments_failed} />
      <BillDetails data={data?.items} />
    </>
  )
}

export default ReservationSingle
