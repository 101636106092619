import React from "react"
import { Chip } from "@mui/material"
import stateColors from "../styles/colors"
import placeHolders from "../data/placeholders"

function StatusComponent({ status, className }) {
  status = status ? status : "pending "
  return (
    <Chip
      label={status.toUpperCase()}
      color="primary"
      style={{
        height: "24px",
        minWidth: "114px",
        backgroundColor:
          status === "Booked"
            ? "grey"
            : status
              ? stateColors[status]
              : placeHolders[status],
      }}
      className={className}
    />
  )
}

export default StatusComponent
