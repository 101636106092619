import React, { useEffect, useState } from "react"
import styled from "./ConfirmPayment.module.css"

const ConfirmPayment = ({ data, price, onBack, onPay }) => {
  const [last4, setLast4] = useState()

  useEffect(() => {
    data.paymentMethods.forEach((item, index) => {
      if (item.isDefault) {
        setLast4(item.card.last4)
      }
    })
  }, [])
  return (
    <div className={styled.bigContainer}>
      <div className={styled.container}>
        <p className={styled.header}>Confirm payment</p>
        <div className={styled.content}>
          <p>Your card ending in {last4} </p>
          <p>will be charged £{price} </p>
        </div>
        <div className={styled.btnPay} onClick={onPay}>
          <p>Pay now</p>
        </div>
        <div className={styled.btnCancel} onClick={onBack}>
          <p>Back to bill</p>
        </div>
      </div>
    </div>
  )
}

export default ConfirmPayment
