import * as React from "react"
import SwipeableDrawer from "@mui/material/SwipeableDrawer"
import { Check } from "@mui/icons-material"
import { toast } from "react-toastify"
import { CircularProgress } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { billGuestProblem } from "../../api/v2/bills"
import { useParams } from "react-router"

export default function BottomDrawer() {
  const [message, setMessage] = React.useState("")
  const { reservationID } = useParams()
  const submitted = false
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  })

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return
    }
    setState({ ...state, [anchor]: open })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    payInFullMutation.mutate({ reservationID, problemMessage: message })
  }

  const payInFullMutation = useMutation({
    mutationFn: billGuestProblem,
    onSuccess: () => {
      toast.success("Problem has been reported successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      toggleDrawer("bottom", false)()
      setMessage("")
    },
    onError: (err) => {
      toast.error(err.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
    },
  })

  return (
    <div>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <p
            onClick={toggleDrawer(anchor, true)}
            className="text-center underline mb-4 text-[#FEFFFACC] cursor-pointer"
          >
            Problem with your bill?
          </p>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            SlideProps={{
              style: {
                background: "#17243A",
                borderRadius: "32px 32px 0px 0px",
                padding: "30px",
              },
            }}
          >
            {submitted ? (
              <div className="gap-5 w-full">
                <p className="text-[#9AAAFF] text-center w-full">
                  <Check style={{ fontSize: "60px" }} />
                </p>
                <p className="text-[#9AAAFF] text-center text-[20px] my-4">
                  Problem submitted
                </p>
                <p className="text-center text-[#FEFFFACC] mb-5">
                  Thank you for submitting your comments. We’ll get back to you
                  shortly.{" "}
                </p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="w-full">
                <p className="text-[#9AAAFF] text-center text-[20px]">
                  Problem with your bill?
                </p>
                <textarea
                  required
                  placeholder="We’re sorry! Please tell us what happened..."
                  rows={4}
                  className="problem-text-area w-full"
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
                <button className="bg-[#ffF] w-full text-[#17243A] p-4 rounded-full mt-5 font-semibold">
                  {payInFullMutation.isLoading ? (
                    <CircularProgress sx={{ zoom: "50%" }} color="primary" />
                  ) : (
                    "Submit Problem"
                  )}
                </button>
              </form>
            )}
            <div className="items-center w-full flex justify-center mt-4">
              <p
                onClick={toggleDrawer(anchor, false)}
                className="w-[60px] text-center text-[#fff] cursor-pointer"
                style={{ borderBottom: "1px solid #fff" }}
              >
                Cancel
              </p>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  )
}
