import React, { useState } from "react"
import { useNavigate, Link, useParams } from "react-router-dom"
import { auth, googleAuthProvider, db } from "../firebaseConfig"
import { signInWithPopup } from "firebase/auth"
import { doc, setDoc, getDoc, Timestamp } from "firebase/firestore"
import googleLogo from "../assets/google_logo.png"
import { adminSignIn } from "../api/authentications"

function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const navigate = useNavigate()
  const { tenantId, subtenantId } = useParams()
  // const signIn = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const userCredential = await signInWithEmailAndPassword(
  //       auth,
  //       email,
  //       password
  //     );
  //     // Signed in
  //     const user = userCredential.user;
  //     //
  //     navigate("/invoices"); // redirect to invoices page after login
  //   } catch (error) {
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //
  //   }
  // };

  const adminLogIn = async () => {
    const response = await adminSignIn(email, password)

    if (response.status === 200) {
      const token = response.data.token
      localStorage.setItem("token", token)
      if (subtenantId) {
        navigate(`/admin/${tenantId}/${subtenantId}/reservations`)
      } else {
        navigate(`/admin/${tenantId}/reservations`)
      }
    }
    // Signed in
  }
  const signInWithGoogle = async () => {
    const result = await signInWithPopup(auth, googleAuthProvider)
    // The signed-in user info.
    const user = result.user
    //
    // Check if user exists in Firestore
    const userDocRef = doc(db, "users", user.uid)
    const userDoc = await getDoc(userDocRef)
    const currentTime = Timestamp.fromDate(new Date())
    if (!userDoc.exists()) {
      // If user doesn't exist, add them to Firestore with the role field
      await setDoc(userDocRef, {
        uid: user.uid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL,
        phoneNumber: user.phoneNumber,
        createDate: currentTime,
        updateDate: currentTime,
        role: "user", // Set the initial role for the user
      })
    }

    // Check if the user is fully logged in
    if (auth.currentUser) {
      navigate("/invoices") // redirect to invoices page after login
    }
  }

  return (
    <div className="flex items-center justify-center bg-custom-blue py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form
          className="mt-8 space-y-6"
          onSubmit={(e) => {
            e.preventDefault()
            adminLogIn()
          }}
        >
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full h-12 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-custom-button hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 2a2 2 0 00-2 2v2a2 2 0 002 2h6a2 2 0 002-2V4a2 2 0 00-2-2H5zm3 8a2 2 0 012 2v2a2 2 0 01-2 2H5a2 2 0 01-2-2v-2a2 2 0 012-2h3z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              Sign in
            </button>
          </div>
        </form>
        <div className="text-center">
          <button
            className="flex items-center justify-center space-x-2 p-4 bg-white text-black rounded-lg shadow-lg hover:bg-gray-100 w-full h-12"
            onClick={signInWithGoogle}
          >
            <img src={googleLogo} alt="Google logo" className="w-6 h-6" />
            <span>Sign in with Google</span>
          </button>
        </div>
        <div className="text-center mt-2">
          <Link to="/signup" className="text-indigo-600 hover:text-indigo-500">
            Don&apos;t have an account? Sign Up
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Login
