import DetermineHostname from "../../configs/DetermineHostname"

const sendBeforeChargeSMS = async (reservationID) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}billsV2/send-pre-charge-SMS`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ reservationId: reservationID }),
      },
    )
    if (!response.ok) throw new Error((await response.json())?.message)
  } catch (err) {
    throw err
  }
}

const manualCharge = async (reservationID) => {
  const params = {
    reservationId: reservationID,
  }
  try {
    const response = await fetch(
      DetermineHostname().hostV2 + "billsV2/manual-charge",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(params),
      },
    )
    const data = await response.json()
    if (response.ok) {
      return data
    } else throw new Error(data?.message)
  } catch (err) {
    throw new Error(err)
  }
}

const increaseGuestsCount = async ({ reservationID, guestCount }) => {
  const reservationId = reservationID
  const response = await fetch(
    DetermineHostname().hostV2 + "billsV2/increase-guest-count",
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationId,
        guestCount,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const payBillInFull = async ({
  billID,
  stripeCustomerID,
  paymentMethodID,
  isOwner,
}) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/pay-full/${billID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        stripeCustomerID,
        paymentMethodID,
        isOwner,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const splitBill = async ({
  billID,
  portion,
  stripeCustomerID,
  isOwner,
  guestID,
  newGuestsCount,
  paymentMethodID,
}) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/split-bill/`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billID,
        portion,
        stripeCustomerID,
        isOwner,
        guestID,
        guestsCount: newGuestsCount,
        paymentMethodID,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const payInFull3DS = async ({ invoiceID, paymentIntentID }) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}billsV2/pay-full-3ds/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          invoiceID,
          paymentIntentID,
        }),
      },
    )
    if (response.ok) {
      return await response.json()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}
const payInSplit3DS = async ({ invoiceID, paymentIntentID, portion }) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}billsV2/pay-split-3ds/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          invoiceID,
          paymentIntentID,
          portion,
        }),
      },
    )
    if (response.ok) {
      return await response.json()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}

const payInFullApplePayment = async ({
  billID,
  email,
  name,
  isOwner,
  paymentMethod,
  paymentType,
  paymentIntent,
}) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/pay-full-apple-payment/${billID}`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billID,
        email,
        name,
        isOwner,
        paymentMethod,
        paymentType,
        paymentIntent,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const splitBillApplePayment = async ({
  billID,
  portion,
  email,
  name,
  isOwner,
  paymentMethod,
  paymentType,
  paymentIntent,
}) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/split-bill-apple-payment/`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billID,
        portion,
        email,
        name,
        isOwner,
        paymentMethod,
        paymentType,
        paymentIntent,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const updateSvrReservation = async ({ reservation_id, data }) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}svrhooksV2/update-reservation/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          reservation_id,
          data,
        }),
      },
    )
    if (response.ok) {
      return await response.json()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}

const mailBill = async ({ billID, email, paid }) => {
  const billId = billID
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/bill-mail`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        billId,
        email,
        // paid,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const createReservationBill = async (reservationID) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/create-reservation-bill/`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservationID,
      }),
    },
  )
  if (response.ok) {
    return await response.text()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}
const changeReservationStatus = async ({ reservation_id, status }) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}svrhooksV2/change-status`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        reservation_id,
        status,
      }),
    },
  )
  if (response.ok) {
    return await response.text()
  } else {
    const err = await response.json()
    throw new Error(err?.msg || "Unexpected error")
  }
}

const getBillInfoByResID = async (reservationID) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}billsV2/bill-info-by-res-id/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          reservationID,
        }),
      },
    )
    const data = await response.json()
    if (response.ok) {
      return data
    } else throw new Error(data?.message)
  } catch (error) {
    console.error(error)
  }
}

const fetchBillInfo = async (billID) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/bill-info/${billID}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}
const fetchBlinqBillInfo = async (reservationID) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/blinq-bill-info/${reservationID}`,
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}
const createPaymentIntent = async ({ amountInPence, description, meta }) => {
  const response = await fetch(
    `${DetermineHostname().hostV2}billsV2/create-payment-intent/`,
    {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        amountInPence,
        description,
        meta,
      }),
    },
  )
  const data = await response.json()
  if (response.ok) {
    return data
  } else throw new Error(data?.message)
}

const getBillInfo = async (billID) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}billsV2/bill-info/${billID}`,
    )
    const data = await response.json()
    if (response.ok) {
      return data
    } else throw new Error(data?.message)
  } catch (error) {
    console.error(error)
  }
}

const billGuestProblem = async ({ reservationID, problemMessage }) => {
  try {
    const response = await fetch(
      DetermineHostname().hostV2 + "billsV2/bill-guest-problem/",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          reservationID,
          problemMessage,
        }),
      },
    )
    if (response.ok) {
      return await response.text()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}
const addChargeToSvr = async ({
  reservation_id,
  paymentIntentID,
  amountInPound,
  subtotal,
}) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}svrhooksV2/add-charge/`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          reservation_id,
          paymentIntentID,
          amountInPound,
          subtotal,
        }),
      },
    )
    if (response.ok) {
      return await response.json()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}
const refundPartial = async ({
  reservationID,
  paymentIntent,
  refundAmount,
}) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}billsV2/refund-partial/${reservationID}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          paymentIntent,
          refundAmount,
        }),
      },
    )
    if (response.ok) {
      return await response.json()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}
const refundFull = async ({ billID }) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}billsV2/refund-full/${billID}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
      },
    )
    if (response.ok) {
      return await response.json()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}
const addPaymentFailure = async ({
  reservationID,
  payment_intent,
  payment_method,
  isOwner,
  reason,
}) => {
  try {
    const response = await fetch(
      DetermineHostname().hostV2 + "billsV2/add-payment-failed",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          reservationID,
          payment_intent,
          payment_method,
          isOwner,
          reason,
        }),
      },
    )
    if (response.ok) {
      return await response.json()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}
const getPaymentMethod = async (paymentMethodID) => {
  try {
    const response = await fetch(
      `${DetermineHostname().hostV2}billsV2/get-payment-method-stripe/${paymentMethodID}`,
    )
    const data = await response.json()
    if (response.ok) {
      return data
    } else throw new Error(data?.message)
  } catch (error) {
    throw error
  }
}
const addStripePaymentLog = async ({
  reservationID,
  stripeLog = {},
  errorLog = {},
  zumiLog = {},
}) => {
  try {
    const response = await fetch(
      DetermineHostname().hostV2 + "billsV2/add-stripe-payment-log/",
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          reservationID,
          stripeLog,
          zumiLog,
          errorLog,
        }),
      },
    )
    if (response.ok) {
      return await response.json()
    } else {
      const err = await response.json()
      throw new Error(err?.message || "Unexpected error")
    }
  } catch (error) {
    throw error
  }
}

export {
  changeReservationStatus,
  createReservationBill,
  sendBeforeChargeSMS,
  manualCharge,
  fetchBillInfo,
  payBillInFull,
  splitBill,
  mailBill,
  createPaymentIntent,
  increaseGuestsCount,
  fetchBlinqBillInfo,
  splitBillApplePayment,
  payInFullApplePayment,
  getBillInfoByResID,
  getBillInfo,
  billGuestProblem,
  payInFull3DS,
  payInSplit3DS,
  updateSvrReservation,
  addChargeToSvr,
  refundPartial,
  refundFull,
  addPaymentFailure,
  getPaymentMethod,
  addStripePaymentLog,
}
