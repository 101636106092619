import React, { useEffect, useState } from "react"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { Button, TablePagination, TextField, Typography } from "@mui/material"
import moment from "moment-timezone"
import Card from "@mui/material/Card"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"
import BigCircularProgress from "../components/BigCircularProgress"
import TableComponet from "./tableComponent"

moment.tz.setDefault("Europe/London")

const DataRangeTable = ({
  onSearch,
  secondColData,
  headers,
  title,
  navRoot,
  paginate = false,
  tableProps = {
    hasSearch: false,
    hasSortFilter: false,
    hasDateFilter: false,
    verticalHeader: false,
  },
}) => {
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"))
  const [endDate, setEndDate] = useState(moment())
  const [data, setData] = useState()
  const [totalData, setTotalData] = useState()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (data && totalData) {
      const paginatedData = paginate
        ? totalData.map((d) =>
            d.slice(page * rowsPerPage, (page + 1) * rowsPerPage),
          )
        : totalData
      setData(paginatedData)
    }
  }, [page, rowsPerPage])

  const getDataMutate = useMutation({
    mutationFn: onSearch,
    onSuccess: (data) => {
      setTotalData(data)
      const paginatedData = paginate
        ? data.map((d) => d.slice(page * rowsPerPage, (page + 1) * rowsPerPage))
        : data
      setData(paginatedData)
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })
  const fetchData = async () => {
    getDataMutate.mutate({
      startDate: startDate.format("YYYY-MM-DD"),
      endDate: endDate.format("YYYY-MM-DD"),
    })
  }

  return (
    <div className={"flex items-center flex-col w-full"}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        dateLibInstance={moment}
      >
        <Card
          className={"flex items-center justify-center gap-4 p-4 m-2 w-fit"}
        >
          <DatePicker
            value={startDate}
            onChange={(newValue) => {
              setStartDate(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <Typography variant="p">To</Typography>
          <DatePicker
            value={endDate}
            onChange={(newValue) => {
              setEndDate(newValue)
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <Button
            disabled={getDataMutate.isLoading}
            onClick={fetchData}
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </Card>
      </LocalizationProvider>

      <Card className={"flex-row p-2 m-2 w-full lg:max-w-[90%]"}>
        <span>
          {getDataMutate.isLoading ? (
            <BigCircularProgress />
          ) : (
            <>
              {title && (
                <p
                  className="me-3"
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontFamily: "Mulish",
                    fontWeight: 600,
                    lineHeight: "22px",
                    wordWrap: "break-word",
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                >
                  {title}
                </p>
              )}
              {data && (
                <div className={"flex items-end flex-col md:flex-row gap-3"}>
                  {headers?.map((heads, index) => {
                    return (
                      <TableComponet
                        key={index}
                        hasSearch={tableProps.hasSearch}
                        hasSortFilter={tableProps.hasSortFilter}
                        hasDateFilter={tableProps.hasDateFilter}
                        headers={heads}
                        verticalHeader={tableProps.verticalHeader}
                        data={data ? data[index] : []}
                        secondColData={
                          secondColData ? secondColData[index] : []
                        }
                        navRoot={navRoot}
                      />
                    )
                  })}
                </div>
              )}
              {paginate && !!data && !!totalData && (
                <TablePagination
                  component="div"
                  count={totalData[0].length}
                  page={page}
                  onPageChange={(event, newPage) => setPage(newPage)}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={(event) => {
                    setPage(0)
                    setRowsPerPage(parseInt(event.target.value, 10))
                  }}
                  defaultValue={20}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                />
              )}
            </>
          )}
        </span>
      </Card>
    </div>
  )
}

export default DataRangeTable
