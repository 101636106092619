import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import placeHolders from "../data/placeholders"
import {
  FilterIcon,
  NextIcon,
  PreviousIcon,
  SortIcon,
} from "../../components/custom/IconsDashBoard"
import { MobileDatePicker } from "@mui/x-date-pickers"
import { Button, Menu, MenuItem, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router"
import "../styles/styles.css"
import dataTypes from "../data/dataTypes"
import style from "./largeComponent.module.css"
import classNames from "classnames"
import Card from "@mui/material/Card"
import SearchBar from "./SearchBar"
import { TfiReload } from "react-icons/tfi"
import BigCircularProgress from "./BigCircularProgress"
import { collection, onSnapshot, query } from "firebase/firestore"
import { db } from "../../firebaseConfig"
import HideList from "./HideList"

const moment = require("moment-timezone")
moment.tz.setDefault("Europe/London")

function Filter({ items, title, onClick }) {
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <button onClick={handleClick} className="flex items-center mx-2 ">
        <span className="text-[#C5C7CD] flex items-center mx-2">
          <FilterIcon />
        </span>
        {title}
      </button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setSelectedFilter(item.value)
              onClick(item, index)
            }}
            style={
              selectedFilter === item.value
                ? { backgroundColor: "blue", color: "white" }
                : {}
            }
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default function LargeTableComponet({
  edit,
  openRow,
  data,
  extraClassName,
  title,
  headers,
  hasDateFilter = false,
  hasHideList = false,
  onSubmit,
  counts,
  fetchReservations,
  loading,
  from,
  setHideCanceled,
  hideCanceled,
  setHideLeft,
  hideLeft,
  setHideAnonymous,
  hideAnonymous,
  setHideBooked,
  hideBooked,
  setHideDeleted,
  hideDeleted,
}) {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const dateParam = queryParams.get("date")
  const statusParam = queryParams.get("status")
  const shiftParam = queryParams.get("shift")
  const billStatusParam = queryParams.get("billStatus")
  const refundParam = queryParams.get("refund")
  const navigate = useNavigate()
  const { tenantId, subtenantId } = useParams()
  const [activeCanceledButton, setActiveCanceledButton] = useState(false)
  const [activeHideLeftButton, setActiveHideLeftButton] = useState(false)
  const [activeHideAnonymousButton, setActiveHideAnonymousButton] =
    useState(false)
  const [activeHideBookedButton, setActiveHideBookedButton] = useState(false)

  const [selectedDate, setSelecedDate] = useState(
    dateParam ? moment(dateParam).format() : moment().format(),
  )
  const [selectedSort, setSelectedSort] = useState()
  const [dataState, setDataState] = useState(data)
  const [searchData, setSearchData] = useState()
  const [textFieldValues, setTextFieldValues] = useState({})
  const [toggleEdit, setToggleEdit] = useState(false)
  const [status, setStatus] = useState(statusParam)
  const [shift, setShift] = useState(shiftParam)
  const [refund, setRefund] = useState(refundParam)
  const [billStatus, setBillStatus] = useState(billStatusParam)
  const [loadFirstTime, setLoadFirstTime] = useState(true)

  function reservationSnapshot() {
    const reservationsRef = collection(db, "reservations")
    const q = query(reservationsRef)
    let count = 0
    return onSnapshot(q, async () => {
      count++
      if (count > 1) {
        if (fetchReservations) {
          await fetchReservations(
            status,
            moment(selectedDate).format(),
            shift,
            billStatus,
          )
        }
      }
    })
  }

  useEffect(() => {
    const unsubscribe = reservationSnapshot()

    // set selected date in params
    if (!moment(selectedDate).isSame(moment(), "day")) {
      queryParams.set("date", moment(selectedDate).format())
      navigate({
        pathname: location.pathname,
        search: queryParams.toString(),
      })
    } else {
      queryParams.delete("date")
      navigate({
        pathname: location.pathname,
        search: queryParams.toString(),
      })
    }

    // re-fetch reservations when date changes
    if (fetchReservations) {
      fetchReservations(
        status,
        moment(selectedDate).format(),
        shift,
        billStatus,
      )
    }
    return () => unsubscribe()
  }, [
    selectedDate,
    status,
    shift,
    billStatus,
    hideCanceled,
    hideLeft,
    hideAnonymous,
    hideBooked,
    hideDeleted,
  ])

  const addDays = (val) => {
    const newDate = moment(selectedDate).add(val, "days").format()
    setSelecedDate(newDate)
  }
  const handleSort = (selectedColumn) => {
    if (selectedColumn === selectedSort) {
      setSelectedSort(
        selectedColumn[0] === "-"
          ? selectedColumn.substr(1)
          : "-" + selectedColumn,
      )
    } else setSelectedSort(selectedColumn)
  }
  const handleSearch = (e) => {
    setSearchData(e.target.value)
  }
  const sortData = () => {
    if (!selectedSort) return
    let val = -1
    let item = selectedSort
    if (selectedSort && selectedSort[0] === "-") {
      item = selectedSort.substring(1)
      val = 1
    }
    const sortedArray = [...dataState].sort((a, b) => {
      let x = a[item]
      let y = b[item]
      if (dataTypes[item] === "number") {
        x = x * 1
        y = y * 1
      }
      if (dataTypes[item] === "date") {
        x = new Date(x)
        y = new Date(y)
      }
      if (!x) x = placeHolders[item]
      if (!y) y = placeHolders[item]

      if (x < y) return val
      if (x > y) return -val
      return 0
    })
    setDataState(sortedArray)
  }
  const handleOpen = (id) => {
    const route = `/admin/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${title.toLowerCase()}/${id}`
    navigate(route)
  }
  const handleEdit = () => {
    if (toggleEdit) {
      onSubmit?.(textFieldValues)
      setToggleEdit(false)
    } else {
      setToggleEdit(true)
    }
  }

  const handleCancelEdit = () => {
    setToggleEdit(false)
    setTextFieldValues({})
  }

  useEffect(() => {
    if (from === "home") {
      setDataState(
        data.filter(
          (r) =>
            new Date(r.date).toDateString() ===
            new Date(selectedDate).toDateString(),
        ),
      )
    } else {
      setDataState(data)
    }
  }, [data])

  useEffect(() => {
    // Function to be triggered when jsonArray state changes
    sortData()
  }, [selectedSort])

  useEffect(() => {
    if (searchData) {
      const filteredData = data.filter(
        (x) =>
          `${(x.firstName + " ", x.lastName)}`
            ?.toLowerCase()
            .includes(searchData.toLowerCase()) ||
          x.guestEmail.toLowerCase().includes(searchData.toLowerCase()) ||
          x.reservationCode.toLowerCase().includes(searchData.toLowerCase()),
      )
      setDataState(filteredData)
    } else {
      setDataState(data)
    }
  }, [searchData, data])

  useEffect(() => {
    if (loading === false) {
      setLoadFirstTime(false)
    }
  }, [loading])
  const value = (val, type) => {
    if (isNaN(val) && !val) val = "not specified"
    if (type === "price" && val !== "not specified") val = "£" + val
    if (type === "isOwner" && val === true) val = "✔️"
    if (type === "isOwner" && val === false) val = "❌"
    return val
  }

  const statuses =
    from === "home"
      ? [
          {
            title: "All",
            value: null,
          },
          {
            title: "Booked",
            value: "Booked",
          },
          {
            title: "Seated",
            value: "Seated",
          },
          {
            title: "Partially Seated",
            value: "Partially Seated",
          },
          {
            title: "Zumi Fully Paid",
            value: "Zumi Fully Paid",
          },
          {
            title: "Zumi Partial Paid",
            value: "Zumi Partial Paid",
          },
        ]
      : from === "checks" && [
          {
            title: "All",
            value: null,
          },
          {
            title: "Paid",
            value: "paid",
          },
          {
            title: "Unpaid",
            value: "unpaid",
          },
          {
            title: "Partial Paid",
            value: "partial paid",
          },
          {
            title: "Pending",
            value: "pending",
          },
        ]

  const billStatuses = [
    {
      title: "All",
      value: null,
    },
    {
      title: "Paid",
      value: "paid",
    },
    {
      title: "Unpaid",
      value: "unpaid",
    },
    {
      title: "Partial Paid",
      value: "partial paid",
    },
    {
      title: "Pending",
      value: "pending",
    },
  ]

  const shiftes = [
    {
      title: "All",
      value: null,
    },
    {
      title: "Lunch",
      value: "Lunch",
    },
    {
      title: "Dinner",
      value: "Dinner",
    },
    {
      title: "Day",
      value: "Day",
    },
  ]

  const refunds = [
    {
      title: "All",
      value: null,
    },
    {
      title: "Full Refunded",
      value: "full",
    },
    {
      title: "Partial Refunded",
      value: "partial",
    },
  ]
  return (
    <>
      <Card
        sx={{
          m: 1,
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 p-4">
          <div className="justify-self-start flex items-center flex-nowrap">
            <p
              className="me-2"
              style={{
                color: "black",
                fontSize: "16px",
                fontFamily: "Mulish",
                fontWeight: 600,
                lineHeight: "22px",
                wordWrap: "break-word",
              }}
            >
              {title}{" "}
            </p>
            {hasDateFilter && (
              <>
                <button
                  className="bg-[#F4F4F5] p-2 text-3xl"
                  onClick={() => addDays(-1)}
                >
                  <PreviousIcon />
                </button>
                <MobileDatePicker
                  onChange={(date) => {
                    setSelecedDate(moment(date).format())
                  }}
                  value={new Date(selectedDate)}
                  closeOnSelect={true}
                  format="ccc, MMMM dd, yyyy"
                  className={classNames(
                    "hideBorder w-[120px] ",
                    style.datePickerContainer,
                  )}
                  slotProps={{
                    textField: {
                      size: "small",
                      style: {
                        borderColor: " white",
                        width: "200px",
                        borderRadius: 8,
                      },
                    },
                  }}
                />
                <button
                  className="bg-[#F4F4F5] p-2 text-3xl me-2"
                  onClick={() => addDays(1)}
                >
                  <NextIcon />
                </button>
              </>
            )}
            {counts && (
              <h6 className="text-[12px] font-semibold">
                Paid: #{counts?.paid} <br /> Unpaid: #{counts?.unpaid}
              </h6>
            )}
          </div>
          {/* {
            counts &&
            <h6>All: {counts?.all} Paid: {counts?.paid} Unpaid: {counts?.unpaid}</h6>
          } */}
          <div sx={{ color: "black" }} className="justify-self-end flex">
            <SearchBar handleSearch={handleSearch} />
            {from === "home" && (
              <>
                <button
                  onClick={() =>
                    fetchReservations(status, selectedDate, shift, billStatus)
                  }
                  className="flex items-center mx-2 "
                >
                  <span className="text-[#C5C7CD] flex items-center mx-2">
                    <TfiReload />
                  </span>
                </button>
                <Filter
                  items={statuses}
                  title="Status"
                  onClick={async (item) => {
                    await fetchReservations(
                      item.value,
                      selectedDate,
                      shift,
                      billStatus,
                    )
                    setStatus(item.value)
                    if (item.value) {
                      queryParams.set("status", item.value)
                    } else {
                      queryParams.delete("status")
                    }
                    navigate({
                      pathname: location.pathname,
                      search: queryParams.toString(),
                    })
                  }}
                />
                <Filter
                  items={billStatuses}
                  title="Bill Status"
                  onClick={async (item) => {
                    await fetchReservations(
                      status,
                      selectedDate,
                      shift,
                      item.value,
                    )
                    setBillStatus(item.value)
                    if (item.value) {
                      queryParams.set("billstatus", item.value)
                    } else {
                      queryParams.delete("billstatus")
                    }
                    navigate({
                      pathname: location.pathname,
                      search: queryParams.toString(),
                    })
                  }}
                />
                <Filter
                  items={shiftes}
                  title={"Shift"}
                  onClick={async (item) => {
                    await fetchReservations(
                      status,
                      selectedDate,
                      item.value,
                      billStatus,
                    )
                    setShift(item.value)
                    if (item.value) {
                      queryParams.set("shift", item.value)
                    } else {
                      queryParams.delete("shift")
                    }
                    navigate({
                      pathname: location.pathname,
                      search: queryParams.toString(),
                    })
                  }}
                />
                <Filter
                  items={refunds}
                  title={"Refund"}
                  onClick={async (item) => {
                    await fetchReservations(
                      status,
                      selectedDate,
                      shift,
                      billStatus,
                      item.value,
                    )
                    setRefund(item.value)
                    if (item.value) {
                      queryParams.set("refund", item.value)
                    } else {
                      queryParams.delete("refund")
                    }
                    navigate({
                      pathname: location.pathname,
                      search: queryParams.toString(),
                    })
                  }}
                />
              </>
            )}
            {from === "checks" && (
              <>
                <button
                  onClick={() =>
                    fetchReservations(status, selectedDate, shift, billStatus)
                  }
                  className="flex items-center mx-2 "
                >
                  <span className="text-[#C5C7CD] flex items-center mx-2">
                    <TfiReload />
                  </span>
                </button>
                <button className="flex items-center mx-2 ">
                  {" "}
                  <span className="text-[#C5C7CD] flex items-center mx-2">
                    <SortIcon />
                  </span>{" "}
                  Sort
                </button>
                <Filter
                  items={statuses}
                  title={"Status"}
                  onClick={async (item, _index) => {
                    fetchReservations(
                      item.value,
                      selectedDate,
                      shift,
                      billStatus,
                    )
                    setStatus(item.value)
                    if (item.value) {
                      queryParams.set("status", item.value)
                    } else {
                      queryParams.delete("status")
                    }
                    navigate({
                      pathname: location.pathname,
                      search: queryParams.toString(),
                    })
                  }}
                />
              </>
            )}

            {hasHideList && (
              <HideList
                list={[
                  {
                    onClick: setHideCanceled,
                    active: hideCanceled,
                    label: "Hide Canceled & No Show",
                  },
                  {
                    onClick: setHideLeft,
                    active: hideLeft,
                    label: "Hide Left",
                  },
                  {
                    onClick: setHideAnonymous,
                    active: hideAnonymous,
                    label: "Hide Anonymous",
                  },
                  {
                    onClick: setHideBooked,
                    active: hideBooked,
                    label: "Hide Booked & Confirmed",
                  },
                  {
                    onClick: setHideDeleted,
                    active: hideDeleted,
                    label: "Hide Deleted",
                  },
                ]}
              />
            )}

            {toggleEdit && (
              <Button onClick={handleCancelEdit} color="error">
                Cancel
              </Button>
            )}
            {edit && (
              <Button onClick={handleEdit}>
                {toggleEdit ? "Submit" : "Edit"}
              </Button>
            )}
          </div>
        </div>
        {loading && loadFirstTime ? (
          <BigCircularProgress />
        ) : (
          <TableContainer component={Paper} className={extraClassName}>
            <Table
              stickyHeader
              className="z-10"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow
                  sx={{
                    height: "22px",
                  }}
                >
                  {headers &&
                    headers.map((head) => {
                      return (
                        <TableCell
                          className="hover-row table-headers"
                          key={head.name}
                          style={{
                            color: "#9FA2B4",
                            fontSize: "14px",
                            fontFamily: "Mulish",
                            fontWeight: 700,
                            letterSpacing: "0.20px",
                            wordWrap: "break-word",
                          }}
                          onClick={() => handleSort(head.key)}
                        >
                          {head.name}
                        </TableCell>
                      )
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataState?.map((row, rowNumber) => (
                  <TableRow
                    onClick={() => openRow && handleOpen(row.id)}
                    className="hover-row table-data"
                    key={rowNumber}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {headers.map((col) => (
                      <TableCell
                        {...(col.props || {})}
                        style={{
                          color: "#252733",
                          fontSize: "14px",
                          fontFamily: "Mulish",
                          fontWeight: 600,
                          letterSpacing: "0.20px",
                          lineHeight: "20px",
                          wordWrap: "break-word",
                        }}
                        key={col.key + "row" + rowNumber}
                      >
                        {col.renderContent ? (
                          col.renderContent(row, col, { data, rowNumber })
                        ) : toggleEdit ? (
                          <TextField
                            defaultValue={row[col.key]}
                            onChange={(e) => {
                              const newTextFieldValues = { ...textFieldValues }
                              newTextFieldValues[col.key] = e.target.value
                              setTextFieldValues(newTextFieldValues) // Update the state object
                            }}
                            variant="standard"
                            size="small"
                          />
                        ) : (
                          value(row[col.key], col.key)
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Card>
    </>
  )
}
