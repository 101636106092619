import React from "react"

function SkeletonView({ className }) {
  return (
    <div role="status" className="animate-pulse">
      <div className={`bg-gray-200 ${className}`} />
    </div>
  )
}

export default SkeletonView
